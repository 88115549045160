import {withFormik} from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import {Api} from "PNH/services/Api";

export const Functions = {
    create: (values, formik) => {
        return Api.benefit_entries.admin.create({benefit_entry: values}).then((response) => {
            if (response.ok) {
                window.setNotifications([
                    {type: "success", msg: "Dodano"},
                ]);
            } else {
                window.setNotifications([
                    {type: "alert", msg: "Nie udało się zapisać zmian"},
                ]);
            }
        });
    },

    defaultValues: (props) => {
        return {
            report_type: "paper",
            date_played: props.date_played,
            activity_id: props.activity_id,
            organizer_login: props.organizer_login,
            user_id: props.user_id,
            afterSave: props.afterSave,
        };
    },
};

export const FormController = withFormik({
    validationSchema: validationSchema,
    mapPropsToValues: (props) => {
        return {...Functions.defaultValues(props)};
    },
    handleSubmit: (values, formikProps) => {
        formikProps.setSubmitting(true);
        Functions.create(values, formikProps)
            .then((response) => {
                formikProps.props.afterSave(response);
            })
            .then(() => {
                formikProps.setTouched({});
            });
    },
})(Form);
