import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

class AuditsTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.audits.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_auditable_id: this.props.activityID,
        with_auditable_type: "Activity",
        with_user_id: this.state.with_user_id,
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      row.created_at,
      row.user.first_name + " " + row.user.last_name,
      row.action,
      row.comment,
      row.audited_changes.map((r) => (
        <div>
          <b>{r[0]}:</b>
          <br />
          {r[1]}
        </div>
      )),
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            tableHead={["Data", "Użytkownik", "Akcja", "Komentarz", "Zmiany"]}
            tableData={this.state.data}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            page={this.state.page}
            rowsPerPage={this.state.limit}
            count={this.state.total}
            onChangePage={this.changePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
            labelRowsPerPage="Ilość na stronie"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count}`
            }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

AuditsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(extendedTablesStyle)(AuditsTable);
