import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
    addModal: false,
  };

  constructor(props) {
    super(props);
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  addModal = (open) => {
    this.setState({ addModal: open });
  };

  renderDeleteModal = () => {
    const { classes, handleSubmit, setFieldValue, values } = this.props;
    return (
      <>
        <Button color="danger" size="sm" onClick={() => this.deleteModal(true)}>
          -
        </Button>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall,
          }}
          open={this.state.deleteModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.deleteModal(false)}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => this.deleteModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + " " + classes.modalSmallBody}
          >
            <h5>Na pewno chcesz odjąć {values.value}zł?</h5>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.deleteModal(false)}
              color="transparent"
              className={classes.modalSmallFooterFirstButton}
            >
              Nie
            </Button>
            <Button
              onClick={async (e) => {
                await setFieldValue("afterSave", () => {
                  this.deleteModal(false);
                  this.props.fetch();
                });
                await setFieldValue("action", "delete");

                handleSubmit(e);
              }}
              color="primary"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  rendeAddModal = () => {
    const { classes, handleSubmit, setFieldValue, values } = this.props;
    return (
      <>
        <Button size="sm" color="success" onClick={() => this.addModal(true)}>
          +
        </Button>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall,
          }}
          open={this.state.addModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.addModal(false)}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => this.addModal(false)}
            >
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody + " " + classes.modalSmallBody}
          >
            <h5>Na pewno chcesz doładować {values.value}zł?</h5>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.addModal(false)}
              color="transparent"
              className={classes.modalSmallFooterFirstButton}
            >
              Nie
            </Button>
            <Button
              onClick={async () => {
                await setFieldValue("afterSave", () => {
                  this.addModal(false);
                  this.props.fetch();
                });
                await setFieldValue("action", "add");

                handleSubmit();
              }}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Tak
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  render() {
    const { values, errors, handleChange, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText="Komentarz do transakcji"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.comment,
                name: "comment",
                onChange: handleChange,
              }}
            />
            <GridItem xs={12}>
              <GridContainer alignItems="center">
                <GridItem xs={1} md={3} lg={2}>
                  {this.rendeAddModal()}
                </GridItem>
                <GridItem xs={10} md={6} lg={8}>
                  <CustomInput
                    labelText="Wartość"
                    error={errors.value}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: values.value,
                      name: "value",
                      onChange: handleChange,
                      type: "number",
                    }}
                  />
                </GridItem>
                <GridItem xs={1} md={3} lg={2}>
                  {this.renderDeleteModal()}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
