import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import moment from "moment";

export const ActivitiesFunctions = {
  create: (values, formik) => {
    return Api.activity_templates.admin
      .create({ activity_template: values })
      .then((response) => {
        if (response.ok) {
          window.setNotifications([
            { type: "success", msg: "Dodano" },
          ]);
          let url = `/activity_templates?with_id=${response.data.id}`;
          formik.props.history.push(url)
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  update: (values, formik) => {
    return Api.activity_templates.admin
      .update(values.id, { activity_template: values })
      .then((response) => {
        if (response.ok) {
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  delete: (formik) => {
    return Api.activity_templates.admin.delete(formik.values.id).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Usunięto" },
        ]);
        let url = `/activity_templates`;
        formik.history.push(url)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    const defaultValues = {
      id: props.activityID || "",
      localization_id: props.location_id || "",
      competition_type: "activity",
      location_object: "",
      sport_id: "",
      name: "",
      description: "",
      hidden: "",
      price: "",
      notes: "",
      date: "",
      publish_date: moment().format("YYYY-MM-DD H:m"),
      lv: "",
      gender: "",
      localizations: "",
      reserved_spots: 0,
      max_players: 0,
      min_players: 0,
      creator_id: JSON.parse(localStorage.getItem('user')).id,
      sports: [],
      start_at: "",
      stop_at: "",
      baner_image: "",
      thumb_image: "",
      custom_tags: "",
      supplement: 0,
      supplement_medicover: 0,
      ok_system_code: "",
      change_to_public_at: "",
      event_type: "",
      discount: "",
      rental_price: "",
      max_price: "",
      max_benefit_price: "",
      page_id: "",
      verify_lv: false,
      pay_by_bonus_account: false,
      week_days: [],
      afterSave: () => {},
    };
    return { ...defaultValues, ...props.defaults };
  },
};

export const ActivitiesForm = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: false,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      ActivitiesFunctions.create(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
          formikProps.setSubmitting(false);
        });
    } else {
      ActivitiesFunctions.update(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
          formikProps.setSubmitting(false);
        });
    }
  },
})(Form);