import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  localization_id: Yup.number().required("Pole Wymagane"),
  location_object_id: Yup.number().required("Pole Wymagane"),
  sport_id: Yup.number().required("Pole Wymagane"),

  publish_date: Yup.date().required("Pole Wymagane"),
  max_age: Yup.number().required("Pole Wymagane"),
  min_age: Yup.number().required("Pole Wymagane"),
  start_at: Yup.string().required("Pole Wymagane"),
  stop_at: Yup.string().required("Pole Wymagane"),
  // stop_at: Yup.string()
  //   .required("Pole Wymagane")
  //   .test("is-greater", "end time should be greater", function(value) {
  //     const { start_at } = this.parent;
  //     if (!start_at && !value) {
  //       return false;
  //     }
  //     const start_at_time =
  //       start_at.length < 6
  //         ? moment.utc({
  //             h: start_at.split(":")[0],
  //             m: start_at.split(":")[1],
  //           })
  //         : moment.utc({
  //             h: moment.utc(start_at).hours(),
  //             m: moment.utc(start_at).minutes(),
  //           });
  //     const stop_at_time =
  //       value.length < 6
  //         ? moment.utc({ h: value.split(":")[0], m: value.split(":")[1] })
  //         : moment.utc({
  //             h: moment.utc(value).hours(),
  //             m: moment.utc(value).minutes(),
  //           });
  //     return moment(stop_at_time).isAfter(moment(start_at_time));
  //   }),
  // description: Yup.string().required("Pole Wymagane"),
  reserved_spots: Yup.number()
    .min(0, "Minimalna wartość 0")
    .lessThan(Yup.ref("max_players"), "Wartość większa od maksymalnej"),
  max_players: Yup.number()
    .required("Pole Wymagane")
    .min(Yup.ref("min_players"), "Wartość większa od minimalnej"),

  min_players: Yup.number()
    .required("Pole number")
    .min(1, "Minimalna wartość 1"),
  price: Yup.number()
    .required("Pole Wymagane")
    .min(0, "Minimalna wartość 0"),
  date: Yup.date().when(["id"], {
    is: (id) => id !== undefined,
    then: Yup.date().required("Pole Wymagane"),
    otherwise: Yup.date(),
  }),
  max_price: Yup.number().nullable(true).when(["event_type"], {
    is: (event_type) => event_type === 'third_type',
    then: Yup.number().required("Pole Wymagane"),
  }),
  max_benefit_price: Yup.number().nullable(true).when(["event_type"], {
    is: (event_type) => event_type === 'third_type',
    then: Yup.number().required("Pole Wymagane"),
  }),
  date_from: Yup.date().when(["id"], {
    is: (id) => id === undefined,
    then: Yup.date().required("Pole Wymagane"),
    otherwise: Yup.date(),
  }),
  date_to: Yup.date().when(["id", "date_from"], {
    is: (id) => id === undefined,
    then: Yup.date()
      .required("Pole Wymagane")
      .when(
        "date_from",
        (date_from) =>
          date_from &&
          Yup.date()
            .required("Pole Wymagane")
            .min(date_from)
      ),
    otherwise: Yup.date(),
  }),

  days: Yup.array().when(["id"], {
    is: (id) => id === undefined,
    then: Yup.array().required("Pole Wymagane"),
    otherwise: Yup.array(),
  }),
  max_team_size: Yup.number().when(["competition_type", "min_team_size"], {
    is: (competition_type) => competition_type === "sparing",
    then: Yup.number()
        .required("Pole Wymagane")
        .min(Yup.ref("min_team_size"), "Wartość większa od minimalnej"),
    otherwise: Yup.number(),
  }),
  min_team_size: Yup.number().when(["competition_type"], {
    is: (competition_type) => competition_type === "sparing",
    then: Yup.number()
        .required("Pole Wymagane").min(1),
    otherwise: Yup.number(),
  }),
});

export default ValidationSchema;
