import React from "react";
import { Api } from "PNH/services/Api";

import GridItem from "components/Grid/GridItem";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";

import Badge from "components/Badge/Badge";
import Close from "@material-ui/icons/Close";

import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
  };

class LocalizationsSelector extends React.Component {
  render() {
    const { classes, values, setFieldValue } = this.props;
   
    return (
      <>
        
          <GridItem xs={6}>
            <AsyncSelector
              apiMethod={(q) =>
                Api.locations.admin.index(q)
              }
              labelKey="label"
              label="Lokalizacja"
              name="locations"
              onChange={(value, data) => {
                setFieldValue("location_tmp", data);
                setFieldValue("location_tmp_id", value);
              }}
            />
          </GridItem>
          <GridItem xs={2}>
            <Button
              size="sm"
              color="primary"
              disabled={!values.location_tmp_id}
              onClick={async (e) => {
                let locations = values.locations
                locations.push(values.location_tmp)
                setFieldValue("locations", locations);
              }}
            >
              Dodaj
            </Button>
          </GridItem>
          <GridItem xs={12}>
            {values.locations.map((location) => {
              return <Badge color="info" key={location.label} style={{marginLeft: 10}}>
                {location.label}
                <Button simple size="sm" onClick={() => {
                  let locations =  values.locations.filter((v) => (v.id != location.id))
                  setFieldValue("locations", locations);
                }} link color='danger'><Close/></Button>
              </Badge>
            })}
          </GridItem>
      </>
    );
  }
}

export default withStyles(styles)(LocalizationsSelector);
