import React from "react";
import { Api } from "PNH/services/Api";

import GridItem from "components/Grid/GridItem";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";

import Badge from "components/Badge/Badge";
import Close from "@material-ui/icons/Close";

import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};

class ActivitiesSelector extends React.Component {
  render() {
    const { classes, values, setFieldValue } = this.props;

    return (
      <>

        <GridItem xs={6}>
          <AsyncSelector
            apiMethod={(q) =>
              Api.activities.admin.index(q)
            }
            labelKey="label"
            label="Zajęcia"
            name="activities"
            onChange={(value, data) => {
              setFieldValue("activity_tmp", data);
              setFieldValue("activity_tmp_id", value);
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Button
            size="sm"
            color="primary"
            disabled={!values.activity_tmp_id}
            onClick={async (e) => {
              if (values.activities === null) {
                const activities = []
                activities.push(values.activity_tmp)
                setFieldValue("activities", activities);
              } else {
                const activities = values.activities
                activities.push(values.activity_tmp)
                setFieldValue("activities", activities);
              }

            }}
          >
            Dodaj
          </Button>
        </GridItem>
        <GridItem xs={12}>
          {values.activities && values.activities.map((activity) => {
            return <Badge color="info" key={activity.label} style={{ marginLeft: 10 }}>
              {activity.label}
              <Button simple size="sm" onClick={() => {
                let activities = values.activities.filter((v) => (v.id != activity.id))
                setFieldValue("activities", activities);
              }} link color='danger'><Close /></Button>
            </Badge>
          })}
        </GridItem>
      </>
    );
  }
}

export default withStyles(styles)(ActivitiesSelector);
