import React, { useCallback, useEffect, useState, useRef, Fragment } from "react";
import classNames from "classnames";
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import PeopleIcon from "@material-ui/icons/People";
import { Api } from "PNH/services/Api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

export const SearchBox = ({ classes }) => {
    const [data, setData] = useState([])
    const [searchField, setSearchField] = useState("")
    const [showResults, setShowResults] = useState(false)
    const ref = useRef();

    const closeOutside = (e) => {
        setShowResults(false)
    }

    useOnClickOutside(ref, closeOutside, showResults);

    const getData = useCallback(() => {
        Api.searchTags.admin.index({ with_tags: searchField }).then((r) => {
            setData(r.data.rows)
            setShowResults(true)
        })
    })

    const searchButton =
        classes.top +
        " " +
        classes.searchButton +
        " " +
        classNames({
            [classes.searchRTL]: false,
        });

    return <Fragment >
        <form onSubmit={(e) => {
            e.preventDefault()
            getData()
        }} style={{ display: "inline" }}>
            <CustomInput
                formControlProps={{
                    className: classes.top + " " + classes.search,
                }}
                inputProps={{
                    placeholder: "Szukaj",
                    value: searchField,
                    onChange: (e) => setSearchField(e.target.value),
                    inputProps: {
                        "aria-label": "Szukaj",
                        className: classes.searchInput,

                    },
                }}
            />
            <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                className={searchButton}
                onClick={getData}
            >
                <Search
                    className={classes.headerLinksSvg + " " + classes.searchIcon}
                />
            </Button>
        </form>
        <span ref={ref}>
            {showResults && renderResults(data, setShowResults)}
        </span>
    </Fragment>
}

const renderResults = (data, setShowResults) => {
    if (data.length == 0) {
        return null
    }
    return <Card style={{ position: 'fixed', top: '30px', right: '80px', maxWidth: '400px', maxHeight: '500px', overflow: 'auto' }}>
        <CardBody>
            <GridContainer>
                {data.map((r) => {
                    const url = r.searchable_type == 'Activity' ? '/activities/' + r.searchable_id : '/users/' + r.searchable_id
                    return <GridItem xs={12}>
                        <Link to={url} onClick={() => { setShowResults(false) }} >
                            <GridContainer alignItems="center" style={{ borderBottom: '1px solid #ddd' }} >
                                <GridItem xs={1}>
                                    {r.searchable_type == 'Activity' ? <SportsBasketballIcon style={{ color: '#1ad1a2' }} /> : <PeopleIcon style={{ color: '#1ad1a2' }} />}

                                </GridItem>
                                <GridItem xs={11} >
                                    <h5 style={{ color: 'black' }}><b>{r.label_first_line}</b></h5>
                                    <h6 style={{
                                        color: '#444', marginTop: '-10px', whiteSpace: "nowrap",
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>{r.label_second_line}</h6>
                                </GridItem>
                            </GridContainer>
                        </Link>
                    </GridItem>
                })}
            </GridContainer>
        </CardBody>
    </Card>
}

const useOnClickOutside = (ref, handler, showResults) => {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };

            if (!showResults) {
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            } else {
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
            }

        },
        [ref, handler]
    );
}