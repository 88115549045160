import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Datetime from "react-datetime";
import { I18n } from "PNH/components/I18n";
import CompetitionGroupSelector from "./CompetitionGroupSelector";
import GridContainer from "../../../../../components/Grid/GridContainer";
import CompetitionTeamSelector from "./CompetitionTeamSelector";

export const VisibilitySection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
        <GridItem xs={12}>
            <h5>
                <b>Widoczność zajęć</b>
            </h5>
        </GridItem>
        <GridItem xs={12}>
            <hr />
        </GridItem>
        <GridItem xs={12} sm={3}>
            <FormControlLabel
                control={
                    <Checkbox
                        tabIndex={-1}
                        checked={values['confirmed']}
                        onClick={() => {
                            values['confirmed'] && setFieldValue('event_type', ''); // Dirty hack for cleaning event type on removing confirmation
                            setFieldValue('confirmed', !values['confirmed'])
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                }}
                label={I18n.t('confirmed')}
            />
        </GridItem>
        <GridItem md={3}>
            <InputLabel error={!!errors.publish_date} className={classes.label}>
                Data publikacji
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                    utc
                    value={values.publish_date}
                    dateFormat={"YYYY-MM-DD"}
                    timeFormat={"H:mm"}
                    onChange={(value) => setFieldValue("publish_date", value)}
                    inputProps={{ name: "publish_date", autocomplete: "off" }}
                />
            </FormControl>
        </GridItem>
        {["canceled"].map(
            (p) => (
                <GridItem xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                checked={values[p]}
                                onClick={() => setFieldValue(p, !values[p])}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                        }}
                        label={I18n.t(p)}
                    />
                </GridItem>
            )
        )}
        <GridItem xs={12}></GridItem>
        {["hidden"].map(
            (p) => (
                <GridItem xs={12} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                checked={values[p]}
                                onClick={() => setFieldValue(p, !values[p])}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                        }}
                        label={I18n.t(p)}
                    />
                </GridItem>
            )
        )}
        <GridItem xs={12} />
        {values.hidden && <><GridItem xs={12} sm={3}>
            <InputLabel
                error={!!errors.change_to_public_at}
                className={classes.label}
            >
                Zmień na publiczne
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                    utc
                    value={values.change_to_public_at}
                    dateFormat={"YYYY-MM-DD"}
                    timeFormat={"H:mm"}
                    onChange={(value) =>
                        setFieldValue("change_to_public_at", value)
                    }
                    inputProps={{
                        name: "change_to_public_at",
                        autocomplete: "off",
                    }}
                />
            </FormControl>
        </GridItem>
            <GridItem xs={12} md={3}>
                <CustomInput
                    labelText="Cena po odkryciu"
                    error={errors.price_on_publish}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        value: values.price_on_publish,
                        name: "price_on_publish",
                        onChange: handleChange,
                    }}
                />
            </GridItem>
            <GridItem xs={12} md={3}>
                <CustomInput
                    labelText="Dopłata benefi po odkryciu"
                    error={errors.supplement_on_publish}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        value: values.supplement_on_publish,
                        name: "supplement_on_publish",
                        onChange: handleChange,
                    }}
                />
            </GridItem>
            <GridItem xs={12} md={3}>
                <CustomInput
                    labelText="Dopłata Medicover po odkryciu"
                    error={errors.supplement_medicover_on_publish}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        value: values.supplement_medicover_on_publish,
                        name: "supplement_medicover_on_publish",
                        onChange: handleChange,
                    }}
                />

            </GridItem>
            <GridItem xs={12} md={3}>
                <CustomInput
                    labelText="Dopłata Fitprofit po odkryciu"
                    error={errors.supplement_fitprofit_on_publish}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        value: values.supplement_fitprofit_on_publish,
                        name: "supplement_fitprofit_on_publish",
                        onChange: handleChange,
                    }}
                /></GridItem>
            <GridItem xs={12} md={12}>
                {["deposit_disabled_on_publish"].map(
                    (p) => (
                        <GridItem xs={12} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        checked={values[p]}
                                        onClick={() => setFieldValue(p, !values[p])}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot,
                                }}
                                label={I18n.t(p)}
                            />
                        </GridItem>
                    )
                )}
            </GridItem>

        </>}
        <GridItem xs={12}>
            <h5>
                <b>Przypisania</b>
            </h5>
        </GridItem>
        <GridItem xs={12}>
            <hr />
        </GridItem>
        <GridItem xs={12} md={12}>
            <GridContainer>
                <CompetitionGroupSelector handleChange={handleChange} classes={classes} values={values} errors={errors} setFieldValue={setFieldValue}/>
            </GridContainer>
        </GridItem>
        <GridItem xs={12} md={12}>
            <GridContainer>
                <CompetitionTeamSelector handleChange={handleChange} classes={classes} values={values} errors={errors} setFieldValue={setFieldValue}/>
            </GridContainer>
        </GridItem>
        <GridItem xs={12}>
            <hr />
        </GridItem>
    </>
}