import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Api } from "PNH/services/Api";
import { cloneDeep } from "lodash";
/*eslint-disable*/
// nodejs library to set properties for components
// react plugin for creating date-time-picker
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Mail from "@material-ui/icons/Mail";
import Slide from "@material-ui/core/Slide";
// core components

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";


import javascriptStyles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordModal: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }

  form(formik) {
    const { classes } = this.props;
    return (
      <Card plain className={classes.modalLoginCard}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ></DialogTitle>
        <DialogContent
          id="login-modal-slide-description"
          className={classes.modalBody}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={this.props.handleClose}
            style={{ position: "absolute", top: "5px", right: "15px" }}
          >
            {" "}
            <Close className={classes.modalClose} style={{ color: "black" }} />
          </Button>

          <form>
            <div className={classes.textCenter}>
              <h4 style={{ marginTop: "15px" }}>Odzyskaj hasło</h4>
            </div>

            <CardBody className={classes.cardLoginBody}>
              <CustomInput
                id="login-modal-email"
                labelText="Email"
                noErrorIcon
                error={formik.errors["email"]}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: formik.handleChange,
                  name: "email",
                  value: formik.values.email,
                  endAdornment: (
                    <InputAdornment position="start">
                      <Mail className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
            </CardBody>
          </form>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.justifyContentCenter}
        >
          <Button color="primary" simple size="lg" onClick={formik.submitForm}>
            Odzyskaj Hasło
          </Button>
        </DialogActions>
      </Card>
    );
  }
  render() {
    return (
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values) => {
          Api.users.resetPassword(values.email).then((response) => {
            if (response.ok) {
              const data = cloneDeep(this.props.data);
              data.currentUser = response.data;
              const notifications = [
                {
                  type: "success",
                  msg:
                    "Na podany adres e-mail wysłano instrukcję odzyskiwania hasła.",
                },
              ];

              this.props.updateMasterState({ notifications, data });
            } else {
              window.setNotifications([
                {
                  type: "alert",
                  msg: "Błędny email",
                },
              ]);
            }
          });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Błędny adres email")
            .required("Pole Wymagane"),
        })}
      >
        {(props) => {
          return this.form(props);
        }}
      </Formik>
    );
  }
}

export default withStyles(javascriptStyles)(ResetPasswordModal);
