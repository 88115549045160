import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import { UsersFunctions } from "./index";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer >
          <GridItem xs={12}>
            <h3>Fitprofit</h3>
            {values.fitprofit_id && (
              <>
                {values.fitprofit_active ? (
                  <Primary>
                    <h5>
                      <b> Karta Aktywna</b>
                    </h5>
                  </Primary>
                ) : (
                  <Danger>
                    <h5>
                      <b>Karta nieaktywna</b>
                    </h5>
                  </Danger>
                )}
              </>
            )}
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Nr karty fitprofit"
              error={errors.fitprofit_id}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.fitprofit_id,
                name: "fitprofit_id",
                onChange: handleChange,
                type: "fitprofit_id",
                autocomplete: "off",
              }}
            />
          </GridItem>
          <GridContainer justify="flex-end">
            <GridItem xs={12} style={{ textAlign: 'right' }}>
              <Button type="submit" color="primary">
                Zapisz
              </Button>
            </GridItem>
          </GridContainer>
        </GridContainer>

      </form>
    );
  }
}

export default withStyles(styles)(Form);
