/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Dashboard from "PNH/views/Dashboard/Dashboard.jsx";
import Users from "PNH/views/Users/index.jsx";
import Pages from "PNH/views/Pages/index.jsx";
import MainPageTiles from "PNH/views/MainPageTiles/index.jsx";
import Audits from "PNH/views/Audits/index.jsx";
import Locations from "PNH/views/Locations/index.jsx";
import Transactions from "PNH/views/Transactions/index.jsx";
import PromoCodes from "PNH/views/PromoCodes/index.jsx";
import SportsHandballIcon from "@material-ui/icons/SportsHandball";
import SportsVolleyballIcon from "@material-ui/icons/SportsVolleyball";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Sports from "PNH/views/Sports/index.jsx";
import Activities from "PNH/views/Activities/index.jsx";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import NoteIcon from "@material-ui/icons/Note";
import PublicIcon from "@material-ui/icons/Public";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import ActivityTemplates from "PNH/views/ActivitiyTemplates";
import ListAltIcon from '@material-ui/icons/ListAlt';
import Settings from "./PNH/views/Settings";
import SettingsIcon from '@material-ui/icons/Settings';
import {ReportsPage} from "./PNH/views/Reports";
import TableChartIcon from '@material-ui/icons/TableChart';
import {BenefitEntriesPage} from "./PNH/views/BenefitEntries";
import PaymentIcon from '@material-ui/icons/Payment';
import CompetitionGroups from "./PNH/views/CompetitionGroups";
import PersonIcon from '@material-ui/icons/Person';

var dashRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: (props) => <Dashboard {...props} />,
    layout: "/",
  },
  {
    path: "activities",
    name: "Zajęcia",
    rtlName: "",
    icon: SportsHandballIcon,
    component: (props) => <Activities {...props} />,
    layout: "/",
  },
  {
    path: "activity_templates",
    name: "Zajęcia szablony",
    rtlName: "",
    icon: ListAltIcon,
    component: (props) => <ActivityTemplates {...props} />,
    layout: "/",
  },
  {
    path: "users",
    name: "Użytkownicy",
    rtlName: "",
    icon: PersonIcon,
    component: (props) => <Users {...props} />,
    layout: "/",
  },
  {
    path: "competition-groups",
    name: "Grupy",
    rtlName: "",
    icon: PeopleIcon,
    component: (props) => <CompetitionGroups {...props} />,
    layout: "/",
  },
  {
    path: "transactions",
    name: "Transakcje",
    rtlName: "",
    icon: ReceiptIcon,
    component: (props) => <Transactions {...props} />,
    layout: "/",
  },
  {
    path: "promo-codes",
    name: "Kody Promocyjne",
    rtlName: "",
    icon: LoyaltyIcon,
    component: (props) => <PromoCodes {...props} />,
    layout: "/",
  },
  {
    path: "locations",
    name: "Lokalizacje",
    rtlName: "",
    icon: PublicIcon,
    component: (props) => <Locations {...props} />,
    layout: "/",
  },
  {
    path: "sports",
    name: "Dyscypliny",
    rtlName: "",
    icon: SportsVolleyballIcon,
    component: (props) => <Sports {...props} />,
    layout: "/",
  },

  {
    path: "main_page_tiles",
    name: "Kafelki",
    rtlName: "",
    icon: SettingsSystemDaydreamIcon,
    component: (props) => <MainPageTiles {...props} />,
    layout: "/",
  },
  {
    path: "pages",
    name: "Strony",
    rtlName: "",
    icon: NoteIcon,
    component: (props) => <Pages {...props} />,
    layout: "/",
  },
  {
    path: "settings",
    name: "Ustawienia",
    rtlName: "",
    icon: SettingsIcon,
    component: (props) => <Settings {...props} />,
    layout: "/",
  },
  {
    path: "benefit_entries",
    name: "Wpisy benefit",
    rtlName: "",
    icon: PaymentIcon,
    component: (props) => <BenefitEntriesPage {...props} />,
    layout: "/",
  },
  {
    path: "reports",
    name: "Raporty",
    rtlName: "",
    icon: TableChartIcon,
    component: (props) => <ReportsPage {...props} />,
    layout: "/",
  },
  {
    path: "audits",
    name: "Audit",
    rtlName: "",
    icon: LowPriorityIcon,
    component: (props) => <Audits {...props} />,
    layout: "/",
  },
  // {
  //   path: "/panel",
  //   name: "Export",
  //   rtlName: "",
  //   icon: ImportExportIcon,
  //   component: (props) => (window.location.href = "/panel"),
  //   layout: "/admin",
  // },
];
export default dashRoutes;
