import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

class UserAuditsTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    with_auditable_id: "",
    with_auditable_type: "",
    with_audited_changes: "",
    with_action: ""
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.audits.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_auditable_id: this.props.userID,
        with_auditable_type: 'User',
        with_audited_changes: this.state.with_audited_changes,
        with_action: this.state.with_action
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      row.created_at,
      row.user ? row.user.id + " " + row.user.first_name + " " + row.user.last_name : "-",
      row.action,
      row.comment,
      row.audited_changes.map((r) => (
        <div>
          <b>{r[0]}:</b>
          <br />
          {r[1]}
        </div>
      )),
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="secondary">
              <h4 className={classes.cardIconTitle}>Filtry</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Atrybut
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.with_audited_changes}
                      onChange={(e) =>
                        this.setState(
                          { with_audited_changes: e.target.value },
                          this.getData
                        )
                      }
                      inputProps={{
                        isClearable: true,
                      }}
                    >
                      {["id",
                        "first_name",
                        "last_name",
                        "birth_date",
                        "pnh_id",
                        "tuzagram_id",
                        "gender",
                        "newsletter",
                        "phone",
                        "city",
                        "confirmed_phone",
                        "confirmation_sms_token",
                        "ok_id",
                        "benefit_id",
                        "first_line",
                        "second_line",
                        "email",
                        "custom_tags",
                        "tags",
                        "encrypted_password",
                        "reset_password_token",
                        "reset_password_sent_at",
                        "remember_created_at",
                        "sign_in_count",
                        "current_sign_in_at",
                        "last_sign_in_at",
                        "current_sign_in_ip",
                        "last_sign_in_ip",
                        "confirmation_token",
                        "confirmed_at",
                        "confirmation_sent_at",
                        "unconfirmed_email",
                        "created_at",
                        "updated_at",
                        "account_balance",
                        "account_bonus_value",
                        "identifier",
                        "hide_last_name",
                        "provider",
                        "facebook_uid",
                        "facebook_image",
                        "label",
                        "deposit_alert",
                        "deposit_alert_date",
                        "notes",
                        "benefit_active",
                        "benefit_status_ttl",
                        "basket_items_count",
                        "fa",
                        "ok_system_active",
                        "ok_system_status_ttl",
                        "signed_agreement",
                        "transfer_verification",
                        "bonus_expiration_date"
                        ,
                      ].map((g) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={g}
                        >
                          {g}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={4}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Akcja
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.with_action}
                      onChange={(e) =>
                        this.setState(
                          { with_action: e.target.value },
                          this.getData
                        )
                      }
                      inputProps={{
                        isClearable: true,
                      }}
                    >
                      {[
                        "create", "update"
                      ].map((g) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={g}
                        >
                          {g}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="secondary">
              <h4 className={classes.cardIconTitle}>Historia zmian</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "Data",
                  "Użytkownik",
                  "Akcja",
                  "Komentarz",
                  "Zmiany",
                ]}
                tableData={this.state.data}
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                page={this.state.page}
                rowsPerPage={this.state.limit}
                count={this.state.total}
                onChangePage={this.changePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                labelRowsPerPage="Ilość na stronie"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} z ${count}`
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

UserAuditsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(extendedTablesStyle)(UserAuditsTable);
