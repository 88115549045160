import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  defaultValues: (props) => {
    return {
      id: props.activityID,
      data: [],
      afterSave: () => {},
    };
  },
};

export const ParticipantsForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    Api.activities.admin.check_list(values.id).then((response) => {
      formikProps.setFieldValue("data", response.data.rows);
    });
  },
})(Form);
