import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const LocationsFunctions = {
  addObject: (formik) => {
    const objects_attributes = [...formik.values.location_objects_attributes];
    objects_attributes.push({
      id: "",
      object_type: "hall",
      description: "",
      notes: "",
      hidden: false,
      order_value: 1000,
      checklist: "",
      _destroy: false,
    });
    formik.setFieldValue("location_objects_attributes", objects_attributes);
  },
  updateObject: (formik, index, field, value) => {
    const objects_attributes = [...formik.values.location_objects_attributes];
    objects_attributes[index][field] = value;
    formik.setFieldValue("location_objects_attributes", objects_attributes);
  },
  create: (values, formik) => {
    return Api.locations.admin.create({ location: values }).then((response) => {
      if (response.ok) {
        if (!values.modal) {
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
          let url = `/locations/${response.data.id}`;
          formik.props.history.push(url)
        } else {
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
          formik.props.updateLocation(response);
        }
       
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.locations.admin
      .update(values.id, { location: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...LocationsFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
          if (values.modal) {
            formik.props.updateLocation(response);
          }
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  delete: (formik) => {
    return Api.locations.admin.delete(formik.values.id).then((response) => {
      if (response.ok) {
        if (!formik.values.modal) {
          window.setNotifications([
            { type: "success", msg: "Usunięto" },
          ]);
          let url = `/locations`;
          formik.history.push(url)
        }
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      id: props.locationID,
      name: "",
      street: "",
      city: "",
      post_code: "",
      lat: "",
      lang: "",
      district: "",
      description: "",
      notes: "",
      hidden: "",
      photo: "",
      benefit_terminal_sn: "",
      photos: [],
      order_value: 1000,
      location_objects_attributes: [],
      modal: props.modal || false,
      afterSave: props.afterSave ? props.afterSave : () => {},
    };
  },
};

export const LocationsForm = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: false,
  mapPropsToValues: (props) => {
    return { ...LocationsFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      LocationsFunctions.create(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      LocationsFunctions.update(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    }
  },
})(Form);
