import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import { AsyncSelector } from "PNH/components/AsyncSelector/AsyncSelector";
import { UsersFunctions } from "./index";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};

const options = [
  { name: 'Nie dotyczy', value: 'na' },
  { name: 'Nie', value: 'no' },
  { name: 'Tak', value: 'yes'}
];

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
  }

  fetch = () => {
    if (this.props.userID) {
      Api.users.admin.show(this.props.userID).then((response) => {
        if (response.ok) {
          this.props.setFieldValue(
            "user_agreements_attributes",
            response.data.user_agreements_attributes
          );
          this.props.setFieldValue(
            "signed_agreement",
            response.data.signed_agreement
          );
          this.props.setFieldValue(
            "transfer_verification",
            response.data.transfer_verification
          );
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const { classes, values, handleChange, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer justify="flex-end">
          <GridItem xs={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Podpisane porozumienie
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.signed_agreement}
                onChange={handleChange}
                inputProps={{
                  name: "signed_agreement",
                }}
              >
                {options.map((r) => (
                  <MenuItem
                    key={r.name}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={r.value}
                  >
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Przelew weryfikacyjny
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.transfer_verification}
                onChange={handleChange}
                inputProps={{
                  name: "transfer_verification",
                }}
              >
                {options.map((r) => (
                  <MenuItem
                    key={r.name}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={r.value}
                  >
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <GridContainer>
                  <GridItem xs={6}>
                    <h4 className={classes.cardIconTitle}>Ustalenia</h4>
                  </GridItem>
                  <GridItem xs={6} style={{ textAlign: "right" }}>
                    <Button
                        color="primary"
                        simple
                        size="sm"
                        onClick={() => {
                          UsersFunctions.addObject(this.props);
                        }}
                    >
                      + Dodaj obiekt
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridItem xs={12}>
                  <GridContainer>
                    {values.user_agreements_attributes &&
                    values.user_agreements_attributes.map(
                        (user_agreement, index) => {
                          if (user_agreement._destroy) {
                            return null;
                          }
                          return (
                              <GridItem key={user_agreement.id + "-" + index} xs={12}>
                                <GridContainer>
                                  <GridItem xs={2}>
                                    <AsyncSelector
                                        apiMethod={Api.sports.admin.index}
                                        apiShowMethod={Api.sports.admin.show}
                                        labelKey="name"
                                        label="Dyscyplina"
                                        name="sports"
                                        classes={classes}
                                        style={{paddingTop: '15px'}}
                                        defaultValue={user_agreement.sport_id}
                                        value={
                                          {
                                            id: _.get(user_agreement, 'sport.id', null),
                                            label: _.get(user_agreement, 'sport.name', null)
                                          }
                                        }
                                        onChange={(value, data) => {
                                          UsersFunctions.updateObject(
                                              this.props,
                                              index,
                                              "sport_id",
                                              value
                                          );
                                          UsersFunctions.updateObject(
                                              this.props,
                                              index,
                                              "sport",
                                              data
                                          );
                                        }}
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <AsyncSelector
                                        apiMethod={Api.locations.admin.index}
                                        apiShowMethod={Api.locations.admin.show}
                                        labelKey="label"
                                        label="Lokalizacja"
                                        name="locations"
                                        classes={classes}
                                        style={{paddingTop: '15px'}}
                                        defaultValue={user_agreement.localization_id}
                                        value={
                                          {
                                            id: _.get(user_agreement, 'localization.id', null),
                                            label: _.get(user_agreement, 'localization.label', null)
                                          }
                                        }
                                        onChange={(value, data) => {
                                          UsersFunctions.updateObject(
                                              this.props,
                                              index,
                                              "localization",
                                              data
                                          );
                                          UsersFunctions.updateObject(
                                              this.props,
                                              index,
                                              "localization_id",
                                              value
                                          );
                                        }}
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <CustomInput
                                        labelText="Przedział czasowy"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          multiline: true,
                                          value: user_agreement.event_length,
                                          onChange: (e) => {
                                            UsersFunctions.updateObject(
                                                this.props,
                                                index,
                                                "event_length",
                                                e.target.value
                                            );
                                          },
                                        }}
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <CustomInput
                                        labelText="Cena"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          multiline: true,
                                          value: user_agreement.event_price,
                                          onChange: (e) => {
                                            UsersFunctions.updateObject(
                                                this.props,
                                                index,
                                                "event_price",
                                                e.target.value
                                            );
                                          },
                                        }}
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <CustomInput
                                        labelText="Dopłata"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          multiline: true,
                                          value: user_agreement.supplement,
                                          onChange: (e) => {
                                            UsersFunctions.updateObject(
                                                this.props,
                                                index,
                                                "supplement",
                                                e.target.value
                                            );
                                          },
                                        }}
                                    />
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                      <InputLabel
                                          htmlFor="simple-select"
                                          className={classes.selectLabel}
                                      >
                                        Typ wydarzeń
                                      </InputLabel>
                                      <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={user_agreement.event_type}
                                          onChange={(e) => {
                                            UsersFunctions.updateObject(
                                                this.props,
                                                index,
                                                "event_type",
                                                e.target.value
                                            );
                                          }}
                                          inputProps={{
                                            name: "event_type",
                                          }}
                                      >
                                        {[["Organizator 1.0", "first_type"],
                                          ["Organizator 2.0", "second_type"],
                                          ["Organizator 3.0", "third_type"]].map((g) => (
                                            <MenuItem
                                                classes={{
                                                  root: classes.selectMenuItem,
                                                  selected: classes.selectMenuItemSelected,
                                                }}
                                                value={g[1]}
                                            >
                                              {g[0]}
                                            </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </GridItem>
                                  <GridItem xs={2}>
                                    <Button
                                        color="danger"
                                        onClick={() =>
                                            UsersFunctions.updateObject(
                                                this.props,
                                                index,
                                                "_destroy",
                                                1
                                            )
                                        }
                                    >
                                      Usuń
                                    </Button>
                                  </GridItem>
                                </GridContainer>

                                <hr />
                              </GridItem>
                          );
                        }
                    )}
                  </GridContainer>
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem xs={3} style={{textAlign: 'right'}}>
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
