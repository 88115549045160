import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const PagesFunctions = {
  create: (values, formik) => {
    return Api.pages.admin.create({ page: values }).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Dodano stronę" },
        ]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.pages.admin
      .update(values.id, { page: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...PagesFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  delete: (formik) => {
    return Api.pages.admin.delete(formik.values.id).then((response) => {
      if (response.ok) {
        formik.setNotifications([{ type: "success", msg: "Usunięto" }]);
        formik.afterSave(response);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć konta" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      id: props.id,
      name: "",
      url: "",
      visible: true,
      wishpond_id: "",
      afterSave: () => {},
    };
  },
};

export const PagesForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...PagesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      PagesFunctions.create(values, formikProps)
        .then((response) => {
          formikProps.props.afterSave(response);
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      PagesFunctions.update(values, formikProps)
        .then((response) => {
          formikProps.props.afterSave(response);
        })
        .then(() => {
          formikProps.setTouched({});
        });
    }
  },
})(Form);
