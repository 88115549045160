import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { PagesFunctions } from "./index";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.pages.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...PagesFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {values.id && (
          <GridContainer justify="flex-end">
            <GridItem xs={4}>
              <Button
                color="danger"
                className={classes.marginRight}
                onClick={() => this.deleteModal(true)}
              >
                Usuń stronę
              </Button>
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal + " " + classes.modalSmall,
                }}
                open={this.state.deleteModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.deleteModal(false)}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
              >
                <DialogTitle
                  id="small-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => this.deleteModal(false)}
                  >
                    <Close className={classes.modalClose} />
                  </Button>
                </DialogTitle>
                <DialogContent
                  id="small-modal-slide-description"
                  className={classes.modalBody + " " + classes.modalSmallBody}
                >
                  <h5>Na pewno chcesz usunąć stronę?</h5>
                </DialogContent>
                <DialogActions
                  className={
                    classes.modalFooter + " " + classes.modalFooterCenter
                  }
                >
                  <Button
                    onClick={() => this.deleteModal(false)}
                    color="transparent"
                    className={classes.modalSmallFooterFirstButton}
                  >
                    Nie
                  </Button>
                  <Button
                    onClick={() => PagesFunctions.delete(this.props)}
                    color="success"
                    simple
                    className={
                      classes.modalSmallFooterFirstButton +
                      " " +
                      classes.modalSmallFooterSecondButton
                    }
                  >
                    Tak
                  </Button>
                </DialogActions>
              </Dialog>
            </GridItem>
          </GridContainer>
        )}
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText="Url"
              error={errors.url}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.url,
                name: "url",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Nazwa"
              error={errors.name}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.name,
                name: "name",
                onChange: handleChange,
                type: "text",
              }}
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <FormControlLabel
              style={{ marginTop: "20px" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.visible}
                  onClick={() => setFieldValue("visible", !values.visible)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Widoczna"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Wishpond ID"
              error={errors.wishpond_id}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.wishpond_id,
                name: "wishpond_id",
                onChange: handleChange,
                type: "text",
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem xs={3}>
            <Button type="submit" color="success">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
