import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";


export const DescriptionSection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
        <GridItem xs={12}>
            <hr />
        </GridItem>
        <GridItem xs={12}>
            <CustomInput
                labelText="Opis"
                error={errors.description}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    multiline: true,
                    value: values.description,
                    name: "description",
                    onChange: handleChange,
                }}
            />
        </GridItem>
        <GridItem xs={12} md={6}>
            <AsyncSelector
                apiMethod={Api.pages.admin.index}
                apiShowMethod={Api.pages.admin.show}
                labelKey="name"
                label="Strona"
                name="page_id"
                defaultValue={values.page_id}
                value={
                    values.page && {
                        id: values.page.id,
                        label: values.page.name,
                    }
                }
                onChange={(value, data) => {
                    setFieldValue("page", data);
                    setFieldValue("page_id", value);
                }}
            />
        </GridItem>
    </>
}