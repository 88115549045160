import React from "react";
import { withRouter } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import { FiltersForm } from "./Search";
import Profile from "./Profile";
import { ActivitiesForm } from "./ActivitiesFrom/index";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import {currentUrlParams} from 'PNH/services/Helpers'

class Activities extends React.Component {
  render() {
    const url = currentUrlParams().urlPath;
    
    if (!url[1]) {
      return (
          <FiltersForm {...this.props} />
      );
    } else if (url[1] === "new") {
      return (
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <PeopleIcon />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ActivitiesForm {...this.props} />
            </CardBody>
          </Card>
      );
    } else if (url[1]) {
      return (
          <Profile activityID={url[1]} {...this.props} />
      );
    }
  }
}

Activities.propTypes = {};

export default withRouter(Activities);
