import React, { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core";
import Card from "components/Card/Card";
import Timeline from "components/Timeline/Timeline.js";
import CommentIcon from '@material-ui/icons/Comment';
import VisibilityIcon from '@material-ui/icons/Visibility';

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const RenderForm = ({ values, errors, handleSubmit, setFieldValue, handleChange, setOpenModal, openModal, ...props }) => {
  const classes = useStyles();

  return <GridContainer>
    <GridItem xs={12}>
      <CustomInput
        labelText="Dodaj komentarz"
        error={errors.comment}
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          multiline: true,
          value: values.comment,
          name: "comment",
          onChange: handleChange,
        }}
      />
    </GridItem>

    <GridItem xs={12} style={{ textAlign: 'right' }}>
      <Button color="primary" size="sm" onClick={handleSubmit}> Dodaj</Button>
    </GridItem>
  </GridContainer>
}

const RenderComments = ({comments}) => {
  return <Timeline simple stories={comments.reverse().map(comment => {
    return {
      // First story
      inverted: true,
      badgeColor: comment.comment === "Odczytano" ? "info" : "primary",
      badgeIcon: comment.comment === "Odczytano" ? VisibilityIcon : CommentIcon,
      title: comment.user,
      titleColor: "info",
      body: (
        <p>
          {comment.comment}
        </p>
      ),
      footerTitle: `Dodano: ${comment.created_at}`,
    }
  })} />
}


const Form = ({ values, ...props }) => {

  return <>
    <RenderComments comments={values.comments}/>
    <RenderForm values={values} {...props} />
  </>
}

export default withStyles(styles)(Form);
