import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Api} from "../../../../services/Api";

export const RejectedForm = ({row, classes}) => {
    const [isRejected, setRejected] = useState(row.rejected)

    return <FormControlLabel
        control={
            <Checkbox
                tabIndex={-1}
                checked={isRejected}
                disabled={row.confirmed}
                onClick={() => {
                    setRejected(!isRejected);
                    Api.benefit_entries.admin.update(row.id, {rejected: !isRejected}).then((r)=>{
                        if(r.ok) {
                            window.setNotifications([
                                {
                                    type: "success",
                                    msg: "Zapisano",
                                },
                            ]);
                        } else {
                            window.setNotifications([
                                {
                                    type: "alert",
                                    msg: "Nie zapisano",
                                },
                            ]);
                        }
                    })

                }}
                checkedIcon={<Check className={classes.checkedIcon}/>}
                icon={<Check className={classes.uncheckedIcon}/>}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                }}
            />
        }
        classes={{
            label: classes.label,
            root: classes.labelRoot,
        }}
        label={""}
    />
}