import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from 'react-router-dom'
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

class LocationsTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    with_id: "",
    with_name: "",
    with_street: "",
    with_city: "",
    with_district: "",
    with_post_code: "",
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.locations.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_id: this.state.with_id,
        with_name: this.state.with_name,
        with_city: this.state.with_city,
        with_district: this.state.with_district,
        with_post_code: this.state.with_post_code,
        with_street: this.state.with_street,
        order_element: 'order_value',
        order_dir: 'ASC'
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      row.id,
      row.name,
      row.street,
      row.city,
      row.district,
      row.post_code,
      row.hidden ? "Tak" : "Nie",
      row.order_value,
      <Button
        color="success"
        simple
        onClick={() => {
          let url = `/locations/${row.id}`;
          this.props.history.push(url)
        }}
        key={`edit_${row.id}`}
        className={classes.actionButton}
      >
        <Edit className={classes.icon} />
      </Button>,
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };
  render() {
    const { classes } = this.props;
    return (
        <GridContainer>
          <GridItem xs={12}>
            <Button
              style={{ float: "right" }}
              color="success"
              onClick={() => {
                let url = "/locations/new";
                this.props.history.push(url)
              }}
            >
              Dodaj
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <h4 className={classes.cardIconTitle}>Filtry</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={1}>
                    <CustomInput
                      id={`with_id`}
                      labelText="ID"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_id`,
                        value: this.state[`with_id`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <CustomInput
                      id={`with_name`}
                      labelText="Nazwa"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_name`,
                        value: this.state[`with_name`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <CustomInput
                      id={`with_street`}
                      labelText="Ulica"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_street`,
                        value: this.state[`with_street`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      id={`with_city`}
                      labelText="Miasto"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_city`,
                        value: this.state[`with_city`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      id={`with_district`}
                      labelText="Dzielnica"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_district`,
                        value: this.state[`with_district`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      id={`with_post_code`}
                      labelText="Kod pocztowy"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_post_code`,
                        value: this.state[`with_post_code`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <h4 className={classes.cardIconTitle}>Lokalizacje</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHead={[
                    "ID",
                    "Nazwa",
                    "Ulica",
                    "Miasto",
                    "Dzielnica",
                    "Kod Pocztowy",
                    "Ukryte",
                    "Kolejność",
                    "",
                  ]}
                  tableData={this.state.data}
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  page={this.state.page}
                  rowsPerPage={this.state.limit}
                  count={this.state.total}
                  onChangePage={this.changePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Ilość na stronie"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} z ${count}`
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}

LocationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(extendedTablesStyle)(LocationsTable));
