import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import PromoCodesTable from "./Table";
import Profile from "./Profile";
import { PromoCodesForm } from "./PromoCodeForm/index.jsx"; 
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import { currentUrlParams } from "PNH/services/Helpers";
import { FiltersForm } from "./Search";

class Sports extends React.Component { 
  render() {
    const url = currentUrlParams().urlPath;
    if (!url[1]) {
      return (
        <FiltersForm {...this.props} />
      );
    } else if (url[1] === "new") {
      return (
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <PeopleIcon />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <PromoCodesForm {...this.props} />
            </CardBody>
          </Card>
      );
    } else if (url[1]) {
      return (
          <Profile {...this.props} />
      );
    }
  }
}

Sports.propTypes = {};

export default Sports;
