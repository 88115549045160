import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import ActivitiesTable from "PNH/components/Filters/Table"
import { Api, serializeParams } from "PNH/services/Api";
import { withRouter } from 'react-router-dom';
import { RowFunction } from "./RowFunction";
import { TableFilters } from "./Filters";
import moment from "moment";

export const SearchFunctions = {
  index: (values, formik) => {
    const {creator, ...restValues} = values
    return Api.activity_templates.admin
      .index({ ...restValues, ...{ data: [], search: true } })
      .then((response) => {
        if (response.ok) {
         
          formik.setFieldValue("data", response.data);
          formik.setFieldValue("total", response.data.total);
          const params = { ...restValues, ...{ data: [] } };
          // eslint-disable-next-line 
          history.pushState({}, "activity_templates", "/activity_templates?" + serializeParams(restValues));
          window.scrollTo(0, 0);
        }
      });
  },

  getData: async (formik) => {
    SearchFunctions.index(formik.values, formik);
  },

  changePage: async (page, formik) => {
    let pageChange = await formik.setFieldValue('page', page)
    SearchFunctions.index({...formik.values, ...{page: page} }, formik);
  },

  mapRows: (row, formik) => {
    return RowFunction(row, formik)
  },

  clearFilters: () => {
    window.location.href = "/activity_templates";
  },

  onChangeRowsPerPage: async (e, formik) => {
    let limit = await formik.setFieldValue('limit', e.target.value);
    let page = await formik.setFieldValue('page', 0)
    SearchFunctions.getData(formik)
  },

  handleAddButton: (formik) => {
    let url = "/activity_templates/new";
    formik.history.push(url)
  },

  bulkUpdateComponent: (formik) => {
    return null;
  },

  filtersComponent: (formik) => {
    return TableFilters(formik)
  },

  tableHeaders: () => [
    "ID",
    "Nazwa",
    "Czas",
    "Dyscyplina",
    "Lokalizacja",
    "MIejsc",
    "Ukryte",
    "Typ",
    "Zatwierdzony",
    "Info",
    "Data dodania",
    "",
  ],

  showAddButton: () => true,
  showClearButton: () => true,

  orderBy: () => [
    "activity_templates.created_at asc",
    "activity_templates.created_at desc",
    "activity_templates.confirmed asc",
    "activity_templates.confirmed desc",
    "activity_templates.price asc",
    "activity_templates.price desc",
    "activity_templates.start_at asc",
    "activity_templates.start_at desc",
    "activity_templates.canceled asc",
    "activity_templates.canceled desc",
    "activity_templates.activity_type asc",
    "activity_templates.activity_type desc",
    "activity_templates.hidden asc",
    "activity_templates.hidden desc",
    "activity_templates.approved_by_admin asc",
    "activity_templates.approved_by_admin desc",
  ],

  defaultValues: (props) => {
    const urlParams = new URLSearchParams(props.location.search);
    return {
      page: parseInt(urlParams.get("page")) || 0,
      limit: 25,
      data: { rows: [] },
      activitiesIDs: [],
      total: 0,
      with_id: urlParams.get("with_id") || "",
      with_tags: urlParams.get("with_tags") || "",
      with_localization_id: urlParams.get("with_localization_id") || "",
      localization: {},
      with_sport_id: urlParams.get("with_sport_id") || "",
      sport: {},
      with_pay_by_benefit: urlParams.get("with_pay_by_benefit") || "",
      with_pay_by_ok_system: urlParams.get("with_pay_by_ok_system") || "",
      with_promo: urlParams.get("with_promo") || "",
      with_coach: urlParams.get("with_coach") || "",
      with_canceled: urlParams.get("with_canceled") || "",
      with_creator_id: urlParams.get("with_creator_id") || "",
      with_min_participants: urlParams.get("with_min_participants") || "",
      with_activity_type: urlParams.get("with_activity_type") || "",
      with_gender: urlParams.get("with_gender") || "",
      with_city: urlParams.get("with_city") || "",
      with_start_at_greater: urlParams.get("with_start_at_greater") || "",
      with_start_at_less: urlParams.get("with_start_at_less") || "",
      with_hidden: urlParams.get("with_hidden") || "",
      with_confirmed: urlParams.get("with_confirmed") || "",
      with_approved_by_admin: urlParams.get("with_approved_by_admin") || "",
      order: urlParams.get("order") || "activity_templates.created_at asc",
      city: {},
      afterSave: props.afterSave,
    };
  },
};

export const FiltersForm = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...SearchFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    const tmp = {...values, ...{page: 0}}
    formikProps.setSubmitting(true); 
    
    SearchFunctions.index(tmp, formikProps)
      .then((response) => { })
      .then(() => {
        formikProps.setFieldValue("page", 0);
        formikProps.setTouched({}); 
      });
  },
})(({ ...props }) => <ActivitiesTable SearchFunctions={SearchFunctions} {...props} />));
