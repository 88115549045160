import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Pole Wymagane"),
  prefix: Yup.string().required("Pole Wymagane"),
  active_from: Yup.date().required("Pole Wymagane"),
  active_to: Yup.date().required("Pole Wymagane"),
  value: Yup.number().nullable(true).required("Pole Wymagane"),
});

export default ValidationSchema;
