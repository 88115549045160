import { withFormik } from "formik";
import validationSchema from "PNH/views/Activities/Search/ValidationSchema";
import ActivitiesTable from "PNH/components/Filters/Table"
import { Api, serializeParams } from "PNH/services/Api";
import { withRouter } from 'react-router-dom';
import { RowFunction } from "PNH/views/Activities/Search/RowFunction";
import { TableFilters } from "PNH/views/Activities/Search/Filters";
import moment from "moment";

export const SearchFunctions = {
  index: (values, formik) => {
    
    return Api.activities.admin
      .index({ ...values, ...{ data: [], search: true } })
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue("data", response.data);
          formik.setFieldValue("total", response.data.total);
          formik.setCounter(response.data.total)
        }
      });
  },

  getData: async (formik) => {
    SearchFunctions.index(formik.values, formik);
  },

  changePage: async (page, formik) => {
    let pageChange = await formik.setFieldValue('page', page)
    SearchFunctions.index({...formik.values, ...{page: page} }, formik);
  },

  mapRows: (row, formik) => {
    return RowFunction(row, formik)
  },

  clearFilters: () => {
    window.location.href = "/";
  },

  onChangeRowsPerPage: async (e, formik) => {
    let limit = await formik.setFieldValue('limit', e.target.value);
    let page = await formik.setFieldValue('page', 0)
    SearchFunctions.getData(formik)
  },

  handleAddButton: (formik) => {
    let url = "/activities/new";
    formik.history.push(url)
  },

  bulkUpdateComponent: (formik) => {
    return <></>
  },

  filtersComponent: (formik) => {
    return TableFilters(formik)
  },

  tableHeaders: () => [
    "",
    "ID",
    "Data",
    "Dyscyplina",
    "Lokalizacja",
    "Zapisanych",
    "Odwołane",
    "Ukryte",
    "Typ",
    "Potwierdzone",
    "Info",
    "",
  ],

  showAddButton: () => false,
  showClearButton: () => false,

  orderBy: () => [
    "activities.created_at asc",
    "activities.created_at desc",
    "activities.confirmed asc",
    "activities.confirmed desc",
    "activities.date asc",
    "activities.date desc",
    "activities.price asc",
    "activities.price desc",
    "activities.start_at asc",
    "activities.start_at desc",
    "activities.canceled asc",
    "activities.canceled desc",
    "activities.activity_type asc",
    "activities.activity_type desc",
    "activities.hidden asc",
    "activities.hidden desc",
  ],

  defaultValues: (props) => {
    const urlParams = new URLSearchParams(props.location.search);
    return {
      page: parseInt(urlParams.get("page")) || 0,
      limit: 25,
      flat: true,
      data: { rows: [] },
      activitiesIDs: [],
      total: 0,
      with_activities_id: urlParams.get("with_activities_id") || "",
      with_tags: urlParams.get("with_tags") || "",
      with_price_less: urlParams.get("with_price_less") || 500,
      with_price_greater: urlParams.get("with_price_greater") || 0,
      with_date_greater: urlParams.get("with_date_greater") || moment()
        .utc()
        .format("YYYY-MM-DD"),
      with_date_less: urlParams.get("with_date_less") || "",
      with_localization_id: urlParams.get("with_localization_id") || "",
      localization: {},
      with_sport_id: urlParams.get("with_sport_id") || "",
      sport: {},
      with_start_at_greater: "",
      with_start_at_less: urlParams.get("with_start_at_less") || "",
      with_pay_by_benefit: urlParams.get("with_pay_by_benefit") || "",
      with_pay_by_ok_system: urlParams.get("with_pay_by_ok_system") || "",
      with_promo: urlParams.get("with_promo") || "",
      with_coach: urlParams.get("with_coach") || "",
      with_canceled: urlParams.get("with_canceled") || "",
      with_coordinator: urlParams.get("with_coordinator") || "",
      with_min_participants: urlParams.get("with_min_participants") || "",
      with_activity_type: urlParams.get("with_activity_type") || "",
      with_gender: urlParams.get("with_gender") || "",
      with_archive: urlParams.get("with_archive") || "",
      with_city: urlParams.get("with_city") || "",
      with_start_at_greater: urlParams.get("with_start_at_greater") || "",
      with_start_at_less: urlParams.get("with_start_at_less") || "",
      with_hidden: urlParams.get("with_hidden") || "",
      with_confirmed: false,
      with_activity_type: "organizer",
      order: urlParams.get("order") || "activities.date asc",
      city: {},
      afterSave: props.afterSave,
    };
  },
};

export const ActivitiesToVerify = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...SearchFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    const tmp = {...values, ...{page: 0}}
    formikProps.setSubmitting(true); 
    
    SearchFunctions.index(tmp, formikProps)
      .then((response) => { })
      .then(() => {
        formikProps.setFieldValue("page", 0);
        formikProps.setTouched({}); 
      });
  },
})(({ ...props }) => <ActivitiesTable SearchFunctions={SearchFunctions} {...props} />));
