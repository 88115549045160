import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  add: (values, formik) => {
    return Api.activities.admin.coordinators
      .add(values.id, { user: { id: values.user_id } })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...UsersFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Dodano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  remove: (values, formik) => {
    return Api.activities.admin.coordinators
      .remove(values.id, { user: { id: values.user_id } })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...UsersFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Usunięto" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się usunąć" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id: props.activityID,
      action: "add",
      user_id: "",
      coordinators: [],
      user: {},
      afterSave: () => {},
    };
  },
};

export const CoordinatorsForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    UsersFunctions[values.action](values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
