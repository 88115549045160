import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ImageUpload from "PNH/components/CustomUpload/ImageUpload";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { SportsFunctions } from "./index";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {


  form() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      isSubmitting,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        
        <GridContainer>
          <GridItem xs={12}>
            <h4>
              <b>Benefit</b>
            </h4>
          </GridItem>
          <GridItem xs={12}><code>{values.result}</code></GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="ID"
                error={errors.benefit_id}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.benefit_id,
                  name: "benefit_id",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Pierwsza linia"
                error={errors.first_line}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.first_line,
                  name: "first_line",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Druga linia"
                error={errors.second_line}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.second_line,
                  name: "second_line",
                  onChange: handleChange,
                }}
              />
            </GridItem>
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm style={{ textAlign: "right" }}>
            <Button type="submit" color="success" disabled={isSubmitting}>
              Sprawdź kartę
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  render() {
    return this.form();
  }
}

export default withStyles(styles)(Form);
