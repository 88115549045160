import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  benefit_id: Yup.string().required("Pole Wymagane"),
  first_line: Yup.string().required("Pole Wymagane"),
  second_line: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
