import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  activity_id: Yup.string().required("Pole Wymagane"),
  user_id: Yup.string().required("Pole Wymagane"),
  date_played: Yup.date().required("Pole Wymagane"),
  report_type: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
