import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import { UsersFunctions } from "./index";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>

        <GridContainer>
        <GridItem xs={12}>
            <h4><b>Facebook</b>
            
            </h4>
            {values.facebook_uid ? (
                  <Primary>
                    <h5>
                      <b>podłączony</b>
                    </h5>
                  </Primary>
                ) : (
                  <Danger>
                    <h5>
                      <b>niepodłączony</b>
                    </h5>
                  </Danger>
                )}
          </GridItem>
          <GridItem xs={12} >
            <CustomInput
              labelText="Facebook id"
              error={errors.facebook_uid}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "fb id",
                value: values.facebook_uid,
                name: "facebook_uid",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
            />
          </GridItem>
          <GridItem style={{marginTop: '50px'}}>
            <h4><b>Dodatkowe ustawienia</b></h4>
          </GridItem>
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12} >
            <CustomInput
              labelText="Dodatkowe tagi"
              error={errors.custom_tags}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "#tag#tag2",
                value: values.custom_tags,
                name: "custom_tags",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
            />
          </GridItem>
          <GridItem xs={12} >
            <CustomInput
              labelText="Data Aktywacji Konta"
              error={errors.confirmed_at}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "RRRR-MM-DD",
                value: values.confirmed_at,
                name: "confirmed_at",
                onChange: handleChange,
                type: "text",
                autocomplete: "off",
              }}
            />
          </GridItem>

          <GridItem xs={12} >
            <FormControlLabel
              style={{ marginTop: "20px" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.hide_last_name}
                  onClick={() =>
                    setFieldValue("hide_last_name", !values.hide_last_name)
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Ukryj Nazwisko"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <FormControlLabel
              style={{ marginTop: "20px" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.fa}
                  onClick={() =>
                    setFieldValue("fa", !values.fa)
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Fake account"
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Notatki"
              error={errors.notes}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: values.notes,
                name: "notes",
                onChange: handleChange,
                autocomplete: "off",
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
                labelText="Skąd o nas wiesz?"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  value: values.how_know_about_us,
                  autocomplete: "off",
                  disabled: true,
                }}
            />
          </GridItem>
      </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem xs={3} style={{textAlign: 'right'}}>
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer >
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12}>
            <Button
              color="danger"
              className={classes.marginRight}
              onClick={() => this.deleteModal(true)}
            >
              Usuń konto
            </Button>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall,
              }}
              open={this.state.deleteModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.deleteModal(false)}
              aria-labelledby="small-modal-slide-title"
              aria-describedby="small-modal-slide-description"
            >
              <DialogTitle
                id="small-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.deleteModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
              </DialogTitle>
              <DialogContent
                id="small-modal-slide-description"
                className={classes.modalBody + " " + classes.modalSmallBody}
              >
                <h5>Na pewno chcesz usunąć konto?</h5>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button
                  onClick={() => this.deleteModal(false)}
                  color="transparent"
                  className={classes.modalSmallFooterFirstButton}
                >
                  Nie
                </Button>
                <Button
                  onClick={() => UsersFunctions.delete(this.props)}
                  color="success"
                  simple
                  className={
                    classes.modalSmallFooterFirstButton +
                    " " +
                    classes.modalSmallFooterSecondButton
                  }
                >
                  Tak
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
