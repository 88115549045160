import React from "react";
import {Api} from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import {Functions} from "./index";
import Primary from "components/Typography/Primary";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {I18n} from "../../../components/I18n";
import AsyncSelector from "../../../components/AsyncSelector/AsyncSelector";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteModal: false,
            fetch: !!this.props.values.id,
        };
        this.fetch();
    }

    deleteModal = (open) => {
        this.setState({deleteModal: open});
    };

    fetch = () => {
        if (this.props.values.id) {
            Api.competition_groups.admin.show(this.props.values.id).then((response) => {
                if (response.ok) {
                    this.props.setValues({
                        ...Functions.defaultValues(this.props),
                        ...response.data,
                    });
                    this.setState({fetch: false});
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    form() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
        } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <GridContainer justify="flex-end">
                    <GridItem xs={4}>
                        <Dialog
                            classes={{
                                root: classes.center + " " + classes.modalRoot,
                                paper: classes.modal + " " + classes.modalSmall,
                            }}
                            open={this.state.deleteModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => this.deleteModal(false)}
                            aria-labelledby="small-modal-slide-title"
                            aria-describedby="small-modal-slide-description"
                        >
                            <DialogTitle
                                id="small-modal-slide-title"
                                disableTypography
                                className={classes.modalHeader}
                            >
                                <Button
                                    justIcon
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    color="transparent"
                                    onClick={() => this.deleteModal(false)}
                                >
                                    <Close className={classes.modalClose}/>
                                </Button>
                            </DialogTitle>
                            <DialogContent
                                id="small-modal-slide-description"
                                className={classes.modalBody + " " + classes.modalSmallBody}
                            >
                                <h5>Na pewno chcesz usunąć?</h5>
                            </DialogContent>
                            <DialogActions
                                className={
                                    classes.modalFooter + " " + classes.modalFooterCenter
                                }
                            >
                                <Button
                                    onClick={() => this.deleteModal(false)}
                                    color="transparent"
                                    className={classes.modalSmallFooterFirstButton}
                                >
                                    Nie
                                </Button>
                                <Button
                                    onClick={() => Functions.delete(this.props)}
                                    color="success"
                                    simple
                                    className={
                                        classes.modalSmallFooterFirstButton +
                                        " " +
                                        classes.modalSmallFooterSecondButton
                                    }
                                >
                                    Tak
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={4}>
                        <CustomInput
                            labelText="Nazwa"
                            error={errors.name}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: values.name,
                                name: "name",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} md={6}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                error={!!errors.competition_type}
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                Rodzaj
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values.competition_group_type}
                                onChange={handleChange}
                                inputProps={{
                                    name: "competition_group_type",
                                }}
                            >
                                {["local_group", "organizer", "club", "tournament", "league", "b2b", "open_event", "other"].map(
                                    (t) => (
                                        <MenuItem
                                            key={`cot-${t}`}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value={t}
                                        >
                                            <b>{I18n.t(t)}</b>
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                Płeć
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values.gender}
                                onChange={handleChange}
                                inputProps={{
                                    name: "gender",
                                }}
                            >
                                {["males", "females", "unisex"].map((g) => (
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected,
                                        }}
                                        value={g}
                                    >
                                        {I18n.t(g)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <CustomInput
                            labelText="Max. ilość graczy w grupie"
                            error={errors.max_group_size}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "number",
                                value: values.max_group_size,
                                name: "max_group_size",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <CustomInput
                            labelText="Min. rozmiar drużyny"
                            error={errors.min_team_size}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "number",
                                value: values.min_team_size,
                                name: "min_team_size",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <CustomInput
                            labelText="Maks. rozmiar drużyny"
                            error={errors.max_players_per_team}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "number",
                                value: values.max_players_per_team,
                                name: "max_players_per_team",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <CustomInput
                            labelText="Maks. ilość drużyn"
                            error={errors.max_teams}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type: "number",
                                value: values.max_teams,
                                name: "max_teams",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Opis"
                            error={errors.description}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                value: values.description,
                                name: "description",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} md={6}>
                        <AsyncSelector
                            apiMethod={Api.users.admin.index}
                            apiShowMethod={Api.users.admin.show}
                            error={errors.creator_id}
                            labelKey="label"
                            label="Dodający (jeśli dodajesz w imieniu organizatora wybierz go z listy)"
                            name="creator_id"
                            defaultValue={values.creator_id}
                            value={
                                values.creator && {
                                    id: values.creator.id,
                                    label: values.creator.label,
                                }
                            }
                            onChange={(value, data) => {
                                setFieldValue("creator", data);
                                setFieldValue("creator_id", value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12}> <hr/></GridItem>
                    {[
                        "hidden",
                        "open",
                        "active",
                    ].map((p) => (
                        <GridItem xs={12} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        checked={values[p]}
                                        onClick={() => setFieldValue(p, !values[p])}
                                        checkedIcon={<Check className={classes.checkedIcon}/>}
                                        icon={<Check className={classes.uncheckedIcon}/>}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label,
                                    root: classes.labelRoot,
                                }}
                                label={I18n.t(p)}
                            />
                        </GridItem>
                    ))}


                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Dodatkowe tagi"
                            error={errors.custom_tags}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: values.custom_tags,
                                name: "custom_tags",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Notatki"
                            error={errors.notes}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                value: values.notes,
                                name: "notes",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>


                </GridContainer>
                <GridContainer justify="space-between">
                    <GridItem xs={12} sm>
                        {values.action === 'update' && <Button
                            color="danger"
                            className={classes.marginRight}
                            onClick={() => this.deleteModal(true)}
                        >
                            Usuń
                        </Button>}
                    </GridItem>
                    <GridItem xs={12} sm style={{textAlign: "right"}}>
                        <Button type="submit" color="success">
                            Zapisz
                        </Button>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }

    render() {
        if (this.state.fetch) {
            return <>Loading...</>;
        } else {
            return <>
                {this.form()}
            </>;
        }
    }
}

export default withStyles(styles)(Form);
