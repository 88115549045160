import React from "react";
import { LocationsForm } from "PNH/views/Locations/LocationsFrom/index";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class LocationsModal extends React.Component {
  state = {
    newModal: false,
    editModal: false,
  };
  newModal = (open) => {
    this.setState({ newModal: open });
  };
  editModal = (open) => {
    this.setState({ editModal: open });
  };
  renderDialogNew = () => {
    const { classes, locationID, afterSave, ...props } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.newModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.newModal(false)}
      >
        <DialogTitle
          id="small-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.newModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="small-modal-slide-description"
          className={classes.modalBody}
        >
          <LocationsForm
            modal
            afterSave={() => {
              this.newModal(false);
            }}
            locationID={""}
            {...props}
          />
        </DialogContent>
      </Dialog>
    );
  };

  renderDialogEdit = () => {
    const { classes, afterSave, ...props } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.editModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.editModal(false)}
      >
        <DialogTitle
          id="small-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.editModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="small-modal-slide-description"
          className={classes.modalBody}
        >
          <LocationsForm
            modal
            afterSave={() => {
              this.editModal(false);
              this.props.afterSave();
            }}
            {...props}
          />
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    return (
      <>
        {this.state.newModal && this.renderDialogNew()}
        {this.state.editModal &&
          this.props.locationID &&
          this.renderDialogEdit()}
        <GridContainer>
          <GridItem xs={12}>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.newModal(true)}
            >
              + Dodaj lokalizacje
            </Button>
            <Button
              color="primary"
              size="sm"
              disabled={!this.props.locationID}
              onClick={() => this.editModal(true)}
            >
              Edytuj lokalizacje
            </Button>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default withStyles(dashboardStyle)(LocationsModal);
