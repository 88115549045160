export const currentUrlParams = () => {
    return {
      urlParams: urlParams(),
      urlPath: window.location.pathname.split("/").slice(1)
    }
  }

  const urlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return Object.fromEntries(urlParams)
  }