import { withFormik } from "formik";
import moment from "moment";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";

export const UsersFunctions = {
  update: (values, formik) => {
    return Api.users.admin
      .update(values.id, { user: values })
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue(
            "role_ids",
            response.data.roles.map((r) => r.id)
          );
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id:
      currentUrlParams().urlPath[1] &&  currentUrlParams().urlPath[1] !== "new"
          ?  currentUrlParams().urlPath[1]
          : "",
      role_ids: [],
      all_roles: [],
      afterSave: () => {},
    };
  },
};

export const RolesForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    UsersFunctions.update(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
