import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const Functions = {
  create: (values, formik) => {
    return Api.competition_groups.teams.admin.create(values.competition_group_id, { competition_teams: values }).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Dodano stronę" },
        ]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.competition_groups.teams.admin
        .update(values.id, values.competition_group_id, { competition_teams: values })
        .then((response) => {
          if (response.ok) {
            formik.setValues({
              ...Functions.defaultValues(formik.props),
              ...response.data,
            });
            window.setNotifications([{ type: "success", msg: "Zapisano" }]);
          } else {
            window.setNotifications([
              { type: "alert", msg: "Nie udało się zapisać zmian" },
            ]);
          }
        });
  },


  defaultValues: (props) => {
    return {
      competition_group_id: props.competition_group_id,
      id: props.id,
      name: props.name,
      gender: props.gender,

      afterSave: () => {},
    };
  },
};

export const TeamsForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...Functions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      Functions.create(values, formikProps)
          .then((response) => {
            formikProps.props.afterSave(response);
          })
          .then(() => {
            formikProps.setTouched({});
          });
    } else {
      Functions.update(values, formikProps)
          .then((response) => {
            formikProps.props.afterSave(response);
          })
          .then(() => {
            formikProps.setTouched({});
          });
    }
  },
})(Form);
