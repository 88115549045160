import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  localization_id: Yup.number().required("Pole Wymagane"),
  location_object_id: Yup.number().required("Pole Wymagane"),
  sport_id: Yup.number().required("Pole Wymagane"),
  start_at: Yup.string().required("Pole Wymagane"),
  stop_at: Yup.string().required("Pole Wymagane"),
  // stop_at: Yup.string()
  //   .required("Pole Wymagane")
  //   .test("is-greater", "end time should be greater", function(value) {
  //     const { start_at } = this.parent;
  //     if (!start_at && !value) {
  //       return false;
  //     }
  //     const start_at_time =
  //       start_at.length < 6
  //         ? moment.utc({
  //             h: start_at.split(":")[0],
  //             m: start_at.split(":")[1],
  //           })
  //         : moment.utc({
  //             h: moment.utc(start_at).hours(),
  //             m: moment.utc(start_at).minutes(),
  //           });
  //     const stop_at_time =
  //       value.length < 6
  //         ? moment.utc({ h: value.split(":")[0], m: value.split(":")[1] })
  //         : moment.utc({
  //             h: moment.utc(value).hours(),
  //             m: moment.utc(value).minutes(),
  //           });
  //     return moment(stop_at_time).isAfter(moment(start_at_time));
  //   }),
  // description: Yup.string().required("Pole Wymagane"),
  reserved_spots: Yup.number()
    .min(0, "Minimalna wartość 0")
    .lessThan(Yup.ref("max_players"), "Wartość większa od maksymalnej"),
  max_players: Yup.number()
    .required("Pole Wymagane")
    .min(Yup.ref("min_players"), "Wartość większa od minimalnej"),

  min_players: Yup.number()
    .required("Pole number")
    .min(1, "Minimalna wartość 1"),
  price: Yup.number()
    .required("Pole Wymagane")
    .min(0, "Minimalna wartość 0"),
  max_price: Yup.number().nullable(true).when(["event_type"], {
    is: (event_type) => event_type === 'third_type',
    then: Yup.number().required("Pole Wymagane"),
  }),
  max_benefit_price: Yup.number().nullable(true).when(["event_type"], {
    is: (event_type) => event_type === 'third_type',
    then: Yup.number().required("Pole Wymagane"),
  }),
});

export default ValidationSchema;
