import React from "react";
import TransactionsTable from "./Transactions";
class Transactions extends React.Component {
  render() {
    const url = this.props.location.pathname.split("/");

    return (
        <TransactionsTable {...this.props} />
    );
  }
}

Transactions.propTypes = {};

export default Transactions;
