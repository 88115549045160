import moment from "moment";
import {Api} from "../../../services/Api";
import React, {useState} from "react";
import AsyncSelector from "../../../components/AsyncSelector/AsyncSelector";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import {I18n} from "../../../components/I18n";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "../../../../components/CustomButtons/Button";
import {ConfirmedForm} from "./Forms/ConfirmedForm";
import {ActivitiesForm} from "./Forms/ActivitiesForm";
import {UsersForm} from "./Forms/UsersForm";
import {OrganizerForm} from "./Forms/OrganizerForm";
import {NotesForm} from "./Forms/NotesForm";
import {RejectedForm} from "./Forms/RejectedForm";
import {TypeForm} from "./Forms/TypeForm";

export const RowFunction = (row, formik) => {
    const {classes, values, setFieldValue} = formik;

    return [
        <div style={{maxWidth: '50px', fontSize: 7}}>{row.uuid}</div>,
        row.facility_id,
        <div style={{maxWidth: '150px'}}>{row.facility_name}</div>,
        row.sn,
        row.terminal_type,
        row.date_played,
        row.player_card_no,
        row.organizer_login,
        <div style={{maxWidth: '50px'}}><ConfirmedForm key={`row-c-id-${row.id}`} SearchFunctions={formik.SearchFunctions} formikProps={formik} row={row} classes={classes}/></div>,
        <div style={{minWidth: '150px'}}><ActivitiesForm key={`row-a-id-${row.id}`} row={row} classes={classes}/></div>,
        <div style={{minWidth: '150px'}}><UsersForm key={`row-u-id-${row.id}`} row={row} classes={classes}/></div>,
        <div style={{minWidth: '150px'}}><OrganizerForm key={`row-o-id-${row.id}`} row={row} classes={classes}/></div>,
        <div style={{minWidth: '150px'}}><RejectedForm key={`row-n-id-${row.id}`} row={row} classes={classes}/></div>,
        <div style={{minWidth: '150px'}}><TypeForm key={`row-n-id-${row.id}`} row={row} classes={classes}/></div>,
        <div style={{minWidth: '150px'}}><NotesForm key={`row-n-id-${row.id}`} row={row} classes={classes}/></div>,
        row.created_at,
    ];
}


