import React, {useState} from "react";
import {Api} from "../../../../services/Api";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import Button from "../../../../../components/CustomButtons/Button";
import SaveIcon from '@material-ui/icons/Save';

export const NotesForm = ({row, classes}) => {
    const [notes, setNotes] = useState(row.notes)

    return <>
        <CustomInput
            labelText="Notatki"
            formControlProps={{
                fullWidth: true,
            }}
            inputProps={{
                multiline: true,
                value: notes,
                name: "notes",
                onChange: (e) => {
                    setNotes(e.target.value)
                },
            }}
        />

        {notes !== row.notes && <Button color={"primary"} justIcon={true} onClick={() => {
            Api.benefit_entries.admin.update(row.id, {notes: notes}).then((r) => {
                if (r.ok) {
                    window.setNotifications([
                        {
                            type: "success",
                            msg: "Zapisano",
                        },
                    ]);
                } else {
                    window.setNotifications([
                        {
                            type: "alert",
                            msg: "Nie zapisano",
                        },
                    ]);
                }
            })

        }}><SaveIcon/></Button>}
    </>
}


