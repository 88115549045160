/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState, useRef, Fragment } from "react";

import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "components/CustomButtons/Button";


export const NotificationsBell = ({ sidebar="header", classes, ...props }) => {
    const [notificationsCount, setNotificationsCount] = useState()

    const updateCounter = async (counter) => {
        setNotificationsCount(counter)
    }

    useEffect(() => {
        props.stateUpdates[`updateNotificationCounter${sidebar}`] = 
            (current_user) => updateCounter(currentUserNotificationsCount(current_user))
        
        updateCounter(currentUserNotificationsCount( props.currentUser()))
    }, [])

    return <Fragment key={`notification-bell-${notificationsCount}`} >
        <Button
            color="transparent"
            href="/"
            justIcon
            className={classes.buttonLink}
        >
            <Notifications
                className={
                    classes.headerLinksSvg +
                    " " +
                    classes.links
                }
            />
            {notificationsCount > 0 && <span  className={classes.notifications}>{notificationsCount}</span>}
        </Button>
    </Fragment>
}


const currentUserNotificationsCount = (currentUser) => {
    return currentUser.system_event_count.allUnread > currentUser.system_event_count.myUnread ? currentUser.system_event_count.allUnread : currentUser.system_event_count.myUnread
}
