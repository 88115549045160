import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { I18n } from "PNH/components/I18n";

class OpinionsTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    with_id: "",
    with_first_name: "",
    with_last_name: "",
    with_email: "",
    with_phone: "",
    with_created_at_less: "",
    with_created_at_greater: "",
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.users.admin.opinions
      .index(this.props.userID, {
        page: this.state.page,
        limit: this.state.limit,
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      row.id,
      I18n.t(row.level),
      row.user.label,
      row.activity.label,
      row.sport.name,
      row.created_at,
    "",
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };

  render() {
    const { classes } = this.props;
    return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Table
                  tableHead={[
                    "ID",
                    "Ocena",
                    "Od",
                    "Zajęcia",
                    "Dyscyplina",
                    "Data"
                  ]}
                  tableData={this.state.data}
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  page={this.state.page}
                  rowsPerPage={this.state.limit}
                  count={this.state.total}
                  onChangePage={this.changePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Ilość na stronie"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} z ${count}`
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}

OpinionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(extendedTablesStyle)(OpinionsTable);

