import Danger from "components/Typography/Danger";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import { I18n } from "PNH/components/I18n";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";

export const  RowFunction = (row, formik) => {
    const { classes, values, setFieldValue } = formik;
    return [

        row.id,
        row.first_name,
        row.last_name,
        row.email,
        row.phone,
        row.benefit_id,
        row.age,
        row.active ? "Tak" : "Nie",
        row.created_at,
        row.last_sign_in_at,
        <Button
            color="success"
            simple
            link
            href={`/users/${row.id}`}
            key={`edit_${row.id}`}
            className={classes.actionButton}
        >
            <Edit className={classes.icon} />
        </Button>,
    ];
} 