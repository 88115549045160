import React from "react";
import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

class SportsTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    with_id: "",
    with_name: "",
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.mainPageTiles.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      row.id,
      row.name,
      row.section,
      row.tile_type,
      row.hidden ? "Tak" : "Nie",
      row.order_value,
      <Button
        color="success"
        simple
        onClick={() => {
          let url = `/main_page_tiles/${row.id}`;
          this.props.history.push(url)
        }}
        key={`edit_${row.id}`}
        className={classes.actionButton}
      >
        <Edit className={classes.icon} />
      </Button>,
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };
  render() {
    const { classes } = this.props;
    return (
        <GridContainer>
          <GridItem xs={12}>
            <Button
              style={{ float: "right" }}
              color="success"
              onClick={() => {
                let url = "/main_page_tiles/new";
                this.props.history.push(url)
              }}
            >
              Dodaj
            </Button>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <h4 className={classes.cardIconTitle}>Kafelki</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHead={["ID", "Nazwa", "Sekcja", "Typ", "Ukryte", "Kolejność", ""]}
                  tableData={this.state.data}
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  page={this.state.page}
                  rowsPerPage={this.state.limit}
                  count={this.state.total}
                  onChangePage={this.changePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Ilość na stronie"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} z ${count}`
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}

SportsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(extendedTablesStyle)(SportsTable));
