import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Api } from "PNH/services/Api";
import {
    dailySalesChart,
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import moment from "moment";


const useStyles = makeStyles(styles);

const days = { 0: "N", 1: "P", 2: "W", 3: "Ś", 4: "C", 5: "P", 6: "S", }

const parseData = (r) => {
    let labels = []
    let today  = moment().day()
    let data = {}
    let count = []
    for(let i=6; i >= 0; i--){
        labels.push(days[today])
        count.push(today)
        data[today] = 0
        if(today != 0) {
            today-= 1
        } else {
            today = 6
        }
    }
    labels = labels.reverse()
    count = count.reverse()
    r.data.rows.map((row) => {
        data[moment(row.date).day()] = row.count
        
    })

    return {
        data: {
            labels: labels,
            series: [count.map((d) => {
                return data[d]
            })],
        },
    }
}

export const Charts = ({ }) => {
    const [dataActivitiesPerDay, setDataActivitiesPerDay] = useState({data: {labels: [], series: [[]]}})
    const [newUsers, setNewUsers] = useState({data: {labels: [], series: [[]]}})
    const [boughtActivities, setBoughtActivities] = useState({data: {labels: [], series: [[]]}})


    useEffect(() => {
        Api.stats.admin.activities_per_day().then(r => {
            setDataActivitiesPerDay(
                parseData(r)
            )
        })
        Api.stats.admin.new_users().then(r => {
            setNewUsers(
                parseData(r)
            )
        })

        Api.stats.admin.bought_activities().then(r => {
            setBoughtActivities(
                parseData(r)
            )
        })
    }, [])

    const classes = useStyles();
    return <>
        <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                <Card chart className={classes.cardHover}>
                    <CardHeader color="primary" className={classes.cardHeaderHover}>
                        <ChartistGraph
                            className="ct-chart-white-colors"
                            data={dataActivitiesPerDay.data}
                            type="Bar"
                            options={{...dailySalesChart.options, ...{high: 60}}}
                            listener={dailySalesChart.animation}
                        />
                    </CardHeader>
                    <CardBody>
                        <div className={classes.cardHoverUnder}>
                            <Tooltip
                                id="tooltip-top"
                                title="Refresh"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button simple color="info" justIcon>
                                    <Refresh className={classes.underChartIcons} />
                                </Button>
                            </Tooltip>
                            <Tooltip
                                id="tooltip-top"
                                title="Change Date"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button color="transparent" simple justIcon>
                                    <Edit className={classes.underChartIcons} />
                                </Button>
                            </Tooltip>
                        </div>
                        <h4 className={classes.cardTitle}>Zajęcia w ostatnim tygodniu</h4>
                        <p className={classes.cardCategory}>
                            
                            Odbyło się <span className={classes.successText}>
                                {dataActivitiesPerDay.data.series[0].reduce((partialSum, a) => partialSum + a, 0)}
                            </span>{" "} zajęć w ostatnim tygodniu
                        </p>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            <AccessTime /> Dane z ostatnich 15min
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Card chart className={classes.cardHover}>
                    <CardHeader color="primary" className={classes.cardHeaderHover}>
                        <ChartistGraph
                            className="ct-chart-white-colors"
                            data={newUsers.data}
                            type="Line"
                            options={dailySalesChart.options}
                            listener={dailySalesChart.animation}
                        />
                    </CardHeader>
                    <CardBody>
                        <div className={classes.cardHoverUnder}>
                            <Tooltip
                                id="tooltip-top"
                                title="Refresh"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button simple color="info" justIcon>
                                    <Refresh className={classes.underChartIcons} />
                                </Button>
                            </Tooltip>
                            <Tooltip
                                id="tooltip-top"
                                title="Change Date"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button color="transparent" simple justIcon>
                                    <Edit className={classes.underChartIcons} />
                                </Button>
                            </Tooltip>
                        </div>
                        <h4 className={classes.cardTitle}>Nowi użytkownicy</h4>
                        <p className={classes.cardCategory}>
                           Przybyło <span className={classes.successText}>
                                {newUsers.data.series[0].reduce((partialSum, a) => partialSum + a, 0)}
                            </span>{" "} nowych użytkowników w ostatnim tygodniu
                        </p>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            <AccessTime /> Dane z ostatnich 15min
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Card chart className={classes.cardHover}>
                    <CardHeader color="primary" className={classes.cardHeaderHover}>
                        <ChartistGraph
                            className="ct-chart-white-colors"
                            data={boughtActivities.data}
                            type="Bar"
                            options={{...dailySalesChart.options, ...{high: 600}}}
                            listener={dailySalesChart.animation}
                        />
                    </CardHeader>
                    <CardBody>
                        <div className={classes.cardHoverUnder}>
                            <Tooltip
                                id="tooltip-top"
                                title="Refresh"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button simple color="info" justIcon>
                                    <Refresh className={classes.underChartIcons} />
                                </Button>
                            </Tooltip>
                            <Tooltip
                                id="tooltip-top"
                                title="Change Date"
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Button color="transparent" simple justIcon>
                                    <Edit className={classes.underChartIcons} />
                                </Button>
                            </Tooltip>
                        </div>
                        <h4 className={classes.cardTitle}>Wykupione zajęcia</h4>
                        <p className={classes.cardCategory}>
                           Kupiono <span className={classes.successText}>
                                {boughtActivities.data.series[0].reduce((partialSum, a) => partialSum + a, 0)}
                            </span>{" "} zajęć w ostatnim tygodniu
                        </p>
                    </CardBody>
                    <CardFooter chart>
                        <div className={classes.stats}>
                            <AccessTime /> Dane z ostatnich 15min
                        </div>
                    </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
    </>
}