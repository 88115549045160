import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import LocationsModal from "./Locations";
import { ActivitiesFunctions } from "./index";
import ImageUpload from "PNH/components/CustomUpload/ImageUpload";
import moment from "moment";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import Accordion from "components/Accordion/Accordion";
import { I18n } from "PNH/components/I18n";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
    this.state = {
      deleteModal: false,
      fetchActivity: !!props.values.id,
      fetchSports: true,
      fetchLocations: true,
    };
    this.fetchLocalizations();
    Api.sports.admin.index().then((response) => {
      if (response.ok) {
        this.props.setFieldValue("sports", response.data.rows);
        this.setState({ fetchSports: false });
      }
    });
  }

  fetchLocalizations = () => {
    Api.locations.admin.index().then((response) => {
      if (response.ok) {
        this.props.setFieldValue("localizations", response.data.rows);
        this.setState({ fetchLocations: false });
      }
    });
  };

  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.activities.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          Object.keys(response.data).map((k) => {
            this.props.setFieldValue(k, response.data[k]);
          });
          this.setState({ fetchActivity: false });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };

  formSport() {
    return 
  }

  form() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer justify="flex-end">
          <GridItem xs={4}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall,
              }}
              open={this.state.deleteModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.deleteModal(false)}
              aria-labelledby="small-modal-slide-title"
              aria-describedby="small-modal-slide-description"
            >
              <DialogTitle
                id="small-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.deleteModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
              </DialogTitle>
              <DialogContent
                id="small-modal-slide-description"
                className={classes.modalBody + " " + classes.modalSmallBody}
              >
                <h5>Na pewno chcesz usunąć?</h5>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button
                  onClick={() => this.deleteModal(false)}
                  color="transparent"
                  className={classes.modalSmallFooterFirstButton}
                >
                  Nie
                </Button>
                <Button
                  onClick={() => ActivitiesFunctions.delete(this.props)}
                  color="success"
                  simple
                  className={
                    classes.modalSmallFooterFirstButton +
                    " " +
                    classes.modalSmallFooterSecondButton
                  }
                >
                  Tak
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <h4>
              <b>Zmień w zajęciach</b>
            </h4>
          </GridItem>




          <Accordion
                    active={[]}
                    activeColor="rose"
                    collapses={[
                      { 
                        title: "Dyscyplina",
                        content:  <GridItem xs={12} md={6}>
                                    <AsyncSelector
                                      apiMethod={Api.sports.admin.index}
                                      apiShowMethod={Api.sports.admin.show}
                                      labelKey="name"
                                      label="Dyscyplina"
                                      name="sports"
                                      defaultValue={values.sport_id}
                                      value={
                                        values.sport && {
                                          id: values.sport.id,
                                          label: values.sport.name,
                                        }
                                      }
                                      onChange={(value, data) => {
                                        setFieldValue("sport", data);
                                        setFieldValue("sport_id", value);
                                      }}
                                    />
                                </GridItem>
                      }, 
                      {
                        title: "Lokalizacja",
                        content: <GridContainer>
                          <GridItem xs={12} md={6}>
                            <AsyncSelector
                              apiMethod={Api.locations.admin.index}
                              apiShowMethod={Api.locations.admin.show}
                              labelKey="label"
                              label="Lokalizacja"
                              name="locations"
                              defaultValue={values.localization_id}
                              value={
                                values.localization && {
                                  id: values.localization.id,
                                  label: values.localization.label,
                                }
                              }
                              onChange={(value, data) => {
                                setFieldValue("localization", data);
                                setFieldValue("localization_id", value);
                                if (values.localization_id !== value) {
                                  setFieldValue("location_object_id", "");
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} md={6}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <InputLabel
                                error={!!errors.location_object_id}
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Obiekt
                              </InputLabel>
                              <Select
                                key={`as-loc-ob-${values.localization &&
                                  values.localization.id}`}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={values.location_object_id}
                                onChange={handleChange}
                                inputProps={{
                                  name: "location_object_id",
                                }}
                              >
                                {values.localization &&
                                  values.localization.location_objects_attributes &&
                                  values.localization.location_objects_attributes.map(
                                    (lObject) => (
                                      <MenuItem
                                        key={`lo-${lObject.id}`}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected,
                                        }}
                                        value={lObject.id}
                                      >
                                        {lObject.description} <br />
                                        <b>{I18n.t(lObject.object_type)}</b>
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12}>
                            <LocationsModal
                              updateLocation={(response) => {
                                setFieldValue("localization", response.data);
                                setFieldValue("localization_id", response.data.id);
                              }}
                              locationID={values.localization_id}
                              {...this.props}
                            />
                          </GridItem>
                        </GridContainer>,
                      },
                      {
                        title: "Data i godzina",
                        content: <GridContainer>
                            <GridItem md={3}>
                              <>
                                <InputLabel error={!!errors.date} className={classes.label}>
                                  Data zajęć
                                </InputLabel>
                                <br />
                                <FormControl fullWidth style={{ zIndex: 10 }}>
                                  <Datetime
                                    utc
                                    value={values.date}
                                    dateFormat={"YYYY-MM-DD"}
                                    timeFormat={false}
                                    onChange={(value) => setFieldValue("date", value)}
                                    inputProps={{ name: "date", autocomplete: "off" }}
                                  />
                                </FormControl>
                              </>
                            </GridItem>
                            <GridItem md={3}>
                              <InputLabel error={!!errors.start_at} className={classes.label}>
                                Czas rozpoczęcia
                              </InputLabel>
                              <br />
                              <FormControl fullWidth style={{ zIndex: 10 }}>
                                <Datetime
                                  utc
                                  value={values.start_at}
                                  dateFormat={false}
                                  timeFormat={"H:mm"}
                                  onChange={(value) => {
                                    const format = moment(value).format("H:mm");
                                    const time = format === "Invalid date" ? value : format;
                                    setFieldValue("start_at", time);
                                  }}
                                  inputProps={{ name: "start_at", autocomplete: "off" }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem md={3}>
                              <InputLabel error={!!errors.stop_at} className={classes.label}>
                                Czas zakończenia
                              </InputLabel>
                              <br />
                              <FormControl fullWidth style={{ zIndex: 10 }}>
                                <Datetime
                                  utc
                                  value={values.stop_at}
                                  dateFormat={false}
                                  timeFormat={"H:mm"}
                                  onChange={(value) => {
                                    const format = moment(value).format("H:mm");
                                    const time = format === "Invalid date" ? value : format;
                                    setFieldValue("stop_at", time);
                                  }}
                                  inputProps={{ name: "stop_at", autocomplete: "off" }}
                                />
                              </FormControl>
                            </GridItem>
                        </GridContainer>,
                      },
                      {
                        title: "Cena, Płeć, Poziom",
                        content: <GridContainer>
                           <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText="Cena"
                              error={errors.price}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "number",
                                value: values.price,
                                name: "price",
                                onChange: handleChange,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText="Dopłata"
                              error={errors.supplement}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "number",
                                value: values.supplement,
                                name: "supplement",
                                onChange: handleChange,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText="Dopłata Medicover"
                              error={errors.supplement_medicover}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "number",
                                value: values.supplement_medicover,
                                name: "supplement_medicover",
                                onChange: handleChange,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                                labelText="Dopłata FitProfit"
                                error={errors.supplement_fitprofit}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "number",
                                  value: values.supplement_fitprofit,
                                  name: "supplement_fitprofit",
                                  onChange: handleChange,
                                }}
                            />
                          </GridItem>
                          {values.event_type === 'third_type' && (
                            <GridItem xs={12} md={3}>
                              <CustomInput
                                  labelText="Maksymalna cena"
                                  error={errors.max_price}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    value: values.max_price,
                                    name: "max_price",
                                    onChange: handleChange,
                                  }}
                              />
                            </GridItem>
                          )}
                          {values.event_type === 'third_type' && (
                            <GridItem xs={12} md={3}>
                              <CustomInput
                                  labelText="Maksymalna cena z MS"
                                  error={errors.max_benefit_price}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    value: values.max_benefit_price,
                                    name: "max_benefit_price",
                                    onChange: handleChange,
                                  }}
                              />
                            </GridItem>
                          )}
                          {values.event_type === 'third_type' && (
                              <GridItem xs={12} md={3}>
                                <CustomInput
                                    labelText="Maksymalna cena z FitProfit"
                                    error={errors.max_fitprofit_price}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: "number",
                                      value: values.max_fitprofit_price,
                                      name: "max_fitprofit_price",
                                      onChange: handleChange,
                                    }}
                                />
                              </GridItem>
                          )}
                          <GridItem xs={12} md={3}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Płeć
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={values.gender}
                                onChange={handleChange}
                                inputProps={{
                                  name: "gender",
                                }}
                              >
                                {["males", "females", "unisex"].map((g) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={g}
                                  >
                                    {I18n.t(g)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                                labelText={"Minimalny wiek"}
                                error={errors.min_age}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "number",
                                  value: values.min_age,
                                  name: "min_age",
                                  onChange: handleChange,
                                }}
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                                labelText={"Maksymalny wiek"}
                                error={errors.max_age}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "number",
                                  value: values.max_age,
                                  name: "min_age",
                                  onChange: handleChange,
                                }}
                            />
                          </GridItem>
                          <GridItem xs={12} md={3} lg={3}>
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Poziom
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={values.lv}
                                onChange={handleChange}
                                inputProps={{
                                  name: "lv",
                                }}
                              >
                                {[
                                  "free",
                                  "beginners",
                                  "medium",
                                  "intermediate",
                                  "advanced",
                                ].map((lv) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={lv}
                                  >
                                    {I18n.t(lv)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>,
                      },
                      {
                        title: "Ilość miejsc",
                        content: <GridContainer>
                              <GridItem xs={12} md={4}>
                                <CustomInput
                                  labelText="Min. ilość graczy"
                                  error={errors.min_players}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    value: values.min_players,
                                    name: "min_players",
                                    onChange: handleChange,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <CustomInput
                                  labelText="Ilość miejsc"
                                  error={errors.max_players}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    value: values.max_players,
                                    name: "max_players",
                                    onChange: handleChange,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} md={4}>
                                <CustomInput
                                  labelText="Zarezerwowane miejsca"
                                  error={errors.reserved_spots}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "number",
                                    value: values.reserved_spots,
                                    name: "reserved_spots",
                                    onChange: handleChange,
                                  }}
                                />
                              </GridItem>
                        </GridContainer>,
                      },
                      {
                        title: "Płatność",
                        content: <GridContainer>
                           {[
                            "pay_by_cash",
                            "pay_by_online_transfer",
                            "pay_by_prepaid",
                            "pay_by_benefit",
                            "pay_by_ok_system",
                             "pay_by_fitprofit",
                             "pay_by_bonus_account"
                          ].map((p) => (
                            <GridItem xs={12} md={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    checked={values[p]}
                                    onClick={() => setFieldValue(p, !values[p])}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot,
                                }}
                                label={I18n.t(p)}
                              />
                            </GridItem>
                          ))}
                        </GridContainer>
                      }
                    ]} />
          
          <GridItem xs={12}>
            <CustomInput
              labelText="Opis"
              error={errors.description}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: values.description,
                name: "description",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <AsyncSelector
              apiMethod={Api.pages.admin.index}
              apiShowMethod={Api.pages.admin.show}
              labelKey="name"
              label="Strona"
              name="page_id"
              defaultValue={values.page_id}
              value={
                values.page && {
                  id: values.page.id,
                  label: values.page.name,
                }
              }
              onChange={(value, data) => {
                setFieldValue("page", data);
                setFieldValue("page_id", value);
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Notatki"
              error={errors.notes}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: values.notes,
                name: "notes",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <h5>
              <b>Widoczność zajęć</b>
            </h5>
          </GridItem>
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values['confirmed']}
                  onClick={() => {
                    values['confirmed'] && setFieldValue('event_type', ''); // Dirty hack for cleaning event type on removing confirmation
                    setFieldValue('confirmed', !values['confirmed'])
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={I18n.t('confirmed')}
            />
          </GridItem>
          <GridItem md={3}>
            <InputLabel error={!!errors.publish_date} className={classes.label}>
              Data publikacji
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
              <Datetime
                utc
                value={values.publish_date}
                dateFormat={"YYYY-MM-DD"}
                timeFormat={"H:mm"}
                onChange={(value) => setFieldValue("publish_date", value)}
                inputProps={{ name: "publish_date", autocomplete: "off" }}
              />
            </FormControl>
          </GridItem>
          {["canceled"].map(
            (p) => (
              <GridItem xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={values[p]}
                      onClick={() => setFieldValue(p, !values[p])}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={I18n.t(p)}
                />
              </GridItem>
            )
          )}
          <GridItem xs={12}></GridItem>
          {["hidden"].map(
            (p) => (
              <GridItem xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={values[p]}
                      onClick={() => setFieldValue(p, !values[p])}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={I18n.t(p)}
                />
              </GridItem>
            )
          )}
          <GridItem xs={12} />
          {values.hidden && <><GridItem xs={12} sm={6}>
            <InputLabel
              error={!!errors.change_to_public_at}
              className={classes.label}
            >
              Zmień na publiczne
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
              <Datetime
                value={values.change_to_public_at}
                dateFormat={"YYYY-MM-DD"}
                timeFormat={"H:mm"}
                onChange={(value) =>
                  setFieldValue("change_to_public_at", value)
                }
                inputProps={{
                  name: "change_to_public_at",
                  autocomplete: "off",
                }}
              />
            </FormControl>
          </GridItem> 
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Cena po odkryciu"
              error={errors.price_on_publish}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.price_on_publish,
                name: "price_on_publish",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Dopłata benefi po odkryciu"
              error={errors.supplement_on_publish}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.supplement_on_publish,
                name: "supplement_on_publish",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText="Dopłata Medicover po odkryciu"
              error={errors.supplement_medicover_on_publish}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.supplement_medicover_on_publish,
                name: "supplement_medicover_on_publish",
                onChange: handleChange,
              }}
            />
          </GridItem>
            <GridItem xs={12} md={6}>
              <CustomInput
                  labelText="Dopłata FitProfit po odkryciu"
                  error={errors.supplement_fitprofit_on_publish}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.supplement_fitprofit_on_publish,
                    name: "supplement_fitprofit_on_publish",
                    onChange: handleChange,
                  }}
              />
            </GridItem>
            <GridItem xs={12} md={12}>
              {["deposit_disabled_on_publish"].map(
                  (p) => (
                      <GridItem xs={12} sm={3}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  tabIndex={-1}
                                  checked={values[p]}
                                  onClick={() => setFieldValue(p, !values[p])}
                                  checkedIcon={<Check className={classes.checkedIcon} />}
                                  icon={<Check className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                              />
                            }
                            classes={{
                              label: classes.label,
                              root: classes.labelRoot,
                            }}
                            label={I18n.t(p)}
                        />
                      </GridItem>
                  )
              )}
            </GridItem>
          </>}
          <GridItem xs={12}>
            <h5>
              <b>Ustawienia</b>
            </h5>
            <hr />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormControlLabel
                control={
                  <Checkbox
                      tabIndex={-1}
                      checked={values['verify_lv']}
                      onClick={() => {
                        values['verify_lv'] 
                        setFieldValue('verify_lv', !values['verify_lv'])
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={I18n.t('verify_lv')}
            />
          </GridItem>
          {values.confirmed && (
              <GridItem xs={12} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                  >
                    Typ wydarzenia
                  </InputLabel>
                  <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={values.event_type}
                      onChange={handleChange}
                      inputProps={{
                        name: "event_type",
                      }}
                  >
                    {[["Organizator 1.0", "first_type"],
                      ["Organizator 2.0", "second_type"],
                      ["Organizator 3.0", "third_type"]].map((g) => (
                        <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={g[1]}
                        >
                          {g[0]}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
          )}
          <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
              >
                Organizator
              </InputLabel>
              <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={values.activity_type}
                  onChange={({ ...arg }) => {
                    handleChange(arg);
                    setFieldValue("event_type", null);
                  }}
                  inputProps={{
                    name: "activity_type",
                  }}
              >
                {[["PNH", "pnh"],
                  ["Organizator", "organizer"],
                ].map((g) => (
                    <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={g[1]}
                    >
                      {g[0]}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          {["coach", "deposit_disabled"].map(
            (p) => (
              <GridItem xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={values[p]}
                      onClick={() => setFieldValue(p, !values[p])}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={I18n.t(p)}
                />
              </GridItem>
            )
          )}
          <GridItem xs={12}>
            <CustomInput
              labelText="Dodatkowe tagi (#promocja#nowosc)"
              error={errors.custom_tags}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: values.custom_tags,
                name: "custom_tags",
                onChange: handleChange,
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <CustomInput
              labelText="Nr Medicover Sport"
              error={errors.ok_system_code}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: values.ok_system_code,
                name: "ok_system_code",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Notatki"
              error={errors.notes}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: values.notes,
                name: "notes",
                onChange: handleChange,
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <AsyncSelector
              apiMethod={Api.users.admin.index}
              apiShowMethod={Api.users.admin.show}
              labelKey="label"
              label="Dodający (jeśli dodajesz w imieniu organizatora wybierz go z listy)"
              name="creator_id"
              defaultValue={values.creator_id}
              value={
                values.creator && {
                  id: values.creator.id,
                  label: values.creator.label,
                }
              }
              onChange={(value, data) => {
                setFieldValue("creator", data);
                setFieldValue("creator_id", value);
              }}
            />
          </GridItem>
          {!values.id && <GridItem xs={12}>
            <AsyncSelector
              apiMethod={Api.users.admin.index}
              apiShowMethod={Api.users.admin.show}
              labelKey="label"
              label="Dopisz do listy koordynatorów"
              name="coordinator_id"
              defaultValue={values.coordinator_id}
              value={
                values.coordinator && {
                  id: values.coordinator.id,
                  label: values.coordinator.label,
                }
              }
              onChange={(value, data) => {
                setFieldValue("coordinator", data);
                setFieldValue("coordinator_id", value);
              }}
            />
          </GridItem> }

          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12} md={6}>
            <h6>Baner</h6>
            <ImageUpload
              value={process.env.REACT_APP_API_URL+values.baner_image}
              onChange={(file) => {
                setFieldValue("baner_image", file);
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <h6>Kafelek</h6>
            <ImageUpload
              value={process.env.REACT_APP_API_URL+values.thumb_image}
              onChange={(file) => {
                setFieldValue("thumb_image", file);
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm style={{ textAlign: "right" }}>
            <Button
              type="submit"
              color="success"
              disabled={this.props.isSubmitting}
            >
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  render() {
    const { fetchActivity, fetchLocalizations, fetchLocations } = this.state;
    if (!fetchActivity && !fetchLocalizations && !fetchLocations) {
      return this.form();
    } else {
      return <h4>Loading...</h4>;
    }
  }
}

export default withStyles(styles)(Form);
