/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
var oldLog = console.error
window.console.error = function (message) {
  if(typeof message.startsWith !== "function"){
   return oldLog.apply(console, arguments);
  }
  if (message.startsWith('Material-UI:')) {
    return false
  }
  if (!message.includes('Warning')) {
    return oldLog.apply(console, arguments);
  }
  if (!message.startsWith('Material-UI:') && message.includes('Warning')) {
    return console.warn(console, arguments)
  }
};

import React from "react";
import ReactDOM from "react-dom";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import "PNH/assets/css/pnh.scss";
import Application from "PNH/application";

ReactDOM.render(
  <Application />,
  document.getElementById("root")
);
