import React from "react";
import {Api, serializeParams} from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Table from "components/Table/Table";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import CloseIcon from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Danger from "components/Typography/Danger";
import {I18n} from "PNH/components/I18n";
import {FormController} from "../../../BenefitEntries/Form";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {
    state = {
        deleteModal: false,
        addModal: false,
        toDelete: {},
        toAdd: undefined,
        with_fa: false,
        disableListSender: false,
    };

    constructor(props) {
        super(props);
        this.fetch();
    }

    deleteModal = (open) => {
        this.setState({deleteModal: open});
    };

    addModal = (open) => {
        this.setState({addModal: open});
    };

    renderModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSmall,
                }}
                open={this.state.deleteModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.deleteModal(false)}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogTitle
                    id="small-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.deleteModal(false)}
                    >
                        <Close className={classes.modalClose}/>
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="small-modal-slide-description"
                    className={classes.modalBody + " " + classes.modalSmallBody}
                >
                    <h5>Na pewno chcesz wypisać gracza?</h5>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button
                        onClick={() => this.deleteModal(false)}
                        color="transparent"
                        className={classes.modalSmallFooterFirstButton}
                    >
                        Nie
                    </Button>
                    <Button
                        onClick={() => {
                            this.delete(this.state.toDelete, false);
                            this.deleteModal(false);
                        }}
                        color="success"
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Tak, bez kosztów.
                    </Button>
                    <Button
                        onClick={() => {
                            this.delete(this.state.toDelete, true);
                            this.deleteModal(false);
                        }}
                        color="warning"
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Tak, z kosztami
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    renderAddModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal + " " + classes.modalSmall,
                }}
                open={this.state.addModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.addModal(false)}
                aria-labelledby="small-modal-slide-title"
                aria-describedby="small-modal-slide-description"
            >
                <DialogTitle
                    id="small-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.addModal(false)}
                    >
                        <Close className={classes.modalClose}/>
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="small-modal-slide-description"
                    className={classes.modalBody + " " + classes.modalSmallBody}
                >
                    <h5>Na pewno chcesz zapisać gracza?</h5>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button
                        onClick={() => this.addModal(false)}
                        color="transparent"
                        className={classes.modalSmallFooterFirstButton}
                    >
                        Nie
                    </Button>
                    <Button
                        onClick={() => {
                            this.add(this.state.toAdd, false);
                            this.addModal(false);
                        }}
                        color="success"
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Tak, bez kosztów.
                    </Button>
                    <Button
                        onClick={() => {
                            this.add(this.state.toAdd, true);
                            this.addModal(false);
                        }}
                        color="warning"
                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Tak, z kosztami
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    fetch = () => {
        if (this.props.activityID) {
            Api.activities.admin.participants
                .index(this.props.activityID, {limit: 1000})
                .then((response) => {
                    if (response.ok) {
                        this.props.setFieldValue("data", response.data.rows);
                    } else {
                        window.setNotifications([
                            {type: "alert", msg: "Coś poszło nie tak"},
                        ]);
                    }
                });
        }
    };

    sendList = (activity) => {
        this.setState({disableListSender: true}, () => Api.activities.admin.participants.send_list(activity.id).then(response => {
            if (response.ok) {
                window.setNotifications([
                    {type: "success", msg: "Zlecono wysyłkę listy"},
                ]);
            }
        }))
    }

    update = (row, params) => {
        Api.activities.admin.participants
            .update(row.activity_id, row.id, {participant: params})
            .then((response) => {
                if (response.ok) {
                    this.props.setFieldValue("data", response.data.rows);
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
    };
    delete = (row, with_costs) => {
        Api.activities.admin.participants
            .delete(row.activity_id, row.id, with_costs)
            .then((response) => {
                if (response.ok) {
                    this.props.setFieldValue("data", response.data.rows);
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
    };
    add = (user_id, with_costs) => {
        Api.activities.admin.participants
            .add(this.props.activity.id, user_id, with_costs)
            .then((response) => {
                if (response.ok) {
                    this.props.setFieldValue("data", response.data.rows);
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
    };

    render() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            activity,
        } = this.props;
        const disabled = !activity.past;

        return (
            <GridContainer>
                {this.renderModal()}
                {this.renderAddModal()}
                <GridItem xs={12}>
                    <h3>Lista zapisanych</h3>
                    <hr/>
                    Zapisz osobę na zajęcia zajęcia:
                    <GridContainer jusify="space-between">
                        {!activity.past && (
                            <>
                                <GridItem xs>
                                    <AsyncSelector
                                        apiMethod={(params) => Api.users.admin.index({with_fa: this.state.with_fa, ...params})}
                                        labelKey="label"
                                        label="Użytkownik"
                                        name="user"
                                        onChange={(value, data) => {
                                            this.setState({toAdd: value});
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            htmlFor="simple-select"
                                            className={classes.selectLabel}
                                        >
                                            FA List
                                        </InputLabel>
                                        <Select

                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={this.state.with_fa}
                                            onChange={(e) => {
                                                this.setState({with_fa: e.target.value})
                                            }}
                                            inputProps={{
                                                name: "presence",
                                            }}
                                        >
                                            <MenuItem
                                                key={`par-fa`}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value={true}
                                            >
                                                Tak
                                            </MenuItem>
                                            <MenuItem
                                                key={`par-no-fa}`}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value={false}
                                            >
                                                Nie
                                            </MenuItem>
                                        </Select>
                                    </FormControl>,
                                </GridItem>
                                <GridItem xs>
                                    <Button
                                        color="primary"
                                        disabled={!this.state.toAdd}
                                        onClick={async (e) => {
                                            this.addModal(true);
                                        }}
                                    >
                                        Dodaj
                                    </Button>
                                </GridItem>{" "}
                            </>
                        )}

                        <GridItem xs={12}>
                            <GridContainer jusify="space-between">
                                {!values.data[0] && (
                                    <GridItem xs={12}>
                                        <Primary>
                                            <h5>
                                                <b>Brak Zapisów</b>
                                            </h5>
                                        </Primary>
                                    </GridItem>
                                )}
                                {values.data[0] && (
                                    <GridItem xs={12}>
                                        <Primary>
                                            <h5>
                                                <b>Zapisani</b>
                                            </h5>
                                        </Primary>
                                        <Table
                                            striped
                                            tableHead={[
                                                "Użytkownik",
                                                "Koordynator",
                                                "Data",
                                                "Transakcja",
                                                "Płatność",
                                                "Obecność",
                                                "Karta",
                                                "Komentarz",
                                                "",
                                            ]}
                                            tableData={values.data.map((row) => {
                                                return [
                                                    <>
                                                        {row.user.fa &&
                                                            <Danger style={{fontWeight: 900}}>FA!!<br/></Danger>}
                                                        <a href={`/users/${row.user.id}`}>
                                                            {row.user.identifier} <br/> {row.user.first_name}
                                                            {row.user.last_name}
                                                            <br/>
                                                            {row.user.phone}
                                                        </a>
                                                    </>,
                                                    activity.coordinators
                                                        .map((r) => r.id)
                                                        .includes(row.user.id)
                                                        ? "Tak"
                                                        : row.user.roles
                                                            .map((r) => r.name)
                                                            .includes("user_role_coordinator")
                                                            ? "Może"
                                                            : "Nie",
                                                    row.created_at,
                                                    row.transaction.identifier,
                                                    <>
                                                        {I18n.t(row.payment_method)}
                                                        <br/>
                                                        {row.payment_method === "benefit" && <Primary>
                                                            <b> {row.user.benefit_id}</b>
                                                        </Primary>}
                                                        {row.payment_method === "benefit" &&
                                                            ((activity.past && row.benefit_active) ||
                                                            (!activity.past && row.user.benefit_active) ? (
                                                                <Primary>
                                                                    <b> Karta Aktywna</b>
                                                                </Primary>
                                                            ) : (
                                                                <Danger>
                                                                    <b>Karta nieaktywna</b>
                                                                </Danger>
                                                            ))}
                                                        {activity.past && row.payment_method === "benefit" ? (row.benefit_entry ? (
                                                            <Primary>
                                                                <b>Odbicie znalezione</b>
                                                            </Primary>
                                                        ) : (
                                                            <Danger>
                                                                <b>Odbicie nieznalezione</b>
                                                                <br/>
                                                                <FormController activity_id={row.activity_id}
                                                                                user_id={row.user.id}
                                                                                date_played={`${activity.date} ${activity.start_at}`}
                                                                                organizer_login={activity.activity_type == 'organizer' ? activity.creator.benefit_login : ""}
                                                                                afterSave={this.fetch}/>
                                                            </Danger>
                                                        )) : null}
                                                        {row.payment_method === "fitprofit" &&
                                                            ((activity.past && row.fitprofit_active) ||
                                                            (!activity.past && row.user.fitprofit_active) ? (
                                                                <Primary>
                                                                    <b> Karta Aktywna</b>
                                                                </Primary>
                                                            ) : (
                                                                <Danger>
                                                                    <b>Karta nieaktywna</b>
                                                                </Danger>
                                                            ))}
                                                        {row.payment_method === "ok_system" &&
                                                            ((activity.past && row.ok_system_active) ||
                                                            (!activity.past && row.user.ok_system_active) ? (
                                                                <Primary>
                                                                    <b> Medicover aktywny</b>
                                                                </Primary>
                                                            ) : (
                                                                <Danger>
                                                                    <b>Medicover nieaktywny</b>
                                                                </Danger>
                                                            ))}
                                                    </>,
                                                    <FormControl
                                                        fullWidth
                                                        className={classes.selectFormControl}
                                                    >
                                                        <InputLabel
                                                            htmlFor="simple-select"
                                                            className={classes.selectLabel}
                                                        >
                                                            Obecność
                                                        </InputLabel>
                                                        <Select
                                                            disabled={disabled}
                                                            MenuProps={{
                                                                className: classes.selectMenu,
                                                            }}
                                                            classes={{
                                                                select: classes.select,
                                                            }}
                                                            value={row.presence_check}
                                                            onChange={(e) => {
                                                                this.update(row, {
                                                                    presence_check: e.target.value,
                                                                });
                                                            }}
                                                            inputProps={{
                                                                name: "presence",
                                                            }}
                                                        >
                                                            <MenuItem
                                                                key={`par-${row.id}`}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected,
                                                                }}
                                                                value={true}
                                                            >
                                                                Tak
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={`par-no-${row.id}`}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected,
                                                                }}
                                                                value={false}
                                                            >
                                                                Nie
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>,
                                                    row.payment_method === "benefit" || row.payment_method === "fitprofit" ? (
                                                        <FormControl
                                                            fullWidth
                                                            className={classes.selectFormControl}
                                                        >
                                                            <InputLabel
                                                                htmlFor="simple-select"
                                                                className={classes.selectLabel}
                                                            >
                                                                Karta
                                                            </InputLabel>
                                                            <Select
                                                                disabled={disabled}
                                                                MenuProps={{
                                                                    className: classes.selectMenu,
                                                                }}
                                                                classes={{
                                                                    select: classes.select,
                                                                }}
                                                                value={row.card_check}
                                                                onChange={(e) => {
                                                                    this.update(row, {
                                                                        card_check: e.target.value,
                                                                    });
                                                                }}
                                                                inputProps={{
                                                                    name: "card_check",
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    key={`par-${row.id}`}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected,
                                                                    }}
                                                                    value={true}
                                                                >
                                                                    Tak
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={`par-no-${row.id}`}
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected,
                                                                    }}
                                                                    value={false}
                                                                >
                                                                    Nie
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    ) : (
                                                        "n/d"
                                                    ),
                                                    <CustomInput
                                                        labelText="Komentarz"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        inputProps={{
                                                            multiline: true,
                                                            value: row.comments,
                                                            name: "comments",
                                                            onChange: (e) => {
                                                                this.update(row, {
                                                                    comments: e.target.value,
                                                                });
                                                            },
                                                        }}
                                                    />,
                                                    <Button
                                                        justIcon
                                                        color="danger"
                                                        disabled={!disabled}
                                                        onClick={() => {
                                                            this.setState({toDelete: row}, () =>
                                                                this.deleteModal(true)
                                                            );
                                                        }}
                                                    >
                                                        <CloseIcon/>
                                                    </Button>,
                                                ];
                                            })}
                                        />
                                    </GridItem>
                                )}
                            </GridContainer>
                        </GridItem>

                        <GridItem xs={12} style={{display: "flex", textAlign: 'right'}}>
                            Zatwierdzenie listy:{" "}
                            {activity.list_verified_at || activity.list_verification_date}
                            <br/>
                            Sprawdzono listę: {activity.list_checked ? "Tak" : "Nie"}
                            <Button
                                color="primary"
                                simple={true}
                                className={classes.mlAuto}
                                disabled={this.state.disableListSender}
                                onClick={() => {if(confirm("Czy wysłać listę do koordynatora?")){this.sendList(activity)} } }
                            >
                                Wyślij listę
                            </Button>
                            {!activity.list_verified && (
                                <Button
                                    color="primary"
                                    disabled={disabled}
                                    className={classes.mlAuto}
                                    onClick={handleSubmit}
                                >
                                    Zatwierdź listę
                                </Button>
                            )}

                        </GridItem>

                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(styles)(Form);
