import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { UsersFunctions } from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import CloseIcon from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";

import avatar from "PNH/assets/img/placeholder.jpg";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.id) {
      Api.competition_groups.teams.admin.players(this.props.competition_group_id, this.props.team_id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;

    const genders = {females: "female", males: 'male'}

    const filters = ['females', 'males'].includes(values.gender) ? { with_competition_group_id: values.competition_group_id, with_gender: genders[values.gender]} : {with_competition_group_id: values.competition_group_id}

    return (
      <GridContainer>

        <GridItem xs={12}>
          <GridContainer jusify="space-between">
            <GridItem xs>
              <AsyncSelector
                apiMethod={(q) =>
                  Api.users.admin.index({
                   ...filters,
                    ...q,
                  })
                }
                labelKey="label"
                label="Użytkownik"
                name="user"
                value={
                  values.user && {
                    id: values.user.id,
                    label: values.user.label,
                  }
                }
                onChange={(value, data) => {
                  setFieldValue("user", data);
                  setFieldValue("user_id", value);
                }}
              />
            </GridItem>
            <GridItem xs>
              <Button
                color="primary"
                disabled={!values.user_id}
                onClick={async (e) => {
                  await setFieldValue("action", "add");
                  handleSubmit(e);
                }}
              >
                Dodaj
              </Button>
            </GridItem>

            <GridItem xs={12}>
              <GridContainer jusify="space-between">
                {!values.rows[0] && (
                  <GridItem xs={12}>
                    <Primary>
                      <h5>
                        <b>Brak Osób</b>
                      </h5>
                    </Primary>
                  </GridItem>
                )}
                {values.rows[0] && (
                  <GridItem xs={12}>
                    <Primary>
                      <h5>
                        <b>Lista osób</b>
                      </h5>
                    </Primary>
                  </GridItem>
                )}
                {values.rows.map((u) => {
                  return (
                    <GridItem
                      key={`uar-${u.id}`}
                      xs
                      sm={6}
                      md={4}
                      style={{ fontSize: 10, fontWeight: 600 }}
                    >
                      <GridContainer>
                        <GridItem xs style={{ maxWidth: 50 }}>
                          <img src={avatar} style={{ maxWidth: 30 }} />
                        </GridItem>
                        <GridItem xs style={{ padding: 0, lineHeight: "15px" }}>
                          <a href={`/users/${u.id}`}>
                            {u.user.label.split(",")[0]}
                            <br />
                            {u.user.label.split(",")[1]}
                          </a>
                          <br />
                          {u.phone}
                        </GridItem>
                        <GridItem
                          xs
                          style={{ maxWidth: 50, cursor: "pointer" }}
                        >
                          <Button
                            justIcon
                            simple
                            color="danger"
                            size="sm"
                            onClick={async (e) => {
                              await setFieldValue("action", "remove");
                              await setFieldValue("team_user_id", u.id);
                              handleSubmit(e);
                            }}
                          >
                            <CloseIcon />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Form);
