import React from "react";
import Table from "./Table";

class Locations extends React.Component {
  render() {
    const url = this.props.location.pathname.split("/");

    return (
        <Table {...this.props} />
    );
  }
}

Locations.propTypes = {};

export default Locations;
