import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

class AuditsTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    with_auditable_id: "",
    with_auditable_type: "",
    with_user_id: "",
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.audits.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_auditable_id: this.state.with_auditable_id,
        with_auditable_type: this.state.with_auditable_type,
        with_user_id: this.state.with_user_id,
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      row.created_at,
      row.auditable_type,
      row.auditable_id,
      row.user ? row.user.first_name + " " + row.user.last_name : "-",
      row.action,
      row.comment,
      row.audited_changes.map((r) => (
        <div>
          <b>{r[0]}:</b>
          <br />
          {r[1]}
        </div>
      )),
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };
  render() {
    const { classes } = this.props;
    return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <h4 className={classes.cardIconTitle}>Filtry</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={2}>
                    <CustomInput
                      id={`with_auditable_id`}
                      labelText="ID"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_auditable_id`,
                        value: this.state[`with_auditable_id`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={5}>
                    <AsyncSelector
                      apiMethod={Api.users.admin.index}
                      labelKey="label"
                      label="Użytkownik"
                      name="user"
                      onChange={(value, data) => {
                        this.setState({ with_user_id: value }, this.getData);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Zasób
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.with_auditable_type}
                        onChange={(e) =>
                          this.setState(
                            { with_auditable_type: e.target.value },
                            this.getData
                          )
                        }
                        inputProps={{
                          isClearable: true,
                          name: "organizer",
                        }}
                      >
                        {[
                          "User",
                          "Activity",
                          "Transaction",
                          "Localization",
                          "LocationObject",
                          "Sport",
                        ].map((g) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={g}
                          >
                            {g}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <h4 className={classes.cardIconTitle}>Historia zmian</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHead={[
                    "Data",
                    "Zasób",
                    "ID",
                    "Użytkownik",
                    "Akcja",
                    "Komentarz",
                    "Zmiany",
                  ]}
                  tableData={this.state.data}
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  page={this.state.page}
                  rowsPerPage={this.state.limit}
                  count={this.state.total}
                  onChangePage={this.changePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Ilość na stronie"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} z ${count}`
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}

AuditsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(extendedTablesStyle)(AuditsTable);
