import moment from "moment";

export const RowFunction = (row, formik) => {
    const {classes, values, setFieldValue} = formik;
    const params = row.params
    return [
        row.id,
        row.report_name,
        params.date_from?.substring(0,10)+"-"+params.date_to?.substring(0,10),
        <div style={{maxWidth: '200px'}}>{row.job_status}</div>,
        row.file && <a href={process.env.REACT_APP_API_URL+row.file} > Plik </a> ,
        row.user.label,
        row.report_class,
        row.created_at,
    ];
} 