import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { I18n } from "PNH/components/I18n";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { Api } from "PNH/services/Api";

export const OrganizerSection = ({ handleChange, classes, values,  setFieldValue }) => {
    return <>
        <GridItem xs={12}>
            <h5>
                <b>Organizator</b>
            </h5>
            <hr />
        </GridItem>
        <GridItem xs={12} md={6}>
            <AsyncSelector
                apiMethod={Api.users.admin.index}
                apiShowMethod={Api.users.admin.show}
                labelKey="label"
                label="Dodający (jeśli dodajesz w imieniu organizatora wybierz go z listy)"
                name="creator_id"
                defaultValue={values.creator_id}
                value={
                    values.creator && {
                        id: values.creator.id,
                        label: values.creator.label,
                    }
                }
                onChange={(value, data) => {
                    setFieldValue("creator", data);
                    setFieldValue("creator_id", value);
                }}
            />
        </GridItem>

        <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                >
                    Organizator
                </InputLabel>
                <Select
                    MenuProps={{
                        className: classes.selectMenu,
                    }}
                    classes={{
                        select: classes.select,
                    }}
                    value={values.activity_type}
                    onChange={({ ...arg }) => {
                        handleChange(arg);
                        setFieldValue("event_type", null);
                    }}
                    inputProps={{
                        name: "activity_type",
                    }}
                >
                    {[["PNH", "pnh"],
                        ["Organizator", "organizer"],
                    ].map((g) => (
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value={g[1]}
                        >
                            {g[0]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </GridItem>

        {values.activity_type === 'organizer' && (
            <GridItem xs={12} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                    >
                        Typ wydarzenia
                    </InputLabel>
                    <Select
                        MenuProps={{
                            className: classes.selectMenu,
                        }}
                        classes={{
                            select: classes.select,
                        }}
                        value={values.event_type}
                        onChange={handleChange}
                        inputProps={{
                            name: "event_type",
                        }}
                    >
                        {[["Organizator 1.0", "first_type"],
                            ["Organizator 2.0", "second_type"],
                            ["Organizator 3.0", "third_type"]].map((g) => (
                            <MenuItem
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                }}
                                value={g[1]}
                            >
                                {g[0]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridItem>
        )}
    </>
}