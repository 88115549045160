import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Info from "components/Typography/Info";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Button from "components/CustomButtons/Button";

import Danger from "components/Typography/Danger";

import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import ArtTrack from "@material-ui/icons/ArtTrack";
import { I18n } from "PNH/components/I18n";
class Profile extends React.Component {
  render() {
    const { classes, activity, onView, onEdit, onCopy } = this.props;

    return (
      <Card
        id={`ac-${activity.id}`}
        key={`ac-${activity.id}`}
        product
        className={classes.cardHover}
        style={{
          marginTop: 5,
          marginBottom: 0,
        }}
      >
        <CardHeader
          image
          style={{
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
          }}
        >
          <img src={process.env.REACT_APP_API_URL+activity.thumb_image} alt="..." />
        </CardHeader>

        <CardBody>
          <div>
            <h4 className={classes.cardProductTitle}>
              <Info>
                <b>{activity.sport.name}</b>
                <br />
                <b>
                  {activity.date} {activity.start_at}-{activity.stop_at}
                </b>
              </Info>
              {activity.canceled && (
                <Danger>
                  {" "}
                  <b>Odwołane</b>
                </Danger>
              )}
            </h4>
          </div>
          <div style={{ textAlign: "center" }}>
            <Tooltip
              id="tooltip-top"
              title="View"
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button color="info" simple justIcon onClick={onView}>
                <ArtTrack className={classes.underChartIcons} />
              </Button>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Edit"
              placement="bottom"
              onClick={onEdit}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button color="success" simple justIcon>
                <Edit className={classes.underChartIcons} />
              </Button>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Copy"
              placement="bottom"
              onClick={onCopy}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button color="transparent" simple justIcon>
                <FileCopyIcon className={classes.underChartIcons} />
              </Button>
            </Tooltip>
          </div>

          <p className={classes.cardProductDesciprion}>
            <GridContainer justify="space-between" style={{ fontSize: 10 }}>
              <GridItem
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  fontSize: 10,
                }}
              >
                <b>ID:</b> {activity.id}
              </GridItem>{" "}
              <GridItem
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  fontSize: 10,
                }}
              >
                <b>Typ:</b> {I18n.t(activity.activity_type)}
              </GridItem>
              <GridItem
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  fontSize: 10,
                }}
              >
                <b>Zapisanych:</b> {activity.all_reserved_spots}/
                {activity.max_players}
              </GridItem>
              <GridItem
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                }}
              >
                <b>Minimalna:</b> {activity.min_players}
              </GridItem>
              <GridItem xs={12}>
                <hr />
              </GridItem>
              {activity.all_reserved_spots < activity.min_players && (
                <GridItem>
                  <Danger>
                    <b>Ilości graczy</b>
                  </Danger>
                </GridItem>
              )}
              {activity.coordinators.length === 0 && (
                <GridItem>
                  <Danger style={{ fontSize: 10 }}>
                    <b>Brak koordynatora</b>
                  </Danger>
                </GridItem>
              )}
              <GridItem>
                <b>
                  Dodał: {activity.creator.first_name}{" "}
                  {activity.creator.last_name}
                </b>
              </GridItem>
              <GridItem>
              {activity.fa_count > 0 && (
                  <Danger style={{fontWeight: 900}}>
                    fa: {activity.fa_count}
                  </Danger>
                )}
              </GridItem>
            </GridContainer>
          </p>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(dashboardStyle)(Profile);
