import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    with_activities_id: Yup.number(),
    with_date_greater: Yup.string()
    .length(10)
    .matches(/(\d){4}-(\d){2}-(\d){2}/, 'date must have this pattern "yyyy-mm-dd'),
    with_date_less: Yup.string()
    .length(10)
    .matches(/(\d){4}-(\d){2}-(\d){2}/, 'date must have this pattern "yyyy-mm-dd'),
    with_start_at_greater: Yup.string()
    .length(5)
    .matches(/(\d){2}:(\d){2}/, 'Hour must have this pattern "00:00"'),
    with_start_at_less: Yup.string()
    .length(5)
    .matches(/(\d){2}:(\d){2}/, 'Hour must have this pattern "00:00"'),
});

export default ValidationSchema;
