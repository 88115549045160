import React from "react";
import { Api } from "PNH/services/Api";

import GridItem from "components/Grid/GridItem";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";

import Badge from "components/Badge/Badge";
import Close from "@material-ui/icons/Close";

import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};

class SportsSelector extends React.Component {
  render() {
    const { classes, values, setFieldValue } = this.props;

    return (
      <>

        <GridItem xs={6}>
          <AsyncSelector
            apiMethod={(q) =>
              Api.sports.admin.index(q)
            }
            labelKey="name"
            label="Dyscyplina"
            name="sports"
            onChange={(value, data) => {
              setFieldValue("sport_tmp", data);
              setFieldValue("sport_tmp_id", value);
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <Button
            size="sm"
            color="primary"
            disabled={!values.sport_tmp_id}
            onClick={async (e) => {
              if (values.sports === null) {
                const sports = []
                sports.push(values.sport_tmp)
                setFieldValue("sports", sports);
              } else {
                const sports = values.sports
                sports.push(values.sport_tmp)
                setFieldValue("sports", sports);
              }

            }}
          >
            Dodaj
          </Button>
        </GridItem>
        <GridItem xs={12}>
          {values.sports && values.sports.map((sport) => {
            return <Badge color="info" key={sport.name} style={{ marginLeft: 10 }}>
              {sport.name}
              <Button simple size="sm" onClick={() => {
                let sports = values.sports.filter((v) => (v.id != sport.id))
                setFieldValue("sports", sports);
              }} link color='danger'><Close /></Button>
            </Badge>
          })}
        </GridItem>
      </>
    );
  }
}

export default withStyles(styles)(SportsSelector);
