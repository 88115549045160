import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import CustomInput from "components/CustomInput/CustomInput";

import Button from "components/CustomButtons/Button";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";

import Slide from "@material-ui/core/Slide";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import PageviewIcon from "@material-ui/icons/Pageview";
import { I18n } from "PNH/components/I18n";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class TransactionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      limit: 10,
      total: 0,
      with_id: "",
      with_created_at_less: "",
      with_created_at_greater: "",
      with_identifier: "",
      opendModal: false,
    };
    this.getData();
  }
  getData = async () => {
    Api.transactions.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_id: this.state.with_id,
        with_identifier: this.state.with_identifier,
        with_user_id: this.props.userID,
        with_created_at_less: this.state.with_created_at_less,
        with_created_at_greater: this.state.with_created_at_greater,
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;

    return [
      <Button
        simple
        color="primary"
        onClick={() => this.setState({ activeTransaction: row })}
      >
        <b>{row.identifier}</b>
      </Button>,
      <span style={{ whiteSpace: "nowrap" }}>{row.created_at}</span>,

      row.transaction_type === "buy_activity" ||
      row.transaction_type === "resign_activity" ? (
        <>
          {I18n.t(row.transaction_type)}
          <br />
          {row.items.map((row) => {
            if (!row.targetable_id) {
              return null;
            }
            return (
              <a href={`/activities/${row.targetable.id}`}>
                #{row.targetable.id},{" "}
              </a>
            );
          })}
        </>
      ) : (
        I18n.t(row.transaction_type)
      ),
      I18n.t(row.payment_method),
      row.status === "paid" || row.status === "canceled" ? (
        <Primary>
          <b>{I18n.t(row.status)}</b>
        </Primary>
      ) : (
        <Danger>
          <b>Nieopłacone</b>
        </Danger>
      ),
      <Button
        simple
        color="github"
        onClick={() => this.setState({ activeTransaction: row })}
      >
        <PageviewIcon />
      </Button>,
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };

  renderTransaction = () => {
    const { classes } = this.props;
    const { activeTransaction } = this.state;
    return (
      <>
        <h6>
          {" "}
          <a href="#" onClick={() => this.setState({ activeTransaction: "" })}>
            {"< Powrót"}{" "}
          </a>
        </h6>
        <h4>
          Transakcja <b>#{activeTransaction.identifier}</b>
          <h6>{activeTransaction.created_at}</h6>
        </h4>

        <GridContainer style={{ fontSize: 12 }}>
          <GridItem xs={12} md={6}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>Informacje</h6>
                <GridContainer justify="space-beetwen">
                  <GridItem xs={6}>Płatność:</GridItem>
                  <GridItem xs={6}>
                    {I18n.t(activeTransaction.payment_method)}
                  </GridItem>
                  <GridItem xs={6}>Status:</GridItem>
                  <GridItem xs={6}>
                    {I18n.t(activeTransaction.status)}
                    {activeTransaction.payment_method === "online_transfer" &&
                      activeTransaction.status === "unpaid" && (
                        <a
                          href={`/dotpay/transactions/${activeTransaction.id}`}
                        >
                          {" "}
                          - Zapłać
                        </a>
                      )}
                  </GridItem>

                  <GridItem xs={6}>Typ: </GridItem>
                  <GridItem xs={6}>
                    {I18n.t(activeTransaction.transaction_type)}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>
                  Wykupione zajęcia i usługi
                </h6>
                <Table
                  striped
                  tableHead={[
                    <b style={{ fontSize: 12 }}>Użytkownik</b>,
                    <b style={{ fontSize: 12 }}>Typ</b>,
                    <b style={{ fontSize: 12 }}>Wartość</b>,
                    <b style={{ fontSize: 12 }}>Dopłata</b>,
                    <b style={{ fontSize: 12 }}>Płatność</b>,
                    <b style={{ fontSize: 12 }}>Kod</b>,
                  ]}
                  tableData={
                    activeTransaction !== false
                      ? activeTransaction.items.map((row) => {
                          return [
                            `${row.user.first_name} ${row.user.last_name}`,
                            row.item_type === "buy_activity" ||
                            row.item_type === "resign_activity" ? (
                              <>
                                {I18n.t(row.item_type)}
                                <br />
                                <a
                                  href={`/activities/${row.targetable.id}`}
                                >
                                  #{row.targetable.id} -
                                  {row.targetable.label}
                                </a>
                              </>
                            ) : (
                              I18n.t(row.item_type)
                            ),
                            ["benefit", "ok system"].includes(
                              row.payment_method
                            )
                              ? "0 PLN"
                              : row.value + "PLN",
                            ,
                            row.supplement + "PLN",
                            row.item_type === "buy_activity"
                              ? I18n.t(row.payment_method)
                              : "-",
                              row.transaction_item_promo_code ? row.transaction_item_promo_code.code : null
                          ];
                        })
                      : []
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>Stan Konta Przed:</h6>
                <b>Główne:</b> {activeTransaction.account_balance_before}zł,{" "}
                <b>Bonus:</b> {activeTransaction.account_bonus_before}zł,{" "}
                <b>Kaucja:</b> {activeTransaction.account_deposit_before}zł
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>Stan Konta Po:</h6>
                <b>Główne:</b> {activeTransaction.account_balance_after}zł,{" "}
                <b>Bonus:</b> {activeTransaction.account_bonus_after}zł,{" "}
                <b>Kaucja:</b> {activeTransaction.account_deposit_after}zł
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>
                  Komentarze do transakcji:
                  <hr />
                </h6>
                {activeTransaction.comments.map((row) => {
                  return (
                    <div key={row.id}>
                      <p>
                        <b>{` ${row.user.first_name} ${row.user.last_name}`}</b>{" "}
                        <Primary>
                          <b style={{ fontSize: 10 }}>{row.created_at} </b>
                        </Primary>
                      </p>

                      {row.comment}
                      <hr />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  renderTable = () => (
    <>
      <Table
        tableHead={[
          <b>Nr Transakcji</b>,
          <b>Data</b>,
          <b>Typ</b>,
          <b>Płatność</b>,
          <b>Status</b>,
          "",
        ]}
        striped
        tableData={this.state.data}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        page={this.state.page}
        rowsPerPage={this.state.limit}
        count={this.state.total}
        onChangePage={this.changePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        labelRowsPerPage="Ilość na stronie"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
      />
    </>
  );

  render() {
    const { classes } = this.props;
    const { activeTransaction } = this.state;
    return (
      <>
        <Card>
          <CardHeader color="secondary">
            <h4 className={classes.cardIconTitle}>Filtry</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={1}>
                <CustomInput
                  id={`with_id`}
                  labelText="ID"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Szukaj...",
                    type: "text",
                    name: `with_id`,
                    value: this.state[`with_id`],
                    onChange: this.handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id={`with_identifier`}
                  labelText="Identyfikator"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Szukaj...",
                    type: "text",
                    name: `with_identifier`,
                    value: this.state[`with_identifier`],
                    onChange: this.handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id={`with_created_at_greater`}
                  labelText="Data od"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Szukaj ROK-M-D...",
                    type: "text",
                    name: `with_created_at_greater`,
                    value: this.state[`with_created_at_greater`],
                    onChange: this.handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <CustomInput
                  id={`with_created_at_less`}
                  labelText="Data do"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Szukaj ROK-M-D...",
                    type: "text",
                    name: `with_created_at_less`,
                    value: this.state[`with_created_at_less`],
                    onChange: this.handleChange,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        {!activeTransaction && this.renderTable()}
        {activeTransaction && this.renderTransaction()}
      </>
    );
  }
}

TransactionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles({ ...modalStyles, ...extendedTablesStyle })(
  TransactionsTable
);
