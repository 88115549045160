import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { ActivitiesFunctions } from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { SettingsSection } from "./FormSections/Settings";
import { VisibilitySection } from "./FormSections/Visibility";
import { DescriptionSection } from "./FormSections/Description";
import { PaymentsSection } from "./FormSections/Payments";
import { ImagesSection } from "./FormSections/Images";
import { FreespotsSection } from "./FormSections/FreeSpots";
import { LimitationsSection } from "./FormSections/Limitations";
import { CostsSection } from "./FormSections/Costs";
import { DateAndTimeSection } from "./FormSections/DateAndTime";
import { LocationSection } from "./FormSections/Location";
import {OrganizerSection} from "./FormSections/Organizer";
import {CompetitionType} from "./FormSections/CompetitionType";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
    this.state = {
      deleteModal: false,
      fetchActivity: !!props.activityID,
      fetchSports: true,
      fetchLocations: true,
    };
    this.fetchLocalizations();
    Api.sports.admin.index().then((response) => {
      if (response.ok) {
        this.props.setFieldValue("sports", response.data.rows);
        this.setState({ fetchSports: false });
      }
    });
  }

  fetchLocalizations = () => {
    Api.locations.admin.index().then((response) => {
      if (response.ok) {
        this.props.setFieldValue("localizations", response.data.rows);
        this.setState({ fetchLocations: false });
      }
    });
  };

  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.activityID) {
      Api.activities.admin.show(this.props.activityID).then((response) => {
        if (response.ok) {
          
          Object.keys(response.data).map((k) => {
            if(this.props.copy && (k == "creator_id" || k == "date" || k == "creator" || k == "publish_date" || k == "id" )) {
              // do nothing
            } else {
              this.props.setFieldValue(k, response.data[k]);
            }
          });
          this.setState({ fetchActivity: false });
        } else {
          this.props.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  form() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer justify="flex-end">
          <GridItem xs={4}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall,
              }}
              open={this.state.deleteModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.deleteModal(false)}
              aria-labelledby="small-modal-slide-title"
              aria-describedby="small-modal-slide-description"
            >
              <DialogTitle
                id="small-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.deleteModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
              </DialogTitle>
              <DialogContent
                id="small-modal-slide-description"
                className={classes.modalBody + " " + classes.modalSmallBody}
              >
                <h5>Na pewno chcesz usunąć?</h5>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button
                  onClick={() => this.deleteModal(false)}
                  color="transparent"
                  className={classes.modalSmallFooterFirstButton}
                >
                  Nie
                </Button>
                <Button
                  onClick={() => ActivitiesFunctions.delete(this.props)}
                  color="success"
                  simple
                  className={
                    classes.modalSmallFooterFirstButton +
                    " " +
                    classes.modalSmallFooterSecondButton
                  }
                >
                  Tak
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <h4>
              <b>Zajęcia: {values.id ? "Edycja" : "Dodaj nowe"}</b>
            </h4>
          </GridItem>
          <CompetitionType values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} {...this.props}/>
          <OrganizerSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} {...this.props} />
          <GridItem xs={12}>
            <h5>
              <b>Ogólne</b>
            </h5>
            <hr />
          </GridItem>

          <GridItem xs={12} md={6}>
            <AsyncSelector
              apiMethod={Api.sports.admin.index}
              apiShowMethod={Api.sports.admin.show}
              labelKey="name"
              label="Dyscyplina"
              name="sports"
              defaultValue={values.sport_id}
              value={
                values.sport && {
                  id: values.sport.id,
                  label: values.sport.name,
                }
              }
              onChange={(value, data) => {
                setFieldValue("sport", data);
                setFieldValue("sport_id", value);
              }}
            />
          </GridItem>

          <LocationSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} {...this.props} />
          <DateAndTimeSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} {...this.props}/>
          <CostsSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          <LimitationsSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          <FreespotsSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          <PaymentsSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          <DescriptionSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          <ImagesSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          <SettingsSection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          <VisibilitySection values={values} classes={classes} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />

        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm>
            <Button
              color="danger"
              className={classes.marginRight}
              onClick={() => this.deleteModal(true)}
            >
              Usuń
            </Button>
          </GridItem>
          <GridItem xs={12} sm style={{ textAlign: "right" }}>
            <Button
              type="submit"
              color="success"
              disabled={this.props.isSubmitting}
            >
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  render() {
    const { fetchActivity, fetchLocalizations, fetchLocations } = this.state;
    if (!fetchActivity && !fetchLocalizations && !fetchLocations) {
      return this.form();
    } else {
      return <h4>Loading...</h4>;
    }
  }
}

export default withStyles(styles)(Form);
