import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {I18n} from "PNH/components/I18n";
import Accordion from "components/Accordion/Accordion";

export const TableFilters = (formik) => {
    const {classes, SearchFunctions} = formik;
    return (<GridContainer>
            <form onSubmit={formik.handleSubmit} style={{width: '100%'}}>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <GridContainer style={{justifyContent: 'flex-end'}}>
                                <GridItem xs={3}>
                                    {withFacilityName(formik)}
                                </GridItem>
                                <GridItem xs={3}>
                                    {withFOrganizerLogin(formik)}
                                </GridItem>
                                <GridItem xs={3}>
                                    {withConfirmed(formik)}
                                </GridItem>
                                <GridItem xs={3}>
                                    {withTags(formik)}
                                </GridItem>

                                <GridItem xs={12}>
                                    <Accordion
                                        collapses={[{
                                            title: 'Zaawansowane filtry',
                                            content: <GridContainer>
                                                <GridItem xs={3}>
                                                    {withDateGreater(formik)}
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    {withDateLess(formik)}
                                                </GridItem>

                                                <GridItem xs={3}>
                                                    {withDatePlayedGreater(formik)}
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    {withDatePlayedLess(formik)}
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    {withID(formik)}
                                                </GridItem>
                                            </GridContainer>
                                        }]}/>
                                </GridItem>


                                <GridItem xs={3}>
                                    {order(formik)}
                                </GridItem>

                                <GridItem xs={3} style={{textAlign: "right"}}>
                                    {SearchFunctions.showClearButton && <Button
                                        link
                                        color="success"
                                        onClick={SearchFunctions.clearFilters}
                                    >
                                        Wyczyść
                                    </Button>}
                                    <Button
                                        color="success"
                                        onClick={formik.handleSubmit}
                                    >
                                        Filtruj
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <input type="submit" style={{display: 'none'}}/>
            </form>
        </GridContainer>
    )
}


const withID = (formik) => (
    <CustomInput
        id={`with_uuid`}
        error={formik.errors.with_uuid}
        labelText="uuid"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj...",
            type: "text",
            name: `with_uuid`,
            value: formik.values.with_uuid,
            onChange: (e) => {
                formik.setFieldValue(`with_uuid`, e.target.value)
            },
        }}
    />
)

const withDateGreater = (formik) => (
    <CustomInput
        id={`with_created_at_greater`}
        error={formik.errors.with_created_at_greater}
        labelText="Data importu"
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_created_at_greater`,
            value: formik.values.with_created_at_greater,
            onChange: (e) => {
                formik.setFieldValue(`with_created_at_greater`, e.target.value)
            },
        }}
    />
)

const withDateLess = (formik) => (
    <CustomInput
        id={`with_created_at_less`}
        error={formik.errors.with_created_at_less}
        labelText="Data importu"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_created_at_less`,
            value: formik.values.with_created_at_less,
            onChange: (e) => {
                formik.setFieldValue(`with_date_less`, e.target.value)
            },
        }}
    />
)

const withDatePlayedGreater = (formik) => (
    <CustomInput
        id={`with_date_played_greater`}
        error={formik.errors.with_date_played_greater}
        labelText="Data odbicia"
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_date_played_greater`,
            value: formik.values.with_date_played_greater,
            onChange: (e) => {
                formik.setFieldValue(`with_date_played_greater`, e.target.value)
            },
        }}
    />
)

const withDatePlayedLess = (formik) => (
    <CustomInput
        id={`with_date_played_less`}
        error={formik.errors.with_date_played_less}
        labelText="Data odbicia"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_date_played_less`,
            value: formik.values.with_date_played_less,
            onChange: (e) => {
                formik.setFieldValue(`with_date_played_less`, e.target.value)
            },
        }}
    />
)

const withTags = (formik) => (
    <CustomInput
        id={`with_tags`}
        labelText="Tagi..."
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_tags`,
            value: formik.values.with_tags,
            onChange: (e) => {
                formik.setFieldValue(`with_tags`, e.target.value)
            },
        }}
    />
)

const withFacilityName = (formik) => (
    <CustomInput
        id={`with_facility_name`}
        labelText="Lokalizacja..."
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Lokalizacja",
            type: "text",
            name: `with_facility_name`,
            value: formik.values.with_facility_name,
            onChange: (e) => {
                formik.setFieldValue(`with_facility_name`, e.target.value)
            },
        }}
    />
)

const withFOrganizerLogin = (formik) => (
    <CustomInput
        id={`with_organizer_login`}
        labelText="Login..."
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Login",
            type: "text",
            name: `with_organizer_login`,
            value: formik.values.with_organizer_login,
            onChange: (e) => {
                formik.setFieldValue(`with_organizer_login`, e.target.value)
            },
        }}
    />
)

const withConfirmed = (formik) => {
    const {classes} = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Zweryfikowano
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.with_confirmed}
            onChange={(e) => {
                formik.setFieldValue(`with_confirmed`, e.target.value)
            }
            }
            inputProps={{
                isClearable: true,
                name: "with_confirmed",
            }}
        >
            {["", "true", "false"].map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g) || "Wyczyść"}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

const order = (formik) => {
    const {classes, setFieldValue, SearchFunctions} = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Sortowanie
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.order}
            onChange={(e) =>
                setFieldValue(`order`, e.target.value)
            }
            inputProps={{
                isClearable: true,
                name: "order",
            }}
        >
            {SearchFunctions.orderBy().map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g)}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}

