import React from "react";
import GridItem from "components/Grid/GridItem";
import ImageUpload from "PNH/components/CustomUpload/ImageUpload";


export const ImagesSection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12} md={6}>
            <h6>Baner</h6>
            <ImageUpload
              value={process.env.REACT_APP_API_URL + values.baner_image}
              onChange={(file) => {
                setFieldValue("baner_image", file);
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <h6>Kafelek</h6>
            <ImageUpload
              value={process.env.REACT_APP_API_URL + values.thumb_image}
              onChange={(file) => {
                setFieldValue("thumb_image", file);
              }}
            />
          </GridItem>
    </>
}