import React from "react";
import {Api} from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ImageUpload from "PNH/components/CustomUpload/ImageUpload";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {SportsFunctions} from "./index";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteModal: false,
            fetch: !!this.props.values.id,
        };
        this.fetch();
    }

    deleteModal = (open) => {
        this.setState({deleteModal: open});
    };

    fetch = () => {
        if (this.props.values.id) {
            Api.mainPageTiles.admin.show(this.props.values.id).then((response) => {
                if (response.ok) {
                    this.props.setValues({
                        ...SportsFunctions.defaultValues(this.props),
                        ...response.data,
                    });
                    this.setState({fetch: false});
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    form() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
        } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <GridContainer justify="flex-end">
                    <GridItem xs={4}>
                        <Dialog
                            classes={{
                                root: classes.center + " " + classes.modalRoot,
                                paper: classes.modal + " " + classes.modalSmall,
                            }}
                            open={this.state.deleteModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => this.deleteModal(false)}
                            aria-labelledby="small-modal-slide-title"
                            aria-describedby="small-modal-slide-description"
                        >
                            <DialogTitle
                                id="small-modal-slide-title"
                                disableTypography
                                className={classes.modalHeader}
                            >
                                <Button
                                    justIcon
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    color="transparent"
                                    onClick={() => this.deleteModal(false)}
                                >
                                    <Close className={classes.modalClose}/>
                                </Button>
                            </DialogTitle>
                            <DialogContent
                                id="small-modal-slide-description"
                                className={classes.modalBody + " " + classes.modalSmallBody}
                            >
                                <h5>Na pewno chcesz usunąć?</h5>
                            </DialogContent>
                            <DialogActions
                                className={
                                    classes.modalFooter + " " + classes.modalFooterCenter
                                }
                            >
                                <Button
                                    onClick={() => this.deleteModal(false)}
                                    color="transparent"
                                    className={classes.modalSmallFooterFirstButton}
                                >
                                    Nie
                                </Button>
                                <Button
                                    onClick={() => SportsFunctions.delete(this.props)}
                                    color="success"
                                    simple
                                    className={
                                        classes.modalSmallFooterFirstButton +
                                        " " +
                                        classes.modalSmallFooterSecondButton
                                    }
                                >
                                    Tak
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <h4>
                            <b>Kafelek</b>
                        </h4>
                    </GridItem>
                    <GridItem xs={6}>
                        <CustomInput
                            labelText="Nazwa"
                            error={errors.name}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: values.name,
                                name: "name",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                error={!!errors.tile_type}
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                Typ
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values.tile_type}
                                onChange={handleChange}
                                inputProps={{
                                    name: "tile_type",
                                }}
                            >
                                {["simple", "wideLeft", "wideRight", "fullSmall", "fullBig"].map(
                                    (type) => (
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </GridItem>

                    <GridItem xs={6}>
                        <CustomInput
                            labelText="Kategoria"
                            error={errors.category}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: values.category,
                                name: "category",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>

                    <GridItem xs={6}>
                        <FormControlLabel
                            style={{marginTop: "20px"}}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    checked={values.hidden}
                                    onClick={() => setFieldValue("hidden", !values.hidden)}
                                    checkedIcon={<Check className={classes.checkedIcon}/>}
                                    icon={<Check className={classes.uncheckedIcon}/>}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                    }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot,
                            }}
                            label="Ukryj"
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Url"
                            error={errors.url}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                value: values.url,
                                name: "url",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <CustomInput
                            labelText="Kolejność"
                            error={errors.order_value}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                value: values.order_value,
                                name: "order_value",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <CustomInput
                            labelText="Tekst na guziku"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                value: values.button_text,
                                name: "button_text",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} md={6}>
                        <CustomInput
                            labelText="Opis"
                            error={errors.description}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                value: values.description,
                                name: "description",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>


                    <GridItem xs={12}>
                        <hr/>
                    </GridItem>
                    <GridItem xs={12}>
                        <h6>Skalowanie</h6>
                    </GridItem>
                    {['xs', 'sm', 'md', 'lg', 'xl'].map((size) => <GridItem xs={12} md={2}>
                        <CustomInput
                            labelText={size}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                multiline: true,
                                value: values[size],
                                name: size,
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>)}
                    <GridItem xs={6}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                error={!!errors.section}
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                Sekcja
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values.section}
                                onChange={handleChange}
                                inputProps={{
                                    name: "section",
                                }}
                            >
                                {["logged_in_1", "logged_in_2", "logged_in_3", "logged_in_4", "logged_in_5", "logged_in_6",
                                    "logged_in_7", "logged_in_8", "logged_in_9", "logged_in_10", "logged_in_11",
                                    "not_logged_in_1", "not_logged_in_2", "not_logged_in_3", "not_logged_in_4", "not_logged_in_5", "not_logged_in_6"
                                ].map(
                                    (section) => (
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value={section}
                                        >
                                            {section}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12}>

                        <hr/>
                    </GridItem>
                    <GridItem>
                        <h6>Grafika</h6>
                        <ImageUpload
                            value={`${process.env.REACT_APP_API_URL}${values.picture}`}
                            onChange={(file) => {
                                setFieldValue("picture", file);
                            }}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer justify="space-between">
                    <GridItem xs={12} sm>
                        <Button
                            color="danger"
                            className={classes.marginRight}
                            onClick={() => this.deleteModal(true)}
                        >
                            Usuń
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm style={{textAlign: "right"}}>
                        <Button type="submit" color="success">
                            Zapisz
                        </Button>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }

    render() {
        if (this.state.fetch) {
            return <>Loading...</>;
        } else {
            return this.form();
        }
    }
}

export default withStyles(styles)(Form);
