import React from "react";
import Table from "./Table";

class Audit extends React.Component {
  render() {
    return <Table {...this.props} />;
  }
}

Audit.propTypes = {};

export default Audit;
