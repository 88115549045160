/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle";
import LoginModal from "PNH/components/LoginModal";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";


class NotFound extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
      >
        <Typography variant="h1">
          404
        </Typography>
        <Typography variant="h6">
          Nie znaleziono.
        </Typography>
      </Box>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(pagesStyle)(NotFound);
