import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";
import {ConnectToChannel, Send, WsManager} from "PNH/services/Ws";
import {useEffect} from "react";
import {ActivitiesFunctions} from "../../Activities/ActivitiesFrom";

const CHANNEL_NAME = "Admin::PromoCodesChannel"

export const PromoCodesFunctions = {

  receivedMsg: (data) => {
    let type = 'alert';
    switch(data.code) {
      case 200:
         type = 'success';
        break;
      case 202:
         type = 'warning';
        break;
      default:
         type = 'alert';
    }

    window.setNotifications([
      { type: type, msg: data.status },
    ]);
  },

  connectedToChannel: (data)=>{console.log(data)},

  disconnectedToChannel:  (data) => {console.log(data)},


  create: (values, formik) => {
    values.afterSave();
    formik.setFieldValue("afterSave", () => {});
    formik.setTouched({});
    PromoCodesFunctions.ws.send({params: values} )
  },
  update: (values, formik) => {
    values.afterSave();
    formik.setFieldValue("afterSave", () => {});
    formik.setTouched({});
    PromoCodesFunctions.ws.send({params: values} )
  },
  delete: (formik) => {
    return Api.promo_codes.admin.delete(formik.values.id).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Usunięto" },
        ]);
      
       let url = `/promo-codes`;
        formik.history.push(url)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      action:
      currentUrlParams().urlPath[1] &&  currentUrlParams().urlPath[1] !== "new"
        ?  'update'
        : "create",
      id:
        currentUrlParams().urlPath[1] &&  currentUrlParams().urlPath[1] !== "new"
          ?  currentUrlParams().urlPath[1]
          : "",
      name: "",
      description: "",
      active: false,
      prefix: "",
      value: "",
      value_type: 'pln',
      code_type: "all_activities",
      active_from: '',
      active_to: '',
      count: 1,
      unlimited: false,
      only_for_new_players: false,
      limit_of_usage: 1,
      unlimited_usage_by_user: false,
      users: [],
      sports: [],
      locations: [],
      activities: [],
      afterSave: () => {},
    };
  },
};

const FormWrapper = ({...props}) => {
  useEffect(() => {
    const ws = new WsManager()
    ws.connect(CHANNEL_NAME, {
      connected: PromoCodesFunctions.connectedToChannel,
      disconnected: PromoCodesFunctions.disconnectedToChannel,
      received: PromoCodesFunctions.receivedMsg
    })
    PromoCodesFunctions.ws  = ws

    return function disconnect() {
      ws.unsubscribe()
      ws.disconnect()
    }
  }, []);

  return <Form {...props}/>
}

export const PromoCodesForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...PromoCodesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      PromoCodesFunctions.create(values, formikProps)
    } else {
      PromoCodesFunctions.update(values, formikProps)
    }
  },
})(FormWrapper);
