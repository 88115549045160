import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  add: (values, formik) => {
    return Api.competition_groups.admin
      .add_player(values.id, { competition_groups_users: { user_id: values.user_id, role: 0 } })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...UsersFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Dodano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  update: (id, groupId, role, formik, reload) => {
    return Api.competition_groups.admin
        .update_player(id, groupId, {competition_groups_users: {role: role}})
        .then((response) => {
          if (response.ok) {
            formik.setFieldValue("users", response.data.users)
            reload()
            window.setNotifications([{ type: "success", msg: "Zmieniono rolę" }]);
          } else {
            window.setNotifications([
              { type: "alert", msg: "Nie udało się zmienić roli" },
            ]);
          }
        });
  },
  remove: (id, groupId, formik, reload) => {
    return Api.competition_groups.admin
      .remove_player( id, groupId)
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue("users", response.data.users)
          reload()
          window.setNotifications([{ type: "success", msg: "Usunięto" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się usunąć" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id: props.id,
      action: "add",
      user_id: "",
      group_user_id: "",
      users: [],
      user: {},
      afterSave: () => {},
    };
  },
};

export const UsersForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    UsersFunctions[values.action](values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
