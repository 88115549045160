import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";

export const BalanceFunctions = {
  add: (values, formik) => {
    return Api.users.admin.balance
      .add(values.id, { balance: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...BalanceFunctions.defaultValues(formik.props),
          });
          window.setNotifications([
            { type: "success", msg: "Doładowano" },
          ]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się doładować" },
          ]);
        }
      });
  },
  delete: (values, formik) => {
    return Api.users.admin.balance
      .delete(values.id, { balance: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...BalanceFunctions.defaultValues(formik.props),
          });
          window.setNotifications([{ type: "success", msg: "Odjęto" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się odjąć" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id:  currentUrlParams().urlPath[1],
      value: "",
      comment: "",
      action: "",
      afterSave: () => {},
    };
  },
};

export const BalanceForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...BalanceFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (values.action === "add") {
      BalanceFunctions.add(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
          formikProps.setSubmitting(false);
        });
    }
    if (values.action === "delete") {
      BalanceFunctions.delete(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
          formikProps.setSubmitting(false);
        });
    }
  },
})(Form);
