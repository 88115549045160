import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ImageUpload from "PNH/components/CustomUpload/ImageUpload";
import Badge from "components/Badge/Badge";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import moment from "moment";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { PromoCodesFunctions } from "./index";
import Primary from "components/Typography/Primary";
import UsersSelector from "./UsersSelector";
import SportsSelector from "./SportsSelector";
import LocalizationsSelector from "./LocalizationsSelector";
import ActivitiesSelector from "./ActivitiesSelector";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      fetch: !!this.props.values.id,
    };
  }

  form() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer justify="flex-end">
          <GridItem xs={4}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall,
              }}
              open={this.state.deleteModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.deleteModal(false)}
              aria-labelledby="small-modal-slide-title"
              aria-describedby="small-modal-slide-description"
            >
              <DialogTitle
                id="small-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.deleteModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
              </DialogTitle>
              <DialogContent
                id="small-modal-slide-description"
                className={classes.modalBody + " " + classes.modalSmallBody}
              >
                <h5>Na pewno chcesz usunąć dyscyplinę?</h5>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button
                  onClick={() => this.deleteModal(false)}
                  color="transparent"
                  className={classes.modalSmallFooterFirstButton}
                >
                  Nie
                </Button>
                <Button
                  onClick={() => PromoCodesFunctions.delete(this.props)}
                  color="success"
                  simple
                  className={
                    classes.modalSmallFooterFirstButton +
                    " " +
                    classes.modalSmallFooterSecondButton
                  }
                >
                  Tak
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <h4>
              <b>Kod Promocyjny <Primary><b>{values.prefix}{values.code}</b></Primary></b>
            </h4>
          </GridItem>

          <GridItem xs={6}>
            <CustomInput
              labelText="Nazwa"
              error={errors.name}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.name,
                name: "name",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={6}>
            <CustomInput
              labelText="Prefix"
              error={errors.prefix}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.prefix,
                name: "prefix",
                onChange: handleChange,
              }}
            />
          </GridItem>


          <GridItem xs={4} >
            <>
              <InputLabel error={!!errors.active_from} className={classes.label}>
                Ważny od
              </InputLabel>
              <br />
              <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                  utc
                  value={values.active_from}
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={false}
                  filterDate={(date) => {
                    return moment() <= date;
                  }}
                  onChange={(value) => setFieldValue("active_from", value)}
                  inputProps={{ name: "date", autocomplete: "off" }}
                />
              </FormControl>
            </>
          </GridItem>
          <GridItem xs={4} >
            <>
              <InputLabel error={!!errors.active_to} className={classes.label}>
                Ważny do
              </InputLabel>
              <br />
              <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                  utc
                  value={values.active_to}
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={false}
                  filterDate={(date) => {
                    return moment() <= date;
                  }}
                  onChange={(value) => setFieldValue("active_to", value)}
                  inputProps={{ name: "date", autocomplete: "off" }}
                />
              </FormControl>
            </>
          </GridItem>
          <GridItem xs={12}><hr /></GridItem>
          <GridItem xs={4} >
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                error={!!errors.code_type}
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Typ
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.code_type}
                onChange={handleChange}
                inputProps={{
                  name: "code_type",
                }}
              >
                {[['all_activities', 'Wszystkie zajęcia'], ['pnh_activities', 'Tylko PNH'], ['organizer_activities', 'Tylko Organizatorzy']].map(
                  (type) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={type[0]}
                    >
                      {type[1]}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={4}>
            <CustomInput
              labelText="Wartość"
              error={errors.value}
              value={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.value,
                name: "value",
                onChange: handleChange,
              }}
            />
          </GridItem>

          <GridItem xs={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                error={!!errors.value_type}
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Jednostka
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={values.value_type}
                onChange={handleChange}
                inputProps={{
                  name: "value_type",
                }}
              >
                {[['pln', 'PLN'], ['perc', '%']].map(
                  (type) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={type[0]}
                    >
                      {type[1]}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} >
            <CustomInput
              labelText="Opis"
              error={errors.description}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                value: values.description,
                name: "description",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={3}>
            <FormControlLabel
              style={{ marginTop: "20px" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.active}
                  onClick={() => setFieldValue("active", !values.active)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Aktywny"
            />
          </GridItem>
          <GridItem xs={3}>
            <FormControlLabel
              style={{ marginTop: "20px" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.only_for_new_players}
                  onClick={() => setFieldValue("only_for_new_players", !values.only_for_new_players)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Tylko dla nowych graczy"
            />
          </GridItem>
          <GridItem xs={3}>
            <FormControlLabel
              style={{ marginTop: "20px" }}
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.unlimited}
                  onClick={() => setFieldValue("unlimited", !values.unlimited)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Nielimitowany"
            />
          </GridItem>
          {!values.unlimited && <GridItem xs={3}>
            <CustomInput
              labelText="Ilość wielokrotnego użycia"
              error={errors.limit_of_usage}
              value={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.limit_of_usage,
                name: "limit_of_usage",
                onChange: handleChange,
              }}
            />
          </GridItem>}

          <GridItem xs={12}><hr /></GridItem>
          <GridItem xs={12}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Ograniczenia
            </InputLabel>
          </GridItem>
          <UsersSelector {...this.props} />
          <SportsSelector {...this.props} />
          <LocalizationsSelector {...this.props} />
          <ActivitiesSelector {...this.props} />
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm>
            {values.action === 'update' && <Button
              color="danger"
              className={classes.marginRight}
              onClick={() => this.deleteModal(true)}
            >
              Usuń kod
            </Button>}
          </GridItem>
          <GridItem xs={12} sm style={{ textAlign: "right" }}>
            {values.action === 'create' && <CustomInput
              labelText="Ilość kodów"
              error={errors.count}
              value={{
                fullWidth: true,
              }}
              inputProps={{
                value: values.count,
                name: "count",
                onChange: handleChange,
              }}
            />}
            <Button type="submit" color="success">
              {values.action === 'create' ? 'Generuj' : 'Zapisz'}
            </Button>

          </GridItem>
        </GridContainer>
      </form>
    );
  }

  render() {
    if (this.state.fetch) {
      return <>Loading...</>;
    } else {
      return this.form();
    }
  }
}

export default withStyles(styles)(Form);
