import React, { useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import { Functions } from "./index";
import { makeStyles } from "@material-ui/core";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { I18n } from "PNH/components/I18n";
import { Api } from "PNH/services/Api";
import { CommentsForm } from "PNH/components/Comments/Form";
import EditIcon from '@material-ui/icons/Edit';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import PeopleIcon from "@material-ui/icons/People";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import DeleteIcon from '@material-ui/icons/Delete';
import ListAltIcon from '@material-ui/icons/ListAlt';

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const RenderDialog = ({ values, errors, handleSubmit, setFieldValue, handleChange, setOpenModal, openModal, ...props }) => {
  const classes = useStyles();

  return <Dialog
    maxWidth="sm"
    fullWidth
    classes={{
      root: classes.center + " " + classes.modalRoot,
      paper: classes.modal,
    }}
    open={openModal}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => setOpenModal(false)}
  >
    <DialogContent
      id="small-modal-slide-description"
      className={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} >
          <h3>Zdarzenie #{values.id}</h3>
          <h6>{values.created_at}</h6>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            style={{ position: 'Absolute', right: '0px', top: '10px' }}
            onClick={() => setOpenModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </GridItem>
        {!values.targetable_id &&<GridItem xs={12} ><h6>Linkowanie</h6><hr /></GridItem>}

        {!values.targetable_id && <GridItem xs={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Model
            </InputLabel>
            <Select
              key={`targetable-${values.targetable_id}-${values.id}`}
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={values.targetable_type}
              onChange={({ ...attr }) => {
                setFieldValue("targetable", {});
                setFieldValue("targetable_id", "");
                handleChange(attr);
              }}
              inputProps={{
                name: "targetable_type",
              }}
            >
              {["", "Activity", "User", "ActivityTemplate"].map((g) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={g}
                >
                  {I18n.t(g) || "Wybierz"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>}
        {values.targetable_type && !values.targetable_id && <GridItem xs={12} md={6}>
          <AsyncSelector
            key={`targetable-${values.targetable_type}-${values.id}`}
            apiMethod={Functions.targetableApi(values)}
            apiShowMethod={Functions.targetableApiShow(values)}
            labelKey="label"
            label="Rekord"
            name="targetable_id"
            defaultValue={values.targetable_id}
            value={
              values.targetable && {
                id: values.targetable.id,
                label: values.targetable.label,
              }
            }
            onChange={(value, data) => {
              setFieldValue("targetable", data);
              setFieldValue("targetable_id", value);
            }}
          />
        </GridItem>}
        <GridItem xs={12}>
          {renderResults(values, setFieldValue)}
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Typ
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={values.event_type}
              onChange={handleChange}
              inputProps={{
                name: "gender",
              }}
            >
              {["general", "benefit_change", "deposit_drop", "negative_opinion", "template_added"].map((g) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={g}
                >
                  {I18n.t(g)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Status
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={values.event_status}
              onChange={handleChange}
              inputProps={{
                name: "event_status",
              }}
            >
              {["opened", "inprogress", "done", "archived"].map((g) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={g}
                >
                  {I18n.t(g)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText="Treść"
            error={errors.message}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              value: values.message,
              name: "message",
              onChange: handleChange,
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText="Dodatkowe tagi"
            error={errors.custom_tags}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              value: values.custom_tags,
              name: "custom_tags",
              onChange: handleChange,
            }}
          />
        </GridItem>

        <GridItem xs={12} style={{ marginTop: "50px" }}><h6>Administrator</h6><hr /></GridItem>
        <GridItem xs={12} >
          <AsyncSelector
            key={`assigned-${values.assigned && values.assigned.id}-${values.id}`}
            apiMethod={Api.users.admin.index}
            apiShowMethod={Api.users.admin.show}
            labelKey="label"
            label="Przypisany"
            name="assigned_id"
            defaultValue={values.assigned_id}
            value={
              values.assigned && {
                id: values.assigned.id,
                label: values.assigned.label,
              }
            }
            onChange={(value, data) => {
              setFieldValue("assigned", data);
              setFieldValue("assigned_id", value);
            }}
          />
        </GridItem>
        <GridItem xs={12} style={{ textAlign: 'right' }}>
          <hr />
          <Button color="primary" onClick={handleSubmit}> {values.id ? 'Zapisz' : "Dodaj"}</Button>
        </GridItem>
        {values.id && <GridItem xs={12} style={{ marginTop: "50px" }}><h6>Akcje</h6><hr /></GridItem>}
        {values.id && <GridItem xs={12} >
          <CommentsForm afterSave={() => { Functions.get(values, props) }} key={`comments-${values.id}-${values.comments && values.comments.length}`} id={values.id} addCommentApi={Api.system_events.admin.add_comment} comments={values.comments} />
        </GridItem>}
      </GridContainer>
    </DialogContent>
  </Dialog>
}

const renderResults = (data, setFieldValue) => {
  if (!!!data.targetable_id) {
    return null
  }
  const url = (data) => {
    if(data.targetable_type == 'Activity') {
      return  '/activities/' + data.targetable_id 
    }
    if(data.targetable_type == 'User') {
      return  '/users/' + data.targetable_id 
    }
    if(data.targetable_type == 'ActivityTemplate') {
      return  '/activity_templates/' + data.targetable_id 
    }
  }

  return <Card >
    <CardBody>
      <GridContainer>
        <GridItem xs={12}>
          <span style={{
            position: "absolute",
            rop: '0px',
            right: '10px'
          }}><Button
            size='sm'
            justIcon color="danger" onClick={() => {
              setFieldValue("targetable_id", null);
              setFieldValue("targetable", {})
            }}>
              <DeleteIcon />
            </Button></span>
          <Link to={url(data)} >
            <GridContainer alignItems="center" >
              <GridItem xs={1}>
                {data.targetable_type == 'Activity' && <SportsBasketballIcon style={{ color: '#1ad1a2' }} /> }
                {data.targetable_type == 'User' && <PeopleIcon style={{ color: '#1ad1a2' }} />}
                {data.targetable_type == 'ActivityTemplate' && <ListAltIcon style={{ color: '#1ad1a2' }} /> }
              </GridItem>
              <GridItem xs={11} >
                <h5 style={{ color: 'black' }}><b>{data.targetable.label}</b></h5>
              </GridItem>
            </GridContainer>
          </Link>
        </GridItem>
      </GridContainer>
    </CardBody>
  </Card>
}


const Form = ({ values, icons = null, ...props }) => {
  const [openModal, setOpenModal] = useState(false)

  const toggleModal = (toggle) => {
    setOpenModal(toggle)
    if (toggle) {
      Functions.get(values, props)
    }
  }

  return <>
    {openModal && <RenderDialog key={`dialog-${values.id}`} values={values} openModal={openModal} setOpenModal={toggleModal} {...props} />}

    <Button style={{ float: 'right' }} color="primary" size='sm' onClick={() => { toggleModal(true) }}> {values.id ? <> {icons} <EditIcon /> </> : "Dodaj"}</Button>

  </>
}

export default withStyles(styles)(Form);
