import Danger from "components/Typography/Danger";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import { I18n } from "PNH/components/I18n";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";

export const  RowFunction = (row, formik) => {
    const { classes, values, setFieldValue } = formik;
    return [
        row.id,
        row.name,
        <>
            {row.start_at}
            {"-"}
            {row.stop_at}
        </>,

        row.sport.name,
        <>
            {row.localization.name} <br /> {row.localization.street}{" "}
            {row.localization.city}
        </>,
        <>
            {row.all_reserved_spots}/{row.max_players}({row.min_players})
            {row.fa_count > 0 && (
                <Danger style={{ fontWeight: 900 }}>
                    fa: {row.fa_count}
                </Danger>
            )}
        </>,
        row.hidden ? "Tak" : "Nie",
        I18n.t(row.activity_type),
        row.approved_by_admin ? "Tak" : "Nie",
        <>
            <b>
                Dodał: {row.creator.first_name} {row.creator.last_name}
            </b>
            {row.archived && <Danger> Archiwalny</Danger> }
            <br />
        </>,
        row.created_at,

        <Button
            color="success"
            simple
            link
            href={`/activity_templates/${row.id}`}
            key={`edit_${row.id}`}
            className={classes.actionButton}
        >
            <Edit className={classes.icon} />
        </Button>,
    ];
} 