import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import { UsersFunctions } from "./index";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
  }

  fetch = () => {
    if (this.props.values.id) {
      Api.users.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      values,
      errors,
      handleChange,
      handleSubmit,
    } = this.props;
    return (<>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText="Data Ważności Bonusu"
            error={errors.bonus_expiration_date}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "RRRR-MM-DD",
              value: values.bonus_expiration_date,
              name: "bonus_expiration_date",
              onChange: handleChange,
              type: "text",
              autocomplete: "off",
            }}
            autoComplete="off"
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="flex-end">
          <GridItem xs={3} style={{textAlign: 'right'}}>
            <Button type="submit" onClick={handleSubmit} color="primary">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
    </>
    );
  }
}

export default withStyles(styles)(Form);
