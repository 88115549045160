import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { I18n } from "PNH/components/I18n";
import Button from "components/CustomButtons/Button";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import Check from "@material-ui/icons/Check";
import { Checkbox } from "@material-ui/core";
import BulkAction from "./BulkAction";

class PromoCodesTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    with_id: "",
    with_name: "",
    with_user_id: "",
    witn_code: "",
    with_prefix: "",
    with_active: "",
    with_active_from_greater: "",
    order_element: 'id ASC',
    selected: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.promo_codes.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_id: this.state.id,
        with_code: this.state.with_code,
        with_name: this.state.with_name,
        with_prefix: this.state.with_prefix,
        with_active: this.state.with_active,
        with_active_from_greater: this.state.with_active_from_greater,
        with_active_to_less: this.state.with_active_to_less,
        order_element: this.state.order_element,
      })
      .then((response) => {
        this.setState({ data: response.data.rows, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      <Checkbox
        tabIndex={-1}
        checked={this.state.selected.includes(row.id)}
        onClick={() => {
          let ids = this.state.selected.includes(row.id) ? this.state.selected.filter((v) => (v != row.id)) : [...this.state.selected, row.id];
          this.setState({ selected: ids });
        }}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />,
      row.id,
      row.name,
      row.prefix,
      row.code,
      row.value + row.value_type,
      row.active ? "tak" : "nie",
      row.active_from,
      row.active_to,
      <Button
        color="success"
        simple
        link
        href={`/promo-codes/${row.id}`}
        key={`edit_${row.id}`}
        className={classes.actionButton}
      >
        <Edit className={classes.icon} />
      </Button>,
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };
  render() {

    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Button
            style={{ float: "right" }}
            color="success"
            onClick={() => {
              let url = "/promo-codes/new";
              this.props.history.push(url)
            }}
          >
            Dodaj
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="secondary">
              <h4 className={classes.cardIconTitle}>Filtry</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={2}>
                  <CustomInput
                    id={`with_id`}
                    labelText="ID"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj...",
                      type: "text",
                      name: `with_id`,
                      value: this.state[`with_id`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <CustomInput
                    id={`with_name`}
                    labelText="Nazwa"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj...",
                      type: "text",
                      name: `with_name`,
                      value: this.state[`with_name`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <CustomInput
                    id={`with_prefix`}
                    labelText="Prefix"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj...",
                      type: "text",
                      name: `with_prefix`,
                      value: this.state[`with_prefix`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <CustomInput
                    id={`with_code`}
                    labelText="Kod"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj...",
                      type: "text",
                      name: `with_code`,
                      value: this.state[`with_code`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Aktywny
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.with_active}
                      onChange={(e) =>
                        this.setState(
                          { with_active: e.target.value },
                          this.getData
                        )
                      }
                      inputProps={{
                        isClearable: true,
                        name: "with_active",
                      }}
                    >
                      {["true", "false"].map((g) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={g}
                        >
                          {I18n.t(g)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    id={`with_active_from_greater`}
                    labelText="Data od"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj ROK-M-D...",
                      type: "text",
                      name: `with_active_from_greater`,
                      value: this.state[`with_active_from_greater`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    id={`with_active_to_less`}
                    labelText="Data do"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "Szukaj ROK-M-D...",
                      type: "text",
                      name: `with_active_to_less`,
                      value: this.state[`with_active_to_less`],
                      onChange: this.handleChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Sortowanie
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.order_element}
                      onChange={(e) =>
                        this.setState({ order_element: e.target.value }, this.getData)
                      }
                      inputProps={{
                        isClearable: true,
                        name: "organizer",
                      }}
                    >
                      {["promo_codes.prefix", "promo_codes.id"].map((g) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={`${g} DESC`}
                        >
                          {g} Malejąco
                        </MenuItem>
                      ))}
                      {["promo_codes.prefix", "promo_codes.id"].map((g) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={`${g} ASC`}
                        >
                          {g} Rosnąco
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="secondary">
              <h4 className={classes.cardIconTitle}>Historia zmian</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  '',
                  "id",
                  "Nazwa",
                  "Prefix",
                  "Kod",
                  "Wartość",
                  "Aktywny",
                  "Od",
                  "Do",
                  'Opcje'
                ]}
                tableData={this.state.data.map(this.mapRows)}
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                page={this.state.page}
                rowsPerPage={this.state.limit}
                count={this.state.total}
                onChangePage={this.changePage}
                onChangeRowsPerPage={this.onChangeRowsPerPage}
                labelRowsPerPage="Ilość na stronie"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} z ${count}`
                }
              />
              <BulkAction refreshList={this.getData} ids={this.state.selected} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

PromoCodesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(extendedTablesStyle)(PromoCodesTable);
