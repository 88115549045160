import React from "react";
import { MainPageTilesForm } from "./MainPageTilesForm/index";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import PeopleIcon from "@material-ui/icons/People";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { currentUrlParams } from "PNH/services/Helpers";

class Profile extends React.Component {
  render() {
    const { classes } = this.props;
    const url = currentUrlParams();
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <PeopleIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Kafelki</h4>
              </CardHeader>
              <CardBody>
                <MainPageTilesForm {...this.props} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default withStyles(dashboardStyle)(Profile);
