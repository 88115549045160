import React from "react";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import moment from "moment";
import Dates from "../Dates";


export const DateAndTimeSection = ({ handleChange, classes, values, errors, setFieldValue, ...props }) => {
    return <>
        <GridItem xs={12}>
            <hr />
        </GridItem>
        {!values.id && (
            <>
                <GridItem xs={12}>
                    <Dates  handleChange={handleChange} classes={classes} values={values} errors={errors} setFieldValue={setFieldValue} {...props} />
                </GridItem>
            </>
        )}
        <GridItem md={3}>
            {values.id && (
                <>
                    <InputLabel error={!!errors.date} className={classes.label}>
                        Data zajęć
                    </InputLabel>
                    <br />
                    <FormControl fullWidth style={{ zIndex: 10 }}>
                        <Datetime
                            utc
                            value={values.date}
                            dateFormat={"YYYY-MM-DD"}
                            timeFormat={false}
                            onChange={(value) => setFieldValue("date", value)}
                            inputProps={{ name: "date", autocomplete: "off" }}
                        />
                    </FormControl>
                </>
            )}
        </GridItem>

        <GridItem md={3}>
            <InputLabel error={!!errors.start_at} className={classes.label}>
                Czas rozpoczęcia
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                    utc
                    value={values.start_at}
                    dateFormat={false}
                    timeFormat={"H:mm"}
                    onChange={(value) => {
                        const format = moment(value).format("H:mm");
                        const time = format === "Invalid date" ? value : format;
                        setFieldValue("start_at", time);
                    }}
                    inputProps={{ name: "start_at", autocomplete: "off" }}
                />
            </FormControl>
        </GridItem>
        <GridItem md={3}>
            <InputLabel error={!!errors.stop_at} className={classes.label}>
                Czas zakończenia
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                    utc
                    value={values.stop_at}
                    dateFormat={false}
                    timeFormat={"H:mm"}
                    onChange={(value) => {
                        const format = moment(value).format("H:mm");
                        const time = format === "Invalid date" ? value : format;
                        setFieldValue("stop_at", time);
                    }}
                    inputProps={{ name: "stop_at", autocomplete: "off" }}
                />
            </FormControl>
        </GridItem>
    </>
}