import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";

export const Functions = {
  create: (values, formik) => {
    return Api.benefit_statuses.admin.show(values.benefit_id, { benefit: values }).then((response) => {
      if (response.ok) {
        formik.setFieldValue('result', response.data)
        window.setNotifications([
          { type: "success", msg: "Sprawdzono kartę" },
        ]);
   
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      benefit_id: "",
      first_line: "",
      second_line: "",
      result: "",
      afterSave: () => {},
    };
  },
};

export const BenefitForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...Functions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    Functions.create(values, formikProps)
    .then((response) => {
      values.afterSave(response);
      formikProps.setFieldValue("afterSave", () => {});
    })
    .then(() => {
      formikProps.setSubmitting(false);
      formikProps.setTouched({});
    });
  },
})(Form);
