import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";
import Button from "../../../../components/CustomButtons/Button";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import moment from "moment/moment";
import React, {useCallback, useState} from "react";
import formRegular from "../../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsModal from "../../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import {Api} from "../../../services/Api";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};

const MedicoverEntries = ({classes, ...props}) => {
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()


    const generateReport = useCallback(() => {
        Api.reports.admin.create({
            report: {
                report_class: "Reports::MedicoverEntriesReportService",
                params: {date_from: dateFrom,
                    date_to: dateTo}
            }
        }).then((r) => {
            if(r.ok) {
                window.setNotifications([
                    {type: "success", msg: "Dodano do kolejki"},
                ]);
            }
        })
    })

    return <Card>
        <CardHeader>
            <h3>Wejścia Medicover</h3>

        </CardHeader>
        <CardBody>
            <GridContainer>
                <GridItem xs={6}>
                    <>
                        <InputLabel className={classes.label}>
                            OD
                        </InputLabel>
                        <br/>
                        <FormControl fullWidth style={{zIndex: 10}}>
                            <Datetime
                                utc
                                locale={moment.locale('pl')}
                                value={dateFrom}
                                dateFormat={"YYYY-MM-DD"}
                                timeFormat={false}
                                filterDate={(date) => {
                                    return moment() <= date;
                                }}
                                onChange={(value) => setDateFrom(value)}
                                inputProps={{name: "date", autocomplete: "off"}}
                            />
                        </FormControl>
                    </>
                </GridItem>
                <GridItem xs={6}>
                    <InputLabel className={classes.label}>
                        DO
                    </InputLabel>
                    <br/>
                    <FormControl fullWidth style={{zIndex: 10}}>
                        <Datetime
                            utc
                            locale={moment.locale('pl')}
                            value={dateTo}
                            dateFormat={"YYYY-MM-DD"}
                            timeFormat={false}
                            filterDate={(date) => {
                                return moment() <= date;
                            }}
                            onChange={(value) => setDateTo(value)}
                            inputProps={{name: "date", autocomplete: "off"}}
                        />
                    </FormControl>
                </GridItem>
            </GridContainer>
        </CardBody>
        <CardFooter>
            <Button color={"primary"} fullWidth round onClick={generateReport}>Gneruj</Button>
        </CardFooter>
    </Card>
}

export default withStyles(styles)(MedicoverEntries);