import React from "react";
import { Api } from "PNH/services/Api";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LocationsModal from "../Locations";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { I18n } from "PNH/components/I18n";

export const LocationSection = ({ handleChange, classes, values, errors, setFieldValue, ...props }) => {
    return <>
        <GridItem xs={12}>
            <hr />
        </GridItem>
        <GridItem xs={12} md={6}>
            <AsyncSelector
                apiMethod={Api.locations.admin.index}
                apiShowMethod={Api.locations.admin.show}
                labelKey="label"
                label="Lokalizacja"
                name="locations"
                defaultValue={values.localization_id}
                value={
                    values.localization && {
                        id: values.localization.id,
                        label: values.localization.label,
                    }
                }
                onChange={(value, data) => {
                    setFieldValue("localization", data);
                    setFieldValue("localization_id", value);
                    if (values.localization_id !== value) {
                        setFieldValue("location_object_id", "");
                    }
                }}
            />
        </GridItem>
        <GridItem xs={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                    error={!!errors.location_object_id}
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                >
                    Obiekt
                </InputLabel>
                <Select
                    key={`as-loc-ob-${values.localization &&
                        values.localization.id}`}
                    MenuProps={{
                        className: classes.selectMenu,
                    }}
                    classes={{
                        select: classes.select,
                    }}
                    value={values.location_object_id}
                    onChange={handleChange}
                    inputProps={{
                        name: "location_object_id",
                    }}
                >
                    {values.localization &&
                        values.localization.location_objects_attributes &&
                        values.localization.location_objects_attributes.map(
                            (lObject) => (
                                <MenuItem
                                    key={`lo-${lObject.id}`}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value={lObject.id}
                                >
                                    {lObject.description} <br />
                                    <b>{I18n.t(lObject.object_type)}</b>
                                </MenuItem>
                            )
                        )}
                </Select>
            </FormControl>
        </GridItem>
        <GridItem xs={12}>
            <LocationsModal
                updateLocation={(response) => {
                    setFieldValue("localization", response.data);
                    setFieldValue("localization_id", response.data.id);
                }}
                locationID={values.localization_id}
                handleChange={handleChange} classes={classes} values={values} errors={errors} setFieldValue={setFieldValue} 
                {...props}
            />
        </GridItem>

    </>
}