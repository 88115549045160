import React from "react";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import { I18n } from "PNH/components/I18n";


export const PaymentsSection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
          <GridItem xs={12}>
            <h5>
              <b>Płatności</b>
            </h5>
            <hr />
          </GridItem>
          {[
            "pay_by_cash",
            "pay_by_online_transfer",
            "pay_by_prepaid",
            "pay_by_benefit",
            "pay_by_ok_system",
            "pay_by_fitprofit",
            "pay_by_bonus_account"
          ].map((p) => (
            <GridItem xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={values[p]}
                    onClick={() => setFieldValue(p, !values[p])}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={I18n.t(p)}
              />
            </GridItem>
          ))}

    </>
}