import React from "react";
import { Api } from "PNH/services/Api";

import GridItem from "components/Grid/GridItem";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";

import Badge from "components/Badge/Badge";
import Close from "@material-ui/icons/Close";

import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
  };

class CompetitionGroupSelector extends React.Component {
  render() {
    const { classes, values, setFieldValue } = this.props;
   
    return (
      <>
          <GridItem xs={6}>
            <AsyncSelector
              apiMethod={(q) =>
                Api.competition_groups.admin.index(q)
              }
              labelKey="name"
              label="Grupa"
              name="competition_groups"
              onChange={(value, data) => {
                setFieldValue("competition_group_tmp", data);
                setFieldValue("competition_group_tmp_id", value);
              }}
            />
          </GridItem>
          <GridItem xs={2}>
            <Button
              size="sm"
              color="primary"
              disabled={!values.competition_group_tmp_id}
              onClick={async (e) => {
                let competition_groups = values.competition_groups
                  competition_groups.push(values.competition_group_tmp)
                setFieldValue("competition_groups", competition_groups);
              }}
            >
              Dodaj
            </Button>
          </GridItem>
          <GridItem xs={12}>
            {values.competition_groups.map((group) => {
              return <Badge color="info" key={group.name} style={{marginLeft: 10}}>
                {group.name}
                <Button simple size="sm" onClick={() => {
                  let competition_groups =  values.competition_groups.filter((v) => (v.id != group.id))
                  setFieldValue("competition_groups", competition_groups);
                }} link color='danger'><Close/></Button>
              </Badge>
            })}
          </GridItem>
      </>
    );
  }
}

export default withStyles(styles)(CompetitionGroupSelector);
