import React, { useState, useEffect, useCallback } from "react";

var updateUser = false
export const NotificationUpdater = ({ getCurrentUser, getStateUpdates, setCurrentUser, callCurrentUser }) => {

    const MINUTE_MS = 60000;

    useEffect(() => {
        const interval = setInterval(() => {

            callCurrentUser().then(r => {

                if (r.ok) {
                    updateUnreadForAll(r, getCurrentUser(), getStateUpdates())
                    updateMyUnread(r, getCurrentUser(), getStateUpdates())
                    updateListToVerifyCounter(r, getCurrentUser(), getStateUpdates())
                    updateActivitiesVerifyCounter(r, getCurrentUser(), getStateUpdates())

                    if (updateUser) {
                        setCurrentUser(r.data)
                        updateUser = false
                    }
                }
            })
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [])

    return null
}

const updateUnreadForAll = (r, currentUser, stateUpdates, setUpdateUser) => {
    if (currentUser.system_event_count.allUnread !== r.data.system_event_count.allUnread) {
        if (currentUser.system_event_count.allUnread < r.data.system_event_count.allUnread) {
            window.setNotifications([
                {
                    type: "info",
                    msg: "Nowe zdarzenie",
                },
            ]);
        }
        updateUser = true
        stateUpdates.updateNotificationCounterSidebar(r.data)
        stateUpdates.updateNotificationCounterHeader(r.data)
        try {
            stateUpdates.dashboard.setAllNotificationsCount(r.data.system_event_count.allUnread)
        } catch (error) {
             // do nothing
        }
        
    }

}

const updateMyUnread = (r, currentUser, stateUpdates, setUpdateUser) => {
    if (currentUser.system_event_count.myUnread !== r.data.system_event_count.myUnread) {
        if (currentUser.system_event_count.myUnread < r.data.system_event_count.myUnread) {
            window.setNotifications([
                {
                    type: "info",
                    msg: "Nowe zdarzenie",
                },
            ]);
        }
        updateUser = true
        stateUpdates.updateNotificationCounterSidebar(r.data)
        stateUpdates.updateNotificationCounterHeader(r.data)
        try {
            stateUpdates.dashboard.setMyNotificationsCount(r.data.system_event_count.myUnread)
        } catch (error) {
             // do nothing
        }
        
    }
}

const updateListToVerifyCounter = (r, currentUser, stateUpdates, setUpdateUser) => {
    if (currentUser.system_event_count.listToVerify !== r.data.system_event_count.listToVerify) {
        if (currentUser.system_event_count.listToVerify < r.data.system_event_count.listToVerify) {
            updateUser = true
            try {
                stateUpdates.dashboard.setListToVerifyCount(r.data.system_event_count.listToVerify)
            } catch (error) {
                 // do nothing
            }
        }
    }
}

const updateActivitiesVerifyCounter = (r, currentUser, stateUpdates, setUpdateUser) => {
    if (currentUser.system_event_count.activitiesToVerifyCount !== r.data.system_event_count.activitiesToVerifyCount) {
        if (currentUser.system_event_count.activitiesToVerifyCount < r.data.system_event_count.activitiesToVerifyCount) {
            updateUser = true
            
            try {
                stateUpdates.dashboard.setActivitiesToVerifyNotificationsCount(r.data.system_event_count.activitiesToVerifyCount)
            } catch (error) {
                // do nothing
            }
        }
    }
}
