import React from "react";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {I18n} from "PNH/components/I18n";

export const CompetitionType = ({handleChange, classes, values, errors, setFieldValue, ...props}) => {
    return <>
        <GridItem xs={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                    error={!!errors.competition_type}
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                >
                     Rodzaj
                </InputLabel>
                <Select
                    key={`as-loc-ob-${values.localization &&
                    values.localization.id}`}
                    MenuProps={{
                        className: classes.selectMenu,
                    }}
                    classes={{
                        select: classes.select,
                    }}
                    value={values.competition_type}
                    onChange={handleChange}
                    inputProps={{
                        name: "competition_type",
                    }}
                >
                    {["activity", "sparing"].map(
                        (t) => (
                            <MenuItem
                                key={`cot-${t}`}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                }}
                                value={t}
                            >
                                <b>{I18n.t(t)}</b>
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        </GridItem>
    </>
}