import React, { useState } from "react";
import PagesTable from "./Table";
import { currentUrlParams } from "PNH/services/Helpers";
import Editor from "./Editor";

class Users extends React.Component {
  render() {
    const url = currentUrlParams().urlPath;
    if (!url[1]) {
      return (
        <PagesTable {...this.props} />
      );
    } else if (url[1]) {
      return (
        <Editor id={url[1]} />
      );
    }
  }
}

Users.propTypes = {};

export default Users;
