import React, { useState, useCallback, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomTabs from "components/CustomTabs/CustomTabs";
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import { Charts } from "./Stats/charts";
import { ActivitiesToVerify } from "./ActivitiesToVerify/index.jsx";
import { ToDayActivities } from "./ToDaysActivities";
import { ListToVerify } from "./ListToVerify";
import { Events } from "../SystemEvents";
import { ServiceEventForm } from "../SystemEvents/Form";
import Badge from "components/Badge/Badge";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { BenefitForm } from "./BenefitForm";
import {FitprofitForm} from "./FitprofitForm";
const useStyles = makeStyles(styles);

const Dashboard = ({ ...props }) => {

  const [myNotificationsCount, setMyNotificationsCount] = useState()
  const [allNotificationsCount, setAllNotificationsCount] = useState()
  const [listToVerifyCount, setListToVerifyCount] = useState()
  const [activitiesCount, setActivitiesCount] = useState()
  const [activitiesToVerifyNotificationsCount, setActivitiesToVerifyNotificationsCount] = useState()

  useEffect(() => {
    props.stateUpdates['dashboard'] = {};
    props.stateUpdates.dashboard = {
      'setAllNotificationsCount': setAllNotificationsCount,
      'setMyNotificationsCount': setMyNotificationsCount,
      'setListToVerifyCount': setListToVerifyCount,
      'setActivitiesToVerifyNotificationsCount': setActivitiesToVerifyNotificationsCount,
    }
    setAllNotificationsCount(props.currentUser().system_event_count.allUnread)
    setMyNotificationsCount(props.currentUser().system_event_count.myUnread)
    setListToVerifyCount(props.currentUser().system_event_count.listToVerify)
    setActivitiesToVerifyNotificationsCount(props.currentUser().system_event_count.activitiesToVerifyCount)
  }, [])


  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <h3>Zajęcia</h3>
          <CustomTabs
            headerColor="primary"
            tabs={[
              { tabName: <>Zajęcia do weryfikacji<span className={classes.notifications}>{activitiesToVerifyNotificationsCount}</span></>, tabIcon: SportsBasketballIcon, tabContent: <ActivitiesToVerify setCounter={setActivitiesToVerifyNotificationsCount} {...props} /> },
              { tabName: <>Listy do weryfikacji<span className={classes.notifications}>{listToVerifyCount}</span></>, tabIcon: SportsBasketballIcon, tabContent: <ListToVerify setCounter={setListToVerifyCount} {...props} /> },
              { tabName: <>Zajęcia dziś<span className={classes.notifications}>{activitiesCount}</span></>, tabIcon: SportsBasketballIcon, tabContent: <ToDayActivities setCounter={setActivitiesCount} {...props} /> },
            ]}

          />
        </GridItem>
        <GridItem xs={12}>
          <h3>Zdarzenia</h3>
          <CustomTabs
            headerColor="primary"
            tabs={[
              {
                tabName: <>Przypisane do mnie<span className={classes.notifications}>{myNotificationsCount}</span></>, tabIcon: SportsBasketballIcon, tabContent: <>
                  <ServiceEventForm />
                  <Events only_assigned {...props} />
                </>
              },
              {
                tabName: <>Wszystkie<span className={classes.notifications}>{allNotificationsCount}</span></>, tabIcon: SportsBasketballIcon, tabContent: <>
                  <ServiceEventForm />
                  <Events  {...props} />
                </>
              },
            ]}
          />
        </GridItem>
      </GridContainer>
      <Charts {...props} />
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardBody>
              <BenefitForm />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardBody>
             <FitprofitForm />
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </>

  );

}

export default Dashboard;
