import { withFormik } from "formik";
import moment from "moment";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  update: (values, formik) => {
    return Api.users.admin
      .update(values.id, { user: values })
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue(
            "user_sport_levels_attributes",
            response.data.user_sport_levels_attributes
          );
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },

  updateObject: (formik, index, field, value) => {
    const objects_attributes = [...formik.values.user_sport_levels_attributes];
    objects_attributes[index][field] = value;
    formik.setFieldValue("user_sport_levels_attributes", objects_attributes);
  },

  addObject: (formik) => {
    const objects_attributes = [...formik.values.user_sport_levels_attributes];
    objects_attributes.push({
      id: "",
      sport: "",
      sport_id: "",
      level: "",
      edit_only_by_admin: false, 
      check_on_pnh: false, 
      check_on_organizer: false, 
      verified: false,
      _destroy: false,
    });
    formik.setFieldValue("user_sport_levels_attributes", objects_attributes);
  },

  defaultValues: (props) => {

    return {
      id: props.userID,
      user_sport_levels_attributes: [],
      afterSave: () => {},
    };
  },
};

export const SportLevelForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    UsersFunctions.update(values, formikProps)
      .then((response) => {
        values.afterSave(response);
        formikProps.setFieldValue("afterSave", () => {});
      })
      .then(() => {
        formikProps.setTouched({});
      });
  },
})(Form);
