import React from "react";
import TransactionsTable from "./Transactions";
class Transactions extends React.Component {
  render() {
    return <TransactionsTable {...this.props} />;
  }
}

Transactions.propTypes = {};

export default Transactions;
