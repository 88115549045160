/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";

import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {UsersFunctions} from "./index";

import {Api} from "PNH/services/Api";
import {I18n} from "PNH/components/I18n";


class ShoppingCartPage extends React.Component {
    state = {
        summary: false,
        promoCodes: {},
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        this.fetch();
    }

    fetch = () => {
        Api.basket.admin.index(this.props.user_id).then(({ok, data}) => {
            if (ok) {
                this.props.setFieldValue("basket_items", data.rows);
                const promoCodes = {}
                data.rows.forEach((row) => {
                    promoCodes[row.id] = row.promo_code

                })
                this.props.setFieldValue("promoCodes", promoCodes);
            }
        });
    };


    errors = () => {
        if (this.props.values.basket_items.length == 0) {
            return true;
        }

        return (
            this.props.values.basket_items.filter((bi) => {
                if (
                    bi.activity_error ||
                    bi.spots_error ||
                    bi.not_allowed_error ||
                    bi.already_participant_error ||
                    bi.gender_error ||
                    bi.lv_error ||
                    bi.payment_method_error ||
                    bi.user_lv_error ||
                    (!!bi.promo_code && !bi.code_valid) ||
                    !this.props.data.currentUser.account_completed
                ) {
                    return true;
                }
            }).length > 0
        );
    };

    getTransaction = () => {
        Api.transactions.currentUser.basket().then(({ok, data}) => {
            if (ok) {
                this.props.setFieldValue("transaction", data);
                this.setState({summary: true});
            } else {
                this.setState({summary: false}, this.fetch);
            }
        });
    };

    mapFunction = (row, buttonProps = {link: true}) => {
        const {classes, values} = this.props;
        const {promoCodes} = values

        return [
            <div className={classes.imgContainer} key={1}>
                <img src={process.env.REACT_APP_API_URL + row.activity.thumb_image} alt="..." className={classes.img}/>
            </div>,
            <div key={row.id} style={{minWidth: 250}}>
                <a href={row.activity.url}>
                    {row.activity.sport.name} - {row.activity.date}{" "}
                    {row.activity.start_at}
                </a>
                <br/>
                <small className={classes.tdNameSmall}>
                    {row.activity.localization.name} {row.activity.localization.street}{" "}
                    {row.activity.localization.city}
                </small>
                <div>
                    <small className={classes.tdNameSmall}>
                        {row.activity_error &&
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>Zajęcia niedostępne</div>}
                        {row.payment_method_error && (
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>Wybierz metodę płatności</div>
                        )}
                        {row.already_participant_error && (
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>Jest już uczestnikiem zajęć</div>
                        )}
                        {row.gender_error && (
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>Zajęcia tylko
                                dla: {I18n.t(row.activity.gender)}</div>
                        )}
                        {(row.lv_error || row.user_lv_error) && (
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>Zajęcia tylko dla graczy na
                                poziomie: {I18n.t(row.activity.lv)},<br/> poziom
                                gracza: {I18n.t(this.participantLevel(row)) || 'Brak przypisanego poziomu'} </div>
                        )}
                        {row.spots_error && (
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>
                                Brak wystarczającej ilości miejsc (max:{" "}
                                {row.activity.free_spots})
                            </div>
                        )}
                        {row.not_allowed_error && (
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>
                                Brak uprawnień do zapisu tej osoby lub konto nie zostało w pełni
                                uzupełnione
                            </div>
                        )}
                        {row.promo_code && !row.code_valid &&
                            <div style={{fontSize: "7px!important", color: '#f44336'}}>Kod błędny lub nieaktywny</div>}
                    </small>
                </div>
            </div>,
            row.participant.id === row.user.id
                ? `${row.participant.first_name} ${row.participant.last_name}`
                : row.participant.label,
            row.price + "PLN",
            row.deposit + "PLN",
            row.participant.id === row.user.id ?
                <CustomInput
                    labelText="Kod/Voucher"
                    error={!!row.promo_code ? !row.code_valid : false}
                    success={!!row.promo_code ? row.code_valid : false}
                    formControlProps={{
                        fullWidth: true,
                        disabled: !!row.promo_code,
                    }}
                    inputProps={{
                        type: "text",
                        value: promoCodes[row.id],
                        name: "with_tags",
                        onChange: (e) => {
                            let codes = {...promoCodes, ...{[row.id]: e.target.value},}
                            this.props.setFieldValue("promoCodes", codes);
                        },
                    }}/>

                : null
            ,
            row.participant.id === row.user.id ? this.promoCodeButton(row, buttonProps) : null,
            <FormControl
                fullWidth
                className={classes.selectFormControl}
                style={{minWidth: 130}}
            >
                <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
                    Metoda płatności
                </InputLabel>
                <Select
                    value={row.payment_method}
                    onChange={(e) => {

                        this.props.setFieldValue("promoCodes", {});
                        UsersFunctions.update(
                            this.props.user_id,
                            row.id,
                            {payment_method: e.target.value},
                            this.props
                        ).then(() => {
                            this.fetch();
                        });
                    }}
                    MenuProps={{
                        className: classes.selectMenu,
                        classes: {paper: classes.selectPaper},
                    }}
                    classes={{select: classes.select}}
                    inputProps={{
                        name: "payment_method",
                    }}
                >
                    {row.available_payment_method.map((pm) => {
                        if (!pm.active) {
                            return null;
                        }
                        return (
                            <MenuItem
                                disabled={!pm.active}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple,
                                }}
                                value={pm.name}
                            >
                                {I18n.t(pm.name)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>,
            <Tooltip
                id="close1"
                title="Usuń z listy"
                placement="left"
                onClick={() => {
                    Api.basket.admin.remove(this.props.user_id,row.id).then(this.fetch);
                }}
                classes={{tooltip: classes.tooltip}}
            >
                <Button {...buttonProps} color="danger" className={classes.actionButton}>
                    <Close/>
                </Button>
            </Tooltip>,
        ];
    };

    participantLevel(row) {
        const level = row.participant.user_sport_levels_attributes.find((lv) => {
            if (lv.sport_id == row.activity.sport_id) {
                return true
            }
            return false
        })

        return level && level.level
    }

    promoCodeButton(row, buttonProps) {
        const {classes, values} = this.props;
        const {promoCodes} = values
        if (!!!promoCodes[row.id]) {
            return null;
        }
        return row.promo_code ?
            <Tooltip
                id="close1"
                title="Usuń kod"
                placement="left"
                onClick={(e) => {
                    UsersFunctions.update(
                        this.props.user_id,
                        row.id,
                        {promo_code: ''},
                        this.props
                    ).then(() => {
                        this.fetch();
                    });
                }}
                classes={{tooltip: classes.tooltip}}
            >
                <Button {...buttonProps} color="danger" className={classes.actionButton}>
                    Usuń kod
                </Button>
            </Tooltip> :
            <Tooltip
                id="close1"
                title="Dodaj kod"
                placement="left"
                onClick={(e) => {
                    UsersFunctions.update(
                        this.props.user_id,
                        row.id,
                        {promo_code: promoCodes[row.id]},
                        this.props
                    ).then(() => {
                        this.fetch();
                    });
                }}
                classes={{tooltip: classes.tooltip}}
            >
                <Button {...buttonProps} color="primary" className={classes.actionButton}>
                    Zapisz kod
                </Button>
            </Tooltip>
    }

    renderTable = () => {
        const {classes} = this.props;
        return (
            <Table
                tableHead={[
                    "",
                    "Zajęcia",
                    "Uczestnik",
                    "Cena",
                    "Wymagana Kaucja",
                    "Kod promocyjny",
                    "",
                    "Metoda płatności",
                    "Metoda płatności",
                    "",
                ]}
                tableData={this.props.values.basket_items.map(this.mapFunction)}
            />
        );
    };

    render() {
        const {classes, values} = this.props;
        return (

            <Card plain>
                <CardBody plain>
                    <h3 className={classes.cardTitle}>Koszyk</h3>
                    {this.renderTable()}
                </CardBody>
            </Card>

        );
    }
}

ShoppingCartPage.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(stylesExtended)(
    ShoppingCartPage
);
