import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { I18n } from "PNH/components/I18n";
import Accordion from "components/Accordion/Accordion";

export const TableFilters = (formik) => {
    const { classes, SearchFunctions } = formik;
    return (<GridContainer>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <GridContainer style={{ justifyContent: 'flex-end' }}>
                            <GridItem xs={1}>
                                {withID(formik)}
                            </GridItem>
                            <GridItem xs={3}>
                                {withName(formik)}
                            </GridItem>
                            <GridItem xs={3}>
                                {withPrefix(formik)}
                            </GridItem>
                            <GridItem xs={5}>
                                {withCode(formik)}
                            </GridItem>
                            <GridItem xs={12}>
                                <Accordion
                                    collapses={[{
                                        title: 'Zaawansowane filtry',
                                        content: <GridContainer>
                                            <GridItem xs={3}>
                                                {withActive(formik)}
                                            </GridItem>
                                            <GridItem xs={3}>
                                                {withDateGreater(formik)}
                                            </GridItem>
                                            <GridItem xs={3}>
                                                {withDateLess(formik)}
                                            </GridItem>
                                        </GridContainer>
                                    }
                                    ]}
                                />
                            </GridItem>
                            <GridItem xs={3}></GridItem>
                            <GridItem xs={3}>
                                {order(formik)}
                            </GridItem>
                            <GridItem xs={3} style={{ textAlign: "right" }}>
                                {SearchFunctions.showClearButton && <Button
                                    link
                                    color="success"
                                    onClick={SearchFunctions.clearFilters}
                                >
                                    Wyczyść
                                </Button>}
                                <Button
                                    color="success"
                                    onClick={formik.handleSubmit}
                                >
                                    Filtruj
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <input type="submit" style={{ display: 'none' }} />
        </form>
    </GridContainer>
    )
}

const withID = (formik) => (
    <CustomInput
        id={`with_id`}
        error={formik.errors.with_id}
        labelText="ID"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj...",
            type: "text",
            name: `with_id`,
            value: formik.values.with_id,
            onChange: (e) => { formik.setFieldValue(`with_id`, e.target.value) },
        }}
    />
)

const withName = (formik) => (
    <CustomInput
        id={`with_name`}
        error={formik.errors.with_name}
        labelText="Nazwa"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Nazwa...",
            type: "text",
            name: `with_name`,
            value: formik.values.with_name,
            onChange: (e) => { formik.setFieldValue(`with_name`, e.target.value) },
        }}
    />
)

const withPrefix = (formik) => (
    <CustomInput
        id={`with_prefix`}
        error={formik.errors.with_prefix}
        labelText="Prefix"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Prefix...",
            type: "text",
            name: `with_prefix`,
            value: formik.values.with_prefix,
            onChange: (e) => { formik.setFieldValue(`with_prefix`, e.target.value) },
        }}
    />
)
const withCode = (formik) => (
    <CustomInput
        id={`with_code`}
        error={formik.errors.with_code}
        labelText="Kod"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Kod...",
            type: "text",
            name: `with_code`,
            value: formik.values.with_code,
            onChange: (e) => { formik.setFieldValue(`with_code`, e.target.value) },
        }}
    />
)



const withActive = (formik) => {
    const { classes } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Aktywny
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.with_active}
            onChange={(e) => { formik.setFieldValue(`with_active`, e.target.value) }
            }
            inputProps={{
                isClearable: true,
                name: "with_active",
            }}
        >
            {["", "true", "false"].map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g) || "Wyczyść"}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

const withDateGreater = (formik) => (
    <CustomInput
        id={`with_active_from_greater`}
        error={formik.errors.with_active_from_greater}
        labelText="Data od"
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_active_from_greater`,
            value: formik.values.with_active_from_greater,
            onChange: (e) => { formik.setFieldValue(`with_active_from_greater`, e.target.value) },
        }}
    />
)

const withDateLess = (formik) => (
    <CustomInput
        id={`with_active_from_less`}
        error={formik.errors.with_active_from_less}
        labelText="Data do"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_active_from_less`,
            value: formik.values.with_active_from_less,
            onChange: (e) => { formik.setFieldValue(`with_active_from_less`, e.target.value) },
        }}
    />
)

const order = (formik) => {
    const { classes, setFieldValue, SearchFunctions } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Sortowanie
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.order}
            onChange={(e) =>
                setFieldValue(`order`, e.target.value)
            }
            inputProps={{
                isClearable: true,
                name: "order",
            }}
        >
            {SearchFunctions.orderBy().map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g)}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}

