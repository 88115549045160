import React, {useState} from "react";
import {Api} from "../../../../services/Api";
import Button from "../../../../../components/CustomButtons/Button";
import AsyncSelector from "../../../../components/AsyncSelector/AsyncSelector";
import EditIcon from '@material-ui/icons/Edit';
import {Link} from "react-router-dom";

export const ActivitiesForm = ({row, classes}) => {
    const [activityID, setActivityID] = useState(row.activity_id)
    const [activity, setActivity] = useState(row.activity)
    const [edit, setEdit] = useState(false)
    return <>
        {!edit && (activity && <Link to={`/activities/${activityID}`} target={'_blank'}>{activity.label}</Link>)}
        {!edit && !row.confirmed && <Button color={"primary"} justIcon={true}  onClick={() => setEdit(true)} size={"sm"} style={{marginLeft: '10px'}}><EditIcon /></Button>}

        {edit && <AsyncSelector
            skipLoading={true}
            apiMethod={Api.activities.admin.index}
            apiShowMethod={Api.activities.admin.show}
            labelKey="label"
            label="Zajęcia"
            name="activities_id"
            disabled={row.confirmed}
            defaultValue={activityID}
            value={
                activity && {
                    id: activity.id,
                    label: activity.label,
                }
            }
            onChange={(value, data) => {
                setActivityID(value);
                setActivity(data)
                if (activityID !== row.activity_id) {
                    setEdit(false)
                    Api.benefit_entries.admin.update(row.id, {activity_id: activityID}).then((r) => {
                        if (r.ok) {
                            window.setNotifications([
                                {
                                    type: "success",
                                    msg: "Zapisano",
                                },
                            ]);
                        } else {
                            window.setNotifications([
                                {
                                    type: "alert",
                                    msg: "Nie zapisano",
                                },
                            ]);
                        }
                    })
                }
            }}
        />}
    </>
}
