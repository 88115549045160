import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from 'react-router-dom'
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SettingsIcon from "@material-ui/icons/Settings";
import Close from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { PagesForm } from "./PagesForm/index";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";

class PagesTable extends React.Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    with_id: "",
    with_name: "",
    with_url: "",
    pageModal: false,
    pageID: false,
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    Api.pages.admin
      .index({
        page: this.state.page,
        limit: this.state.limit,
        with_id: this.state.with_id,
        with_name: this.state.with_name,
        with_url: this.state.with_url,
      })
      .then((response) => {
        const tableContent = response.data.rows.map(this.mapRows);
        this.setState({ data: tableContent, total: response.data.total });
      });
  };
  mapRows = (row) => {
    const { classes } = this.props;
    return [
      row.id,
      row.url,
      row.name,
      row.visible ? "tak" : "Nie",

      <div key={`ra-${row.id}`}>
        <Button
          key={"btn1" + row.id}
          color="success"
          simple
          onClick={() => {
            let url = `/s/${row.url}`;
            this.props.history.push(url)
          }}
          key={`edit_${row.id}`}
          className={classes.actionButton}
        >
          <VisibilityIcon className={classes.icon} />
        </Button>
        <Button
          key={"btn2" + row.id}
          color="success"
          simple
          onClick={() => {
            let url= `/pages/${row.id}`;
            this.props.history.push(url)
          }}
          key={`edit_${row.id}`}
          className={classes.actionButton}
        >
          <Edit className={classes.icon} />
        </Button>
        <Button
          key={"btn3" + row.id}
          color="info"
          simple
          onClick={() => {
            this.setState({ pageID: row.id, pageModal: true });
          }}
          key={`settings_${row.id}`}
          className={classes.actionButton}
        >
          <SettingsIcon className={classes.icon} />
        </Button>
      </div>,
    ];
  };
  changePage = (event, page) => {
    this.setState({ page: page }, this.getData);
  };

  onChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value, page: 0 }, this.getData);
  };

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value, page: 0 },
      this.getData
    );
  };

  formModal = () => {
    const { classes } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.pageModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ pageID: undefined, pageModal: false })}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() =>
              this.setState({ pageID: undefined, pageModal: false })
            }
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {this.state.pageID ? "Edytuj Stronę" : "Dodaj Stronę"}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <PagesForm
            id={this.state.pageID}
            afterSave={() => {
              this.getData();
              this.setState({ pageID: undefined, pageModal: false });
            }}
            {...this.props}
          />
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    const { classes } = this.props;
    return (
        <GridContainer>
          <GridItem xs={12}>
            <Button
              style={{ float: "right" }}
              color="success"
              onClick={() => {
                this.setState({ pageModal: true });
              }}
            >
              Dodaj
            </Button>
            {this.state.pageModal && this.formModal()}
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <h4 className={classes.cardIconTitle}>Filtry</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={1}>
                    <CustomInput
                      id={`with_id`}
                      labelText="ID"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_id`,
                        value: this.state[`with_id`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      id={`with_url`}
                      labelText="Url"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_url`,
                        value: this.state[`with_url`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={5}>
                    <CustomInput
                      id={`with_name`}
                      labelText="Nazwa"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Szukaj...",
                        type: "text",
                        name: `with_name`,
                        value: this.state[`with_name`],
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12}>
            <Card>
              <CardHeader color="secondary">
                <h4 className={classes.cardIconTitle}>Strony</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHead={["ID", "Url", "Nazwa", "Widoczne", ""]}
                  tableData={this.state.data}
                />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  page={this.state.page}
                  rowsPerPage={this.state.limit}
                  count={this.state.total}
                  onChangePage={this.changePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                  labelRowsPerPage="Ilość na stronie"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} z ${count}`
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}

PagesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles({ ...notificationsStyle, ...extendedTablesStyle })(
  PagesTable
));
