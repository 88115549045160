import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const Functions = {
  get: (values, formik) => {
    if (values.id) {
      return Api.system_events.admin.show(values.id).then((response) => {
        if (response.ok) {
          values.afterSave()
          formik.setValues({
            ...Functions.defaultValues(formik),
            ...response.data,
          });

        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
    }
  },
  create: (values, formik) => {
    return Api.system_events.admin.create({ system_event: values }).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Dodano" },
        ]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.system_events.admin
      .update(values.id, { system_event: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...Functions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },

  delete: (formik) => {
    return Api.system_events.admin.delete(formik.values.id).then((response) => {
      if (response.ok) {
        formik.setNotifications([{ type: "success", msg: "Usunięto" }]);
        formik.afterSave(response);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    });
  },

  targetableApi: (values) => {
    switch (values.targetable_type) {
      case 'User':
        return Api.users.admin.index;
      case 'Activity':
        return Api.activities.admin.index;
      case 'ActivityTemplate':
        return Api.activity_templates.admin.index;
      default:
        return ""
    }
  },

  targetableApiShow: (values) => {
    switch (values.targetable_type) {
      case 'User':
        return Api.users.admin.show;
      case 'Activity':
        return Api.activities.admin.show;
      case 'ActivityTemplate':
        return Api.activity_templates.admin.show;
      default:
        return ""
    }
  },


  defaultValues: (props) => {
    return {
      id: props.id,
      message: "",
      assigned_id: '',
      event_status: 'opened',
      event_type: 'general',
      afterSave: props.afterSave,
    };
  },
};

export const ServiceEventForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...Functions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      Functions.create(values, formikProps)
        .then((response) => {
          // formikProps.props.afterSave(response);
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      Functions.update(values, formikProps)
        .then((response) => {
          formikProps.props.afterSave(response);
        })
        .then(() => {
          formikProps.setTouched({});
        });
    }
  },
})(Form);
