import Danger from "components/Typography/Danger";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import { I18n } from "PNH/components/I18n";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";

export const  RowFunction = (row, formik) => {
    const { classes, values, setFieldValue } = formik;
    return [
      row.id,
      row.name,
      <Button
        color="success"
        simple
        link
        href={`/competition-groups/${row.id}`}
        key={`edit_${row.id}`}
        className={classes.actionButton}
      >
        <Edit className={classes.icon} />
      </Button>,
    ];
} 