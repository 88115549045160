import React from "react";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Pole Wymagane"),
  description: Yup.string().required("Pole Wymagane"),
  creator_id: Yup.number().required("Pole Wymagane"),
});

export default ValidationSchema;
