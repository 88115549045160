import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Api } from "PNH/services/Api";
/*eslint-disable*/
// nodejs library to set properties for components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Mail from "@material-ui/icons/Mail";
import Slide from "@material-ui/core/Slide";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ResetPasswordModal from "./ResetPasswordModal";
import {FBButton} from "./FBLogin"

import javascriptStyles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModal: true,
      resetPasswordModal: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose = () => {
    this.setState({ loginModal: false, resetPasswordModal: false });
  };
  handleClosePopover(state) {
    this.setState({
      [state]: false,
    });
  }
  handleClickButton(state) {
    this.setState({
      [state]: true,
    });
  }
  form() {
    const { classes, buttonClasses } = this.props;
    return (
      <GridItem xs={12} sm={6} md={6} lg={6}>
        {/* BUTTON LOGIN MODAL */}
        <Button
          id="login-button"
          classes={buttonClasses}
          color="transparent"
          onClick={() => this.handleClickOpen("loginModal")}
        >
          <AccountCircle /> Logowanie
        </Button>
        {/* LOGIN MODAL START */}
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalLogin,
          }}
          open={this.state.loginModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          {!this.state.resetPasswordModal && this.formik()}
          {this.state.resetPasswordModal && (
            <ResetPasswordModal
              handleClose={this.handleClose}
              {...this.props}
            />
          )}
        </Dialog>
        {/* LOGIN MODAL END */}
      </GridItem>
    );
  }

  formik = () => {
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values) => {
          Api.users.login({ user: values }).then((response) => {
            if (response.ok) {
              localStorage.setItem('user', JSON.stringify(response.data));
              localStorage.setItem('token', response.headers.authorization);
              window.location.reload()
            } else {
              window.setNotifications([
                {
                  type: "alert",
                  msg: "Niepoprawne hasło lub e-mail, spróbuj ponownie.",
                },
              ]);
            }
          });
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Błędny adres email")
            .required("Pole wymagane"),
          password: Yup.string().required("Pole wymagane"),
        })}
      >
        {(formik) => {
          return (
            <Card plain className={classes.modalLoginCard}>
              <DialogTitle
                id="login-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              ></DialogTitle>
              <DialogContent
                id="login-modal-slide-description"
                className={classes.modalBody}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => this.handleClose()}
                  style={{ position: "absolute", top: "5px", right: "15px" }}
                >
                  {" "}
                  <Close
                    className={classes.modalClose}
                    style={{ color: "black" }}
                  />
                </Button>

                <form>
                  <div className={classes.textCenter}>
                    <h4 style={{ marginTop: "15px" }}>Zaloguj się</h4>
                    <FBButton />

                    <h4 style={{ marginTop: "15px" }}>lub</h4>
                  </div>

                  <CardBody className={classes.cardLoginBody}>
                    <CustomInput
                      id="login-modal-email"
                      labelText="Email"
                      noErrorIcon
                      error={!!formik.errors["email"]}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: formik.handleChange,
                        name: "email",
                        value: formik.values.email,
                        endAdornment: (
                          <InputAdornment position="start">
                            <Mail className={classes.icon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      id="login-modal-pass"
                      labelText="Hasło"
                      noErrorIcon
                      error={!!formik.errors["password"]}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        name: "password",
                        value: formik.values.password,
                        onChange: formik.handleChange,
                        endAdornment: (
                          <InputAdornment position="start">
                            <Icon>lock_outline</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>
                </form>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.justifyContentCenter
                }
              >
                <GridContainer justify="center">
                  <GridItem xs={12} className={classes.textCenter}>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={formik.submitForm}
                    >
                      Zaloguj
                    </Button>
                  </GridItem>
                  <GridItem xs={12} className={classes.textCenter}>
                    <Button
                      simple
                      color="transparent"
                      onClick={() => {
                        this.setState({ resetPasswordModal: true });
                      }}
                    >
                      Odzyskaj Hasło
                    </Button>
                  </GridItem>
                </GridContainer>
              </DialogActions>
            </Card>
          );
        }}
      </Formik>
    );
  };
  render() {
    return <>{this.form()}</>;
  }
}

export default withStyles(javascriptStyles)(LoginModal);
