import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";
import { ConnectToChannel, Send } from "PNH/services/Ws";
const CHANNEL_NAME = "Admin::PromoCodesChannel"


export const PromoCodesFunctions = {

  receivedMsg: (data, refreshList) => {
    let type = 'alert';
    switch (data.code) {
      case 200:
        type = 'success';
        break;
      case 202:
        type = 'warning';
        break;
      default:
        type = 'alert';
    }

    window.setNotifications([
      { type: type, msg: data.status },
    ]);
  
  },

  connectedToChannel: (data) => { console.log(data) },

  disconnectedToChannel: (data) => { console.log(data) },

  update: (values, formik) => {
    const tmp = PromoCodesFunctions.clean(values)
    PromoCodesFunctions.ws.send({ params: {...tmp, count: tmp.ids.length }})
  },

   clean: (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  },

  defaultValues: (props) => {
    return {
      action: 'bulk_update',
      ids: props.ids || [],
      name: null,
      description: null,
      active: null,
      prefix: null,
      value: null,
      value_type: null,
      code_type: null,
      active_from: null,
      active_to: null,
      unlimited: null,
      only_for_new_players: null,
      limit_of_usage: null,
      users: null,
      sports: null,
      locations: null,
      activities: null,
      afterSave: ()=>{}
    };
  },
};

export const PromoCodesForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...PromoCodesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    PromoCodesFunctions.update(values, formikProps)
  },
})(Form);

