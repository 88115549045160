import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";

export const SportsFunctions = {
  create: (values, formik) => {
    return Api.sports.admin.create({ sport: values }).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Dyscyplina dodana" },
        ]);
        let url = `/sports/${response.data.id}`;
        formik.props.history.push(url)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.sports.admin
      .update(values.id, { sport: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...SportsFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  delete: (formik) => {
    return Api.sports.admin.delete(formik.values.id).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Usunięto" },
        ]);
      
       let url = `/sports`;
        formik.history.push(url)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      id:
        currentUrlParams().urlPath[1] &&  currentUrlParams().urlPath[1] !== "new"
          ?  currentUrlParams().urlPath[1]
          : "",
      name: "",
      description: "",
      hidden: "",
      location_objects_attributes: [],
      sport_picture: null,
      baner_picture: null,

      afterSave: () => {},
    };
  },
};

export const SportsForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...SportsFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      SportsFunctions.create(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      SportsFunctions.update(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    }
  },
})(Form);
