import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button";
import { PromoCodesForm } from "./PromoCodeBulkForm/index.jsx"
import { PromoCodesFunctions } from "./PromoCodeBulkForm/index.jsx";
import {ConnectToChannel, Send, WsManager} from "PNH/services/Ws.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CHANNEL_NAME = "Admin::PromoCodesChannel"

class BulkAction extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      ws: new WsManager()
    }
  }

  componentWillUnmount() {
    this.disconnectFromWs()
  }

  connectToWs = () => {
    this.state.ws.connect(CHANNEL_NAME, {
      connected: PromoCodesFunctions.connectedToChannel,
      disconnected: PromoCodesFunctions.disconnectedToChannel,
      received: (data) => PromoCodesFunctions.receivedMsg(data, () => { this.props.refreshList(); this.setState({ editModal: false }) })
    })
    PromoCodesFunctions.ws = this.state.ws
  }

  disconnectFromWs = () => {
    this.state.ws.unsubscribe()
    this.state.ws.disconnect()
  }

  editModal = (open) => {
    this.setState({ editModal: open });
    if(open) {
      this.connectToWs()
    }
  };

  delete = (values, formik) => {
    this.state.ws.send({ params: { action: 'destroy', ids: this.props.ids, count:  this.props.ids.length } })
  }


  renderDialogEdit = () => {
    const { classes, afterSave, ...props } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.editModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.editModal(false)}
      >
        <DialogTitle
          id="small-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.editModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent
          id="small-modal-slide-description"
          className={classes.modalBody}
        >
          <PromoCodesForm {...this.props} />
        </DialogContent>
      </Dialog>
    );
  };
  render() {
    return <>
      <Button
        color="primary"
        size="sm"
        disabled={this.props.ids.length === 0}
        onClick={() => this.editModal(true)}
      >
        Edytuj zaznaczone
      </Button>
      <Button
        color="danger"
        size="sm"
        disabled={this.props.ids.length === 0}
        onClick={() => {
          if (confirm("Usunąć")) {
            this.delete()
          }
        }}
      >
        Usuń zaznaczone
      </Button>
      {this.state.editModal && this.renderDialogEdit()}
    </>;
  }
}

export default withStyles(modalStyle)(BulkAction);
