import Danger from "components/Typography/Danger";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import { I18n } from "PNH/components/I18n";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";

export const  RowFunction = (row, formik) => {
    const { classes, values, setFieldValue } = formik;
    return [
        <Checkbox
            tabIndex={-1}
            checked={values.activitiesIDs.includes(row.id)}
            onClick={() => {
                let ids = values.activitiesIDs.includes(row.id) ? values.activitiesIDs.filter((v) => (v != row.id)) : [...values.activitiesIDs, row.id];
                setFieldValue("activitiesIDs", ids)
            }}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
                checked: classes.checked,
                root: classes.checkRoot,
            }}
        />,
        row.id,
        <>
            <b> {row.date}</b> <br /> {row.start_at}
            {"-"}
            {row.stop_at}
        </>,

        row.sport.name,
        <>
            {row.localization.name} <br /> {row.localization.street}{" "}
            {row.localization.city}
        </>,
        <>
            {row.all_reserved_spots}/{row.max_players}({row.min_players})
            {row.fa_count > 0 && (
                <Danger style={{ fontWeight: 900 }}>
                    fa: {row.fa_count}
                </Danger>
            )}
        </>,
        row.canceled ? "Tak" : "Nie",
        row.hidden ? "Tak" : "Nie",
        I18n.t(row.activity_type),
        row.confirmed ? "Tak" : "Nie",
        <>
            {row.all_reserved_spots < row.min_players && (
                <Danger>
                    Brak wymaganej ilości graczy <br />
                </Danger>
            )}
            {row.coordinators.length === 0 && (
                <Danger>
                    Brak koordynatora <br />
                </Danger>
            )}
            {row.coordinators.length > 0 && <p>Koordynator:</p>}
            {row.coordinators.map((r) => {
                return (
                    <p>
                        {r.first_name} {r.last_name}
                    </p>
                );
            })}
            <b>
                Dodał: {row.creator.first_name} {row.creator.last_name}
            </b>
            <br />
        </>,

        <Button
            color="success"
            simple
            link
            href={`/activities/${row.id}`}
            key={`edit_${row.id}`}
            className={classes.actionButton}
        >
            <Edit className={classes.icon} />
        </Button>,
    ];
} 