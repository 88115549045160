import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Api} from "../../../../services/Api";
import {I18n} from "../../../../components/I18n";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const TypeForm = ({row, classes}) => {
    const [type, setType] = useState(row.report_type)


    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Typ
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={type}
            disabled={row.confirmed}
            onChange={(e) => {
                const value = e.target.value
                setType(value);
                Api.benefit_entries.admin.update(row.id, {report_type: value}).then((r)=>{
                    if(r.ok) {
                        window.setNotifications([
                            {
                                type: "success",
                                msg: "Zapisano",
                            },
                        ]);
                    } else {
                        window.setNotifications([
                            {
                                type: "alert",
                                msg: "Nie zapisano",
                            },
                        ]);
                    }
                })
            }}
            inputProps={{
                name: "report_type",
            }}
        >
            { ['terminal', 'paper', 'system'].map((v) => {
                return  <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={v}
                >
                    {I18n.t(`be_${v}`)}
                </MenuItem>
                })}

        </Select>
    </FormControl>

}