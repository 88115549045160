import React, {useState} from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {I18n} from "PNH/components/I18n";
import EditIcon from '@material-ui/icons/Edit';
import Datetime from "react-datetime";
import moment from "moment/moment";


const styles = {
    ...formRegular,
    ...stylesExtended,
};




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const RenderDialog = ({
                          values,
                          errors,
                          handleSubmit,
                          setFieldValue,
                          handleChange,
                          setOpenModal,
                          openModal,
                          classes,
                          ...props
                      }) => {

    return <Dialog
        maxWidth="sm"
        fullWidth
        classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
        }}
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenModal(false)}
    >
        <DialogContent
            id="small-modal-slide-description"
            className={classes.modalBody}
        >
            <GridContainer>
                <GridItem xs={12} >
                    <h3>Dodaj odbicie MultiSport</h3>
                </GridItem>
                <GridItem xs={12} >
                    <>
                        <InputLabel error={!!errors.date_played} className={classes.label}>
                            Czas
                        </InputLabel>
                        <br />
                        <FormControl fullWidth style={{ zIndex: 20 }}>
                            <Datetime
                                utc
                                locale={moment.locale('pl')}
                                value={values.date_played}
                                dateFormat={"YYYY-MM-DD"}
                                timeFormat={"HH:MM"}
                                onChange={(value) => setFieldValue("date_played", value)}
                                inputProps={{ name: "date_played", autocomplete: "off" }}
                            />
                        </FormControl>
                    </>
                </GridItem>
                <GridItem xs={12} >
                    <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                        >
                            Raport
                        </InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu,
                            }}
                            classes={{
                                select: classes.select,
                            }}
                            value={values.report_type}
                            onChange={handleChange}
                            inputProps={{
                                name: "report_type",
                            }}
                        >
                            {["paper", "system"].map((g) => (
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value={g}
                                >
                                    {I18n.t(`be_${g}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Benefit login"
                        error={errors.notorganizer_logines}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            multiline: true,
                            value: values.organizer_login,
                            name: "organizer_login",
                            onChange: handleChange,
                        }}
                    />
                </GridItem>

                <GridItem xs={12}>
                    <CustomInput
                        labelText="Notatki"
                        error={errors.notes}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            multiline: true,
                            value: values.notes,
                            name: "notes",
                            onChange: handleChange,
                        }}
                    />
                </GridItem>

                <GridItem xs={12} style={{textAlign: 'right'}}>
                    <hr/>
                    <Button color="primary" onClick={handleSubmit}>Dodaj</Button>
                </GridItem>
            </GridContainer>
        </DialogContent>
    </Dialog>
}

const Form = ({values, icons = null, ...props}) => {
    const [openModal, setOpenModal] = useState(false)

    const toggleModal = (toggle) => {
        setOpenModal(toggle)
    }

    return <>
        {openModal && <RenderDialog key={`dialog-${values.id}`} values={values} openModal={openModal}
                                    setOpenModal={toggleModal} {...props} />}
        <Button color="primary" size='sm' onClick={() => {
            toggleModal(true)
        }}> {values.id ? <> {icons} <EditIcon/> </> : "Dodaj odbicie"}</Button>
    </>
}

export default withStyles(styles)(withStyles(modalStyle)(Form));
