import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {I18n} from "../../../../components/I18n";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    toggleModal = (open) => {
        this.setState({modal: open});
    };


    form() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
        } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <GridContainer>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Nazwa"
                            error={errors.name}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: values.name,
                                name: "name",
                                onChange: handleChange,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                Płeć
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values.gender}
                                onChange={handleChange}
                                inputProps={{
                                    name: "gender",
                                }}
                            >
                                {["males", "females", "unisex"].map((g) => (
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected,
                                        }}
                                        value={g}
                                    >
                                        {I18n.t(g)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                </GridContainer>
                <GridContainer justify="space-between">
                    <GridItem xs={12} sm style={{textAlign: "right"}}>
                        <Button type="submit" color="success">
                            Zapisz
                        </Button>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }

    render() {
        const {
            classes,
            values,
        } = this.props;
        return <>
            <Dialog
                maxWidth={'lg'}
                fullWidth={true}
                open={this.state.modal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.toggleModal(false)}

            >
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => this.toggleModal(false)}
                    >
                        <Close className={classes.modalClose}/>
                    </Button>
                    {values.id && <h4>Edytuj drużunę</h4>}
                    {!values.id && <h4>Dodaj drużynę</h4>}
                </DialogTitle>
                <DialogContent

                    className={classes.modalBody}
                >
                    {this.form()}
                </DialogContent>
            </Dialog>


            {values.id && <Button color={"primary"}  simple={true} onClick={() => this.toggleModal(true)}><EditIcon /></Button>}
            {!values.id && <Button  color={"primary"} size={"sm"}   onClick={() => this.toggleModal(true)}>Dodaj</Button>}
        </>

    }
}

export default withStyles(modalStyle)(Form);
