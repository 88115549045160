import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { BalanceForm } from "./BalanceForm/index";
import { BonusForm } from "./BonusForm/index";
import { DepositForm } from "./DepositForm/index";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { currentUrlParams } from "PNH/services/Helpers";
import { BonusDateForm } from "../BonusDateForm";
class UserBalance extends React.Component {
  state = {
    user: {},
  };
  constructor(props) {
    super(props);
    this.fetch();
  }
  fetch = () => {
    Api.users.admin
      .show(currentUrlParams().urlPath[1])
      .then((response) => {
        if (response.ok) {
          this.setState({ user: response.data });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <h3>Bonus</h3>
          </GridItem>
          <GridItem xs={12}>
            <hr/>
          </GridItem>
          
          <GridItem xs={12}>
            <BonusDateForm />
          </GridItem>
          <GridItem xs={12}>
            <h3>Doładowanie konta</h3>
          </GridItem>
          <GridItem xs={12}>
            <hr/>
          </GridItem>
          <GridItem xs={4}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AccountBalanceWalletIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Konto Główne</h4>
              </CardHeader>
              <CardBody>
                <BalanceForm fetch={this.fetch} {...this.props} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={4}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AccountBalanceWalletIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Konto Bonusowe</h4>
              </CardHeader>
              <CardBody>
                <BonusForm fetch={this.fetch} {...this.props} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={4}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AccountBalanceWalletIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Kaucja</h4>
              </CardHeader>
              <CardBody>
                <DepositForm fetch={this.fetch} {...this.props} />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default UserBalance;
