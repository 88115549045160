import React, { useState, useCallback, useEffect } from 'react';

// The editor core
import Editor from '@react-page/editor';

// import the main css, uncomment this: (this is commented in the example because of https://github.com/vercel/next.js/issues/19717)
import '@react-page/editor/lib/index.css';

// The rich text area plugin
import slate from '@react-page/plugins-slate';
// image
import image from '@react-page/plugins-image';
import html5_video from '@react-page/plugins-html5-video'
import spacer from '@react-page/plugins-spacer'
import divider from '@react-page/plugins-divider'
import background, { ModeEnum } from "@react-page/plugins-background";
import { Api } from "PNH/services/Api";
import Button from "components/CustomButtons/Button";
// Define which plugins we want to use.
const cellPlugins = [slate(), image, html5_video, divider, spacer, background({
  enabledModes:
    ModeEnum.COLOR_MODE_FLAG |
    ModeEnum.IMAGE_MODE_FLAG |
    ModeEnum.GRADIENT_MODE_FLAG,
})];

export default function PageEditor({ id, ...props }) {
  const [value, setValue] =  useState(null);
  const [fetch, setFetchValue] = useState(true);
  useEffect(() => {
    Api.pages.admin.show(id).then((response) => {
      if (response.ok) {
        setValue(JSON.parse(response.data.body));
        setFetchValue(false);
      }
    });
  }, [id]);

  const update = useCallback(() => {
    return Api.pages.admin
      .update(id, {
        page: { body: JSON.stringify(value) },
      })
      .then((response) => {
        if (response.ok) {
          window.setNotifications([{ type: "success", msg: "Zapisano stronę" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
  }, [value]);

  return (
    <>
      {!fetch && (
        <Editor cellPlugins={cellPlugins} value={value} onChange={setValue} /> 
      )}
      <toolbar>
        <Button color="success" onClick={update}>
          Zapisz
        </Button>
      </toolbar>
    </>
  );
}
