import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import {FiltersForm} from "./Search";
import PromoCodes from "./cards/promo_codes";
import Core3 from "./cards/core_3";
import Core_2 from "./cards/core_2";
import Core_1 from "./cards/core_1";
import BenefitImporter from "./cards/benefit_importer";
import NewRegisters from "./cards/new_registers";
import BenefitEntries from "./cards/benefit_entries";
import ActivitiesSummary from "./cards/activities_summary";
import Accordion from "components/Accordion/Accordion";
import BenefitUnassignedEntries from "./cards/benefit_unassigned_entries";
import MedicoverEntries from "./cards/medicover_entries";
import BenefitParticipantsWithoutEntry from "./cards/benefit_participants_without_entry";


export const ReportsPage = ({...props}) => {
    return <CustomTabs
        headerColor={"primary"}
        tabs={[{tabName: "Generuj nowy raport", tabIcon: "", tabContent: <NewReportsTab {...props}/>},
            {tabName: "Wygenerowane raporty", tabIcon: "", tabContent: <ReportsTab {...props}/>}
        ]}
    />
}

const NewReportsTab = ({...props}) => {
    return <Accordion
        collapses={[
            {
                title: "Rozliczenia",
                content: <GridContainer>
                    <GridItem xs={4}>
                        <Core3 {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <Core_2 {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <Core_1 {...props}/>
                    </GridItem>
                </GridContainer>
            },

            {
                title: "Benefit",
                content: <GridContainer>
                    <GridItem xs={4}>
                        <BenefitEntries {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <BenefitUnassignedEntries {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <BenefitParticipantsWithoutEntry {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <BenefitImporter {...props}/>
                    </GridItem>
                </GridContainer>
            },

            {
                title: "Podsumowania",
                content: <GridContainer>
                    <GridItem xs={4}>
                        <PromoCodes {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <NewRegisters {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <ActivitiesSummary {...props}/>
                    </GridItem>
                    <GridItem xs={4}>
                        <MedicoverEntries {...props}/>
                    </GridItem>
                </GridContainer>
            }
        ]}
    />

}

const ReportsTab = ({...props}) => {
    return <FiltersForm {...props} />
}
