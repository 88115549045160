import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React from "react";

import { ActivitiesForm } from "./ActivitiesFrom/index";

class FormWrapper extends React.Component {
  state = {
    form: true,
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.activityID !== this.props.activityID ||
      (!this.props.activityID && prevProps.defaults !== this.props.defaults)
    ) {
      this.setState({ form: false }, () => this.setState({ form: true }));
    }
  }
  render() {
    return this.state.form && <ActivitiesForm {...this.props} />;
  }
}

FormWrapper.propTypes = {};

export default FormWrapper;
