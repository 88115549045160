import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  localization_id: Yup.number(),
  location_object_id: Yup.number(),
  sport_id: Yup.number(),

  publish_date: Yup.date(),
  start_at: Yup.string(),
  stop_at: Yup.string()
    .test("is-greater", "end time should be greater", function(value) {
      const { start_at } = this.parent;
      if (!start_at && !value) {
        return true;
      }
      if (!start_at && value) {
        return false;
      }
      const start_at_time =
        start_at.length < 6
          ? moment.utc({
              h: start_at.split(":")[0],
              m: start_at.split(":")[1],
            })
          : moment.utc({
              h: moment.utc(start_at).hours(),
              m: moment.utc(start_at).minutes(),
            });
      const stop_at_time =
        value.length < 6
          ? moment.utc({ h: value.split(":")[0], m: value.split(":")[1] })
          : moment.utc({
              h: moment.utc(value).hours(),
              m: moment.utc(value).minutes(),
            });
      return moment(stop_at_time).isAfter(moment(start_at_time));
    }),
  description: Yup.string(),
  reserved_spots: Yup.number()
    .min(0, "Minimalna wartość 0"),
  max_players: Yup.number()
    .min(Yup.ref("min_players"), "Wartość większa od minimalnej"),

  min_players: Yup.number()
    .min(1, "Minimalna wartość 1"),
  price: Yup.number()
    .min(0, "Minimalna wartość 0"),
  date: Yup.date().when(["id"], {
    is: (id) => id !== undefined,
    then: Yup.date().required("Pole Wymagane"),
    otherwise: Yup.date(),
  }),



});

export default ValidationSchema;
