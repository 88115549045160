import { withFormik } from "formik";
import validationSchema from "../Search/ValidationSchema";
import ActivitiesTable from "PNH/components/Filters/Table"
import { Api, serializeParams } from "PNH/services/Api";
import { withRouter } from 'react-router-dom';
import { RowFunction } from "../Search/RowFunction";
import { TableFilters } from "../Search/Filters";

export const SearchFunctions = {
  index: (values, formik) => {
    return Api.users.admin
      .index({ ...values, ...{ data: [], search: true, with_parent_id: formik.parent_id } })
      .then((response) => {
        if (response.ok) {
          formik.setFieldValue("data", response.data);
          formik.setFieldValue("total", response.data.total);
        }
      });
  },

  getData: async (formik) => {
    SearchFunctions.index(formik.values, formik);
  },

  changePage: async (page, formik) => {
    let pageChange = await formik.setFieldValue('page', page)
    SearchFunctions.index({ ...formik.values, ...{ page: page } }, formik);
  },

  mapRows: (row, formik) => {
    return RowFunction(row, formik)
  },

  clearFilters: () => {
    window.location.href = "/users";
  },

  onChangeRowsPerPage: async (e, formik) => {
    let limit = await formik.setFieldValue('limit', e.target.value);
    let page = await formik.setFieldValue('page', 0)
    SearchFunctions.getData(formik)
  },

  handleAddButton: (formik) => {
    let url = "/users/new";
    formik.history.push(url)
  },

  bulkUpdateComponent: (formik) => {
    return null
  },

  filtersComponent: (formik) => {
    return TableFilters(formik)
  },

  tableHeaders: () => [
    "ID",
    "Imię",
    "Nazwisko",
    "Email",
    "Telefon",
    "Multisport",
    "Aktywne",
    "Data Rejestracji",
    "Logowanie",
    "",
  ],

  showAddButton: () => true,
  showClearButton: () => true,

  orderBy: () => [
    "users.created_at asc",
    "users.created_at desc",
    "users.first_name asc",
    "users.first_name desc",
    "users.last_name asc",
    "users.last_name desc",
    "users.email asc",
    "users.email desc",
    "users.id asc",
    "users.id desc"
  ],

  defaultValues: (props) => {
    const urlParams = new URLSearchParams(props.location.search);
    return {
      page: parseInt(urlParams.get("page")) || 0,
      limit: 25,
      data: { rows: [] },
      activitiesIDs: [],
      total: 0,
      with_id: urlParams.get("with_id") || "",
      with_created_at_greater: urlParams.get("with_created_at_greater") || "",
      with_created_at_less: urlParams.get("with_created_at_less") || "",
      with_first_name: urlParams.get("with_first_name") || "",
      with_last_name: urlParams.get("with_last_name") || "",
      with_email: urlParams.get("with_email") || "",
      with_phone: urlParams.get("with_phone") || "",
      with_benefit_id: urlParams.get("with_benefit_id") || "",
      with_tags: urlParams.get("with_tags") || "",
      order: 'users.created_at desc',
      afterSave: props.afterSave,
    };
  },
};

export const ChildrenForm = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...SearchFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    const tmp = { ...values, ...{ page: 0 } }
    formikProps.setSubmitting(true);

    SearchFunctions.index(tmp, formikProps)
      .then((response) => { })
      .then(() => {
        formikProps.setFieldValue("page", 0);
        formikProps.setTouched({});
      });
  },
})(({ ...props }) => <ActivitiesTable SearchFunctions={SearchFunctions} {...props} />));
