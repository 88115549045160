import React from "react";
import { withRouter } from "react-router-dom";
import ListAltIcon from '@material-ui/icons/ListAlt';
import { FiltersForm } from "./Search";
import { ActivitiesForm } from "./ActivitiesFrom/index";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import { currentUrlParams } from 'PNH/services/Helpers'

class ActivityTemplates extends React.Component {
  render() {
    const url = currentUrlParams().urlPath;

    if (!url[1]) {
      return (
        <FiltersForm {...this.props} />
      );
    } else if (url[1] === "new") {
      return (
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <ListAltIcon />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ActivitiesForm {...this.props} />
          </CardBody>
        </Card>
      );
    } else if (url[1]) {
      return (
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <ListAltIcon />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ActivitiesForm activityID={url[1]} {...this.props} />
          </CardBody>
        </Card>
      );
    }
  }
}

ActivityTemplates.propTypes = {};

export default withRouter(ActivityTemplates);
