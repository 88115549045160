import React, {useEffect, useState} from "react";
import {Api} from "../../../services/Api";
import GridItem from "../../../../components/Grid/GridItem";
import {TeamsForm} from "./Form";
import Accordion from "components/Accordion/Accordion";
import {I18n} from "../../../components/I18n";
import {UsersForm} from "./Form/Players";
import CardBody from "../../../../components/Card/CardBody";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import {ActivitiesFilters} from "../ActivitiesSearch";
import GridContainer from "../../../../components/Grid/GridContainer";
export const TeamsTab = ({competition_group_id}) => {
    const [teams, setTeams] = useState([])

    const fetchTeams = ()=>{
        if (competition_group_id) {
            Api.competition_groups.admin.show(competition_group_id).then((response) => {
                if (response.ok) {
                    const tmp = response.data.teams.map((team)=> {
                        return  {
                            title: <>{team.name} - {I18n.t(team.gender)}  <TeamsForm competition_group_id={competition_group_id} id={team.id} name={team.name} gender={team.gender} afterSave={fetchTeams}/></>,
                            content:

                            <CustomTabs
                                headerColor="primary"
                                tabs={[
                                    {
                                        tabName: <>Zajęcia</>,
                                        tabIcon: SportsBasketballIcon,
                                        tabContent: <ActivitiesFilters context={"with_competition_team_id"} context_id={team.id}/>
                                    },
                                    {
                                        tabName: <>Skład</>,
                                        tabIcon: SportsBasketballIcon,
                                        tabContent: <UsersForm  competition_group_id={competition_group_id} team_id={team.id} rows={team.users} gender={team.gender} afterSave={()=>{}}/>
                                    },
                                    {
                                        tabName: <>Wyniki</>,
                                        tabIcon: SportsBasketballIcon,
                                        tabContent: <></>
                                    },]}/>

                        }
                    })
                    setTeams(tmp)
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    }

    useEffect(fetchTeams, [])

    return <GridItem>
        <GridContainer justify="flex-end">
            <GridItem> <TeamsForm competition_group_id={competition_group_id} afterSave={fetchTeams}/></GridItem>
        </GridContainer>

        <Accordion
            active={[]}
            activeColor="rose"
            collapses={teams}

        />
    </GridItem>
}