import React from "react";
import { Api } from "PNH/services/Api";

import GridItem from "components/Grid/GridItem";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";

import Badge from "components/Badge/Badge";
import Close from "@material-ui/icons/Close";

import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
  };

class UsersSelector extends React.Component {
  render() {
    const { classes, values, setFieldValue } = this.props;
   
    return (
      <>
        
          <GridItem xs={6}>
            <AsyncSelector
              apiMethod={(q) =>
                Api.users.admin.index(q)
              }
              labelKey="label"
              label="Użytkownik"
              name="user"
              onChange={(value, data) => {
                setFieldValue("user_tmp", data);
                setFieldValue("user_tmp_id", value);
              }}
            />
          </GridItem>
          <GridItem xs={2}>
            <Button
              size="sm"
              color="primary"
              disabled={!values.user_tmp_id}
              onClick={async (e) => {
                if(values.users === null){
                  const users = []
                  users.push(values.user_tmp)
                  setFieldValue("users", users);
                } else {
                  const users = values.users
                  users.push(values.user_tmp)
                  setFieldValue("users", users);
                }
              }}
            >
              Dodaj
            </Button>
          </GridItem>
          <GridItem xs={12}>
            {values.users && values.users.map((user) => {
              return <Badge color="info" key={user.label} style={{marginLeft: 10}}>
                {user.label}
                <Button simple size="sm" onClick={() => {
                  let users =  values.users.filter((v) => (v.id != user.id))
                  setFieldValue("users", users);
                }}  color='danger'><Close/></Button>
              </Badge>
            })}
          </GridItem>
      </>
    );
  }
}

export default withStyles(styles)(UsersSelector);
