import { withFormik } from "formik";
import { withRouter } from 'react-router-dom'
import moment from "moment";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";


export const UsersFunctions = {
  create: (values, formik) => {
    return Api.users.admin.create({ user: values }).then((response) => {
      if (response.ok) {
        let url= `/users/${response.data.id}?notification_msg=Uytkownik%20dodany&notification_type=success`;
        formik.props.history.push(url)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },
  update: (values, formik) => {
    return Api.users.admin
      .update(values.id, { user: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...UsersFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },

  defaultValues: (props) => {
    return {
      id:
      currentUrlParams().urlPath[1] && currentUrlParams().urlPath[1] !== "new"
          ?  currentUrlParams().urlPath[1]
          : "",
      identifier: "",
      hide_last_name: false,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      phone: "",
      birth_date: "",
      city: "",
      gender: "",
      confirmed_phone: false,
      edit_password: false,
      child_policy_accepted: false,
      parent_id: "",
      parent: {},
      afterSave: () => {},
    };
  },
};

export const UsersForm = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      UsersFunctions.create(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      UsersFunctions.update(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    }
  },
})(Form));
