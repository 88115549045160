import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const Functions = {
  create: (values, formik) => {
    return formik.props.addCommentApi(values.id, { event_comment: values }).then((response) => {
      if (response.ok) {
        window.setNotifications([
          { type: "success", msg: "Dodano" },
        ]);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się zapisać zmian" },
        ]);
      }
    });
  },


  defaultValues: (props) => {
    return {
      id: props.id,
      comment: "",
      comments: props.comments || [],
      afterSave: () => { },
    };
  },
};

export const CommentsForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...Functions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
   
    Functions.create(values, formikProps)
      .then((response) => {
        formikProps.props.afterSave(response);
      })
      .then(() => {
        formikProps.setTouched({});
      });
    
  },
})(Form);
