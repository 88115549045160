import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ImageUpload from "PNH/components/CustomUpload/ImageUpload";
import { LocationsFunctions } from "./index";
import { I18n } from "PNH/components/I18n";
import Accordion from "components/Accordion/Accordion";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.locations.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...LocationsFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer justify="flex-end">
          <GridItem xs={4}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall,
              }}
              open={this.state.deleteModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.deleteModal(false)}
              aria-labelledby="small-modal-slide-title"
              aria-describedby="small-modal-slide-description"
            >
              <DialogTitle
                id="small-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.deleteModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
              </DialogTitle>
              <DialogContent
                id="small-modal-slide-description"
                className={classes.modalBody + " " + classes.modalSmallBody}
              >
                <h5>Na pewno chcesz usunąć lokalizację?</h5>
              </DialogContent>
              <DialogActions
                className={
                  classes.modalFooter + " " + classes.modalFooterCenter
                }
              >
                <Button
                  onClick={() => this.deleteModal(false)}
                  color="transparent"
                  className={classes.modalSmallFooterFirstButton}
                >
                  Nie
                </Button>
                <Button
                  onClick={() => LocationsFunctions.delete(this.props)}
                  color="success"
                  simple
                  className={
                    classes.modalSmallFooterFirstButton +
                    " " +
                    classes.modalSmallFooterSecondButton
                  }
                >
                  Tak
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <h4>
              <b>Lokalizacja</b>
            </h4>
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridItem xs={12}>
              <CustomInput
                labelText="Nazwa"
                error={errors.name}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.name,
                  name: "name",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Ulica"
                error={errors.street}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.street,
                  name: "street",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Miasto"
                error={errors.city}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.city,
                  name: "city",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Dzielnica"
                error={errors.district}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.district,
                  name: "district",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Kod pocztowy"
                error={errors.post_code}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.post_code,
                  name: "post_code",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                style={{ marginTop: "20px" }}
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={values.hidden}
                    onClick={() => setFieldValue("hidden", !values.hidden)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="Ukryj"
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Szerokość geo."
                error={errors.lat}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.lat,
                  name: "lat",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Długość geo."
                error={errors.lang}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.lang,
                  name: "lang",
                  onChange: handleChange,
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={3}>
              <CustomInput
                labelText="Kolejność w wyszukiwarce"
                error={errors.order_value}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: values.order_value,
                  name: "order_value",
                  onChange: handleChange,
                }}
              />
            </GridItem>
          <GridItem xs={12} md={6}>
            <GridItem xs={12}>
              <CustomInput
                  labelText="Benefit Terminal s/n"
                  error={errors.benefit_terminal_sn}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: values.benefit_terminal_sn,
                    name: "benefit_terminal_sn",
                    onChange: handleChange,
                  }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Opis"
                error={errors.description}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  value: values.description,
                  name: "description",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="Notatki"
                error={errors.notes}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  value: values.notes,
                  name: "notes",
                  onChange: handleChange,
                }}
              />
            </GridItem>
            <GridItem>
              <h6>Zdjęcia</h6>
              <GridContainer justify="space-between">
                {values.photos &&
                  values.photos.map((photo) => {
                    const ref = this;
                    return (
                      <GridItem xs>
                        <img
                          src={`${process.env.REACT_APP_API_URL}${photo.url}`}
                          style={{ maxWidth: 200, padding: 10 }}
                        />
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => {
                            Api.locations.admin
                              .delete_image_attachment(photo.id, values.id)
                              .then(() => {
                                window.location.reload(true);
                              });
                          }}
                        >
                          Usuń
                        </Button>
                        <hr />
                      </GridItem>
                    );
                  })}
              </GridContainer>
              <ImageUpload
                value={values.photo}
                onChange={(file) => {
                  setFieldValue("photo", file);
                }}
              />
            </GridItem>
          </GridItem>
          <GridItem xs={9} style={{ marginTop: 20 }}>
            <h4>
              <b>Obiekty</b>
            </h4>
          </GridItem>
          <GridItem xs={3} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              simple
              size="sm"
              onClick={() => {
                LocationsFunctions.addObject(this.props);
              }}
            >
              + Dodaj obiekt
            </Button>
          </GridItem>

          <GridItem xs={12}>
            <GridContainer>
              {values.location_objects_attributes &&
                values.location_objects_attributes.map(
                  (location_object, index) => {
                    if (location_object._destroy) {
                      return null;
                    }
                    return (
                      <GridItem key={location_object.id + "-" + index} xs={12}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <CustomInput
                              labelText="Opis"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                multiline: true,
                                value: location_object.description,
                                onChange: (e) => {
                                  LocationsFunctions.updateObject(
                                    this.props,
                                    index,
                                    "description",
                                    e.target.value
                                  );
                                },
                              }}
                            />
                          </GridItem>
                          <GridItem xs={6}>
                            <CustomInput
                              labelText="Notatki"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                multiline: true,
                                value: location_object.notes,
                                onChange: (e) => {
                                  LocationsFunctions.updateObject(
                                    this.props,
                                    index,
                                    "notes",
                                    e.target.value
                                  );
                                },
                              }}
                            />
                          </GridItem>
                          <GridItem xs={4}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Rodzaj
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={location_object.object_type || "hall"}
                                onChange={(e) => {
                                  LocationsFunctions.updateObject(
                                    this.props,
                                    index,
                                    "object_type",
                                    e.target.value
                                  );
                                }}
                                inputProps={{
                                  name: "location_type",
                                }}
                              >
                                {[
                                  "hall",
                                  "court",
                                  "stadium",
                                  "gym",
                                  "dance_room",
                                  "baloon",
                                  "other",
                                ].map((type) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={type}
                                  >
                                    {I18n.t(type)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={4}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                              >
                                Weryfikacja
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={location_object.checklist}
                                onChange={(e) => {
                                  LocationsFunctions.updateObject(
                                    this.props,
                                    index,
                                    "checklist",
                                    e.target.value
                                  );
                                }}
                                inputProps={{
                                  name: "checklist",
                                }}
                              >
                                {["terminal", "report"].map((checklist) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={checklist}
                                  >
                                    {I18n.t(checklist)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>

                          <GridItem xs={2}>
                            <FormControlLabel
                              style={{ marginTop: "20px" }}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  checked={location_object.hidden}
                                  onClick={() => {
                                    LocationsFunctions.updateObject(
                                      this.props,
                                      index,
                                      "hidden",
                                      !location_object.hidden
                                    );
                                  }}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                                root: classes.labelRoot,
                              }}
                              label="Ukryj"
                            />
                          </GridItem>
                          {location_object.id && <GridItem xs={12}>
                            <Accordion
                                activeColor="rose"
                                collapses={[
                                  {
                                    title: "Zdjęcia obiektu",
                                    content: <GridContainer justify="space-between">
                                      <GridItem xs>
                                        <ImageUpload
                                            value={location_object.photo}
                                            onChange={(file) => {
                                              LocationsFunctions.updateObject(
                                                  this.props,
                                                  index,
                                                  'photo',
                                                  file
                                              );
                                            }}
                                        />
                                      </GridItem>
                                      {location_object.photos &&
                                          location_object.photos.map((photo) => {
                                            const ref = this;
                                            return (
                                                <GridItem xs>
                                                  <img
                                                      src={`${process.env.REACT_APP_API_URL}${photo.url}`}
                                                      style={{ maxWidth: 100, padding: 10 }}
                                                  />
                                                  <Button
                                                      size="sm"
                                                      color="danger"
                                                      onClick={() => {
                                                        Api.locations.admin
                                                            .delete_image_attachment(photo.id, values.id)
                                                            .then(() => {
                                                              window.location.reload(true);
                                                            });
                                                      }}
                                                  >
                                                    Usuń zdjęcie
                                                  </Button>
                                                  <hr />
                                                </GridItem>
                                            );
                                          })}
                                    </GridContainer>
                                  }
                                ]}

                            />



                          </GridItem> }
                          <GridItem xs={2}>
                            <Button
                              color="danger"
                              onClick={() =>
                                LocationsFunctions.updateObject(
                                  this.props,
                                  index,
                                  "_destroy",
                                  1
                                )
                              }
                            >
                              Usuń obiekt
                            </Button>
                          </GridItem>
                        </GridContainer>

                        <hr />
                      </GridItem>
                    );
                  }
                )}
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm>
            <Button
              color="danger"
              className={classes.marginRight}
              onClick={() => this.deleteModal(true)}
            >
              Usuń lokalizację
            </Button>
          </GridItem>
          <GridItem xs={12} sm style={{ textAlign: "right" }}>
            <Button type="submit" color="success">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
