import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import PeopleIcon from "@material-ui/icons/People";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {currentUrlParams} from "PNH/services/Helpers";
import {CompetitionGroupsForm} from "./Form/index";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import {UsersForm, UsersFunctions} from "./Show/Players";
import Badge from "../../../components/Badge/Badge";
import {I18n} from "../../components/I18n";
import Danger from "../../../components/Typography/Danger";
import {Api} from "../../services/Api";
import {Link} from "react-router-dom";
import Warning from "../../../components/Typography/Warning";
import Primary from "../../../components/Typography/Primary";
import {TeamsTab} from "./Teams";
import {ActivitiesFilters} from "./ActivitiesSearch";

class Profile extends React.Component {
    state = {
        competition_group: {},
    };

    constructor(props) {
        super(props);
        this.fetch();
    }

    fetch = () => {
        const url = currentUrlParams();
        if (url.urlPath[1]) {
            Api.competition_groups.admin.show(url.urlPath[1]).then((response) => {
                if (response.ok) {
                    this.setState({competition_group: response.data})
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    render() {
        const {classes} = this.props;
        const {competition_group} = this.state
        const url = currentUrlParams();
        return (
            <>

                <Primary><h2>{competition_group.name}</h2></Primary>
                <b>ID:#{competition_group.id} - {I18n.t(competition_group.competition_group_type)}{" "} </b>

                {competition_group.notes && <Warning>Notatki: #{competition_group.notes}<br/></Warning>}
                #{competition_group.description}
                <br/>
                {competition_group.hidden && (<>
                        <b>Link:</b> <a href={`${process.env.REACT_APP_MAIN_PAGE_URL}${competition_group.hidden_url}`}
                                        target="_blank">{process.env.REACT_APP_MAIN_PAGE_URL}{competition_group.hidden_url}</a>

                    </>
                )}
                {!competition_group.hidden && (<>
                        <b>Link:</b> <a href={`${process.env.REACT_APP_MAIN_PAGE_URL}${competition_group.url}`}
                                        target="_blank">{process.env.REACT_APP_MAIN_PAGE_URL}{competition_group.url}</a>
                    </>
                )}

                <Card>
                    <CardBody>
                        <GridContainer justify="space-between">
                            <GridItem>
                                <h5>
                                    <b>Ogólne</b>
                                </h5>
                                <hr/>
                                <p>
                                    <b>Rozmiar grupy:</b> {competition_group.users?.length}/{competition_group.max_group_size}
                                    <br/>
                                    <b>Min. ilość graczy w drużynie:</b> {competition_group.min_team_size}
                                    <br/>
                                    <b>Max. ilość graczy w drużynie:</b> {competition_group.max_players_per_team}
                                    <br/>

                                    <b>Ilość drużyn:</b> {competition_group.teams?.length}/{competition_group.max_teams}
                                    <br/>


                                </p>
                            </GridItem>

                            <GridItem>
                                <h5>
                                    <b>Opcje</b>
                                </h5>
                                <hr/>

                                <b>Płeć:</b> {I18n.t(competition_group.gender)}
                                <br/>
                                <b>Ukryta:</b> {competition_group.hidden ? "tak" : "nie"}
                                <br/>
                                <b>Otwarta:</b> {competition_group.open ? "tak" : "nie"}
                                <br/>
                                <b>Aktywna:</b> {competition_group.active ? "tak" : "nie"}
                                <br/>

                            </GridItem>
                            <GridItem md={3}>
                                <h5>
                                    <b>Tagi filtrujące</b>
                                </h5>
                                <hr/>
                                {competition_group.tags &&
                                    competition_group.tags.split("#").map((tag) => {
                                        return (
                                            <Badge color="gray" key={tag}>
                                                {tag}
                                            </Badge>
                                        );
                                    })}
                            </GridItem>
                            <GridItem xs={4}>
                                <Card>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <Link to={`/users/${competition_group.creator_id}`}>
                                                    <GridContainer alignItems="center">
                                                        <GridItem xs={1}>
                                                            <PeopleIcon style={{color: '#1ad1a2'}}/>
                                                        </GridItem>
                                                        <GridItem xs={11} style={{color: 'black'}}>
                                                            <h5><b>{competition_group.creator?.label}</b></h5>
                                                            Dodano: {competition_group?.created_at}
                                                        </GridItem>
                                                    </GridContainer>
                                                </Link>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <PeopleIcon/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Wydarzenia w grupie</h4>
                            </CardHeader>
                            <CardBody>

                                <CustomTabs
                                    headerColor="primary"
                                    tabs={[
                                        {
                                            tabName: <>Zajęcia</>,
                                            tabIcon: SportsBasketballIcon,
                                            tabContent: <ActivitiesFilters context={"with_competition_group_id"} context_id={url.urlPath[1]}/>
                                        },

                                        {
                                            tabName: <>Wyniki</>,
                                            tabIcon: SportsBasketballIcon,
                                            tabContent: <></>
                                        },]}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <PeopleIcon/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Szczegóły</h4>
                            </CardHeader>
                            <CardBody>

                                <CustomTabs

                                    headerColor="primary"
                                    tabs={[
                                        {
                                            tabName: <>Drużyny</>,
                                            tabIcon: SportsBasketballIcon,
                                            tabContent: <TeamsTab competition_group_id={url.urlPath[1]}/>
                                        },

                                        {
                                            tabName: <>Osoby w Grupie</>,
                                            tabIcon: SportsBasketballIcon,
                                            tabContent: <UsersForm id={url.urlPath[1]} {...this.props} />
                                        }, {
                                            tabName: <>Edytuj Grupę</>,
                                            tabIcon: SportsBasketballIcon,
                                            tabContent: <CompetitionGroupsForm id={url.urlPath[1]} {...this.props}
                                                                               afterSave={() => this.fetch()}/>
                                        }, ,]}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        );
    }
}

export default withStyles(dashboardStyle)(Profile);
