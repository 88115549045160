import { withFormik } from "formik";
import { withRouter } from 'react-router-dom'
import moment from "moment";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";
import { currentUrlParams } from "PNH/services/Helpers";


export const UsersFunctions = {
  update: (values, formik) => {
    return Api.users.admin
      .update(values.id, { user: values })
      .then((response) => {
        if (response.ok) {
          formik.setValues({
            ...UsersFunctions.defaultValues(formik.props),
            ...response.data,
          });
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  delete: (formik) => {
    return Api.users.admin.delete(formik.values.id).then((response) => {
      if (response.ok) {
        let url = `/users?notification_msg=Usunięto&notification_type=success`;
        formik.history.push(url)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Nie udało się usunąć konta" },
        ]);
      }
    });
  },

  defaultValues: (props) => {
    return {
      id:
      currentUrlParams().urlPath[1] && currentUrlParams().urlPath[1] !== "new"
          ?  currentUrlParams().urlPath[1]
          : "",
      identifier: "",
      hide_last_name: false,
      confirmed_at: "",
      notes: "",
      how_know_about_us: "",
      fa: false,
      facebook_uid: "",
      afterSave: () => {},
    };
  },
};

export const OptionsForm = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id) {
      UsersFunctions.create(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    } else {
      UsersFunctions.update(values, formikProps)
        .then((response) => {
          values.afterSave(response);
          formikProps.setFieldValue("afterSave", () => {});
        })
        .then(() => {
          formikProps.setTouched({});
        });
    }
  },
})(Form));
