import React from "react";
import Table from "components/Table/Table";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export const CodeTransactionsTable = ({ code, data = [] }) => {
    const tableData = data.map((t, index) => {
        return [
            index +1,
           t.identifier , t.created_at, <>
           <Link to={`/users/${t.user.id}`}>  {t.user.identifier + ', ' + t.user.first_name + ' ' + t.user.last_name } <br/>
            {t.user.email} </Link>
            </>, t.items.map((ti) => {
                if(ti.transaction_item_promo_code && ti.transaction_item_promo_code.code == code) {
                    return  <Link to={`/activities/${ti.targetable.id}`}>{ti.targetable.label}</Link>
                }                   
            })
        ]
    })
    return <>

        <hr />
        <h4>Wykorzystania</h4>
        <Table
            tableHead={[
            <b>#</b>,
            <b>Nr Transakcji</b>,
            <b>Data</b>,
            <b>Użytkownik</b>,
            <b>Zajęcia</b>,
            ]}
            striped
            tableData={tableData}
      />
    </>
}