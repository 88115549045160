import React, { Children } from "react";
import SectionNotifications from "./SectionNotifications";
import { cloneDeep } from "lodash";
import {currentUrlParams} from 'PNH/services/Helpers'

class MainContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: props.notifications,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ notifications: [] });
    }, 4000);
  }

  setNotifications = (notifications) => {
    this.setState({ notifications }, () => {
      setTimeout(() => {
        this.setState({ notifications: [] });
      }, 3500);
    });
  };

  render() {
    const props = cloneDeep(this.props);
    return (
      <>
        <SectionNotifications notifications={this.state.notifications} {...props}/>
        {React.Children.map(this.props.children, (child) => {
          return child;
        })}
      </>
    );
  }
}

export default MainContainer;
