import React from "react";
import {Api} from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";


import {UsersFunctions} from "./index";
import Accordion from "components/Accordion/Accordion";
import {I18n} from "../../../components/I18n";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardBody from "../../../../components/Card/CardBody";
import Card from "../../../../components/Card/Card";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {
    state = {
        deleteModal: false,
    };

    constructor(props) {
        super(props);
        this.fetch();
    }

    deleteModal = (open) => {
        this.setState({deleteModal: open});
    };

    fetch = () => {
        if (this.props.values.id) {
            Api.users.admin.show(this.props.values.id).then((response) => {
                if (response.ok) {
                    this.props.setValues({
                        ...UsersFunctions.defaultValues(this.props),
                        ...response.data,
                    });
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    render() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
        } = this.props;

        const groupLimits = [
            "local_group",
            "organizer",
            "club",
            "tournament",
            "league",
            "b2b",
            "open_event",
            "other"
        ].map((name) => {
            return {
                title: I18n.t(name),
                content: <>{[`l_create_competition_group_${name}`, `l_max_competition_group_${name}_players`, `l_max_competition_group_${name}_max_players_per_team`, `l_max_competition_group_${name}_teams`].map((fieldName) => {
                    return <GridItem xs={12}>
                        <CustomInput
                            labelText={I18n.t(fieldName)}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                placeholder: fieldName,
                                value: values[fieldName],
                                name: fieldName,
                                onChange: handleChange,
                                type: "text",
                                autocomplete: "off",
                            }}
                        />
                    </GridItem>
                })}
                    <GridItem xs={12} md={6}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                            >
                                {I18n.t(`l_can_competition_group_${name}_hidden`)}
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={values[`l_can_competition_group_${name}_hidden`]}
                                onChange={handleChange}
                                inputProps={{
                                    name: `l_can_competition_group_${name}_hidden`,
                                    autocomplete: "off",
                                }}
                            >
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value="true"
                                >
                                    Tak
                                </MenuItem>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected,
                                    }}
                                    value="false"
                                >
                                    Nie
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>

                </>
            }
        })

        const LimitsChildren = () =>  <GridItem xs={12}>
            <CustomInput
                labelText={"Limit kont dziecięcych"}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    placeholder: 'l_child_account_count',
                    value: values.l_child_account_count,
                    name: "l_child_account_count",
                    onChange: handleChange,
                    type: "text",
                    autocomplete: "off",
                }}
            />
        </GridItem>


        const limits = [{
            title: "Grupy",
            content: <Card><CardBody><Accordion collapses={groupLimits}/></CardBody></Card>
        },
            {
                title: "Dzieci",
                content: <LimitsChildren />
            }

        ]


        return (
            <form onSubmit={handleSubmit}>
                <GridContainer>
                    <Accordion
                        collapses={limits}/>

                </GridContainer>
                <GridContainer justify="flex-end">
                    <GridItem xs={3} style={{textAlign: 'right'}}>
                        <Button type="submit" color="primary">
                            Zapisz
                        </Button>
                    </GridItem>
                </GridContainer>
            </form>
        );
    }
}

export default withStyles(styles)(Form);
