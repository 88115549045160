import React from "react";
import Danger from "components/Typography/Danger";
import { I18n } from "PNH/components/I18n";
import { ServiceEventForm } from "../Form/index.jsx";
import { SearchFunctions } from "./index.jsx";
import FiberNewIcon from '@material-ui/icons/FiberNew';
import CommentIcon from '@material-ui/icons/Comment';
import Muted from "components/Typography/Muted.js";

const badgeStyle = {
    zIndex: "4",
    position: "absolute",
    top: "0px",
    border: "1px solid #f44336",
    left: "10px",
    fontSize: "9px",
    background: "#f44336",
    color: "white",
    minWidth: "16px",
    height: "16px",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: "14px",
    verticalAlign: "middle",
    display: "block",
}

export const RowFunction = (row, formik) => {
    const { values } = formik;
    return [
        <>
            {values.only_assigned && !row.read_by_assigned && <Danger style={{ display: 'inline' }}><FiberNewIcon /></Danger>}
            {!values.only_assigned && !row.read_by_admin && <Danger><FiberNewIcon /></Danger>}
        </>,
        row.id ,
        row.message,
        <>{I18n.t(row.targetable_type)}
            <br />
            {row.targetable && row.targetable.label}
        </>,
        I18n.t(row.event_type),
        I18n.t(row.event_status),
        <>
            <ServiceEventForm key={`sem-${row.id}`} icons={row.comments_count > 0 ? <> <CommentIcon /> <span style={badgeStyle}>{row.comments_count}</span></> : <Muted><CommentIcon /></Muted>} afterSave={() => SearchFunctions.getData(formik)} id={row.id} />
        </>,
    ];
} 