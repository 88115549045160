import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { I18n } from "PNH/components/I18n";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { Api } from "PNH/services/Api";
import Datetime from "react-datetime";

export const SettingsSection = ({ handleChange, classes, values, errors, setFieldValue, hideCoordinator = false, hideApprovedByAdmin = false, hideArchived = false }) => {
    return <>
        <GridItem xs={12}>
            <h5>
                <b>Ustawienia</b>
            </h5>
            <hr />
        </GridItem>
        <GridItem xs={12} sm={6}>
            <CustomInput
                labelText="Nr Medicover Sport"
                error={errors.ok_system_code}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    multiline: true,
                    value: values.ok_system_code,
                    name: "ok_system_code",
                    onChange: handleChange,
                }}
            />
        </GridItem>
        <GridItem xs={12} >
            <CustomInput
                labelText="Dodatkowe tagi (#promocja#nowosc)"
                error={errors.custom_tags}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    multiline: true,
                    value: values.custom_tags,
                    name: "custom_tags",
                    onChange: handleChange,
                }}
            />
        </GridItem>
        <GridItem xs={12}>
            <CustomInput
                labelText="Notatki"
                error={errors.notes}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    multiline: true,
                    value: values.notes,
                    name: "notes",
                    onChange: handleChange,
                }}
            />
        </GridItem>
        {!values.id && !hideCoordinator && <GridItem xs={12} md={6}>
            <AsyncSelector
                apiMethod={Api.users.admin.index}
                apiShowMethod={Api.users.admin.show}
                labelKey="label"
                label="Koordynator"
                name="coordinator_id"
                defaultValue={values.coordinator_id}
                value={
                    values.coordinator && {
                        id: values.coordinator.id,
                        label: values.coordinator.label,
                    }
                }
                onChange={(value, data) => {
                    setFieldValue("coordinator", data);
                    setFieldValue("coordinator_id", value);
                }}
            />
        </GridItem>}
        <GridItem xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        tabIndex={-1}
                        checked={values['verify_lv']}
                        onClick={() => {
                            values['verify_lv']
                            setFieldValue('verify_lv', !values['verify_lv'])
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                }}
                label={I18n.t('verify_lv')}
            />
        </GridItem>



        {["coach", "deposit_disabled"].map(
            (p) => (
                <GridItem xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                checked={values[p]}
                                onClick={() => setFieldValue(p, !values[p])}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                        }}
                        label={I18n.t(p)}
                    />
                </GridItem>
            )
        )}

        {hideApprovedByAdmin && <GridItem xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        tabIndex={-1}
                        checked={values['approved_by_admin']}
                        onClick={() => setFieldValue('approved_by_admin', !values['approved_by_admin'])}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                }}
                label={I18n.t('approved_by_admin')}
            />
        </GridItem>}

        {hideArchived && <GridItem xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        tabIndex={-1}
                        checked={values['archived']}
                        onClick={() => setFieldValue('archived', !values['archived'])}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                        }}
                    />
                }
                classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                }}
                label={I18n.t('archived')}
            />
        </GridItem>}
        {hideApprovedByAdmin && <GridItem md={3}>
            <InputLabel error={!!errors.publish_date} className={classes.label}>
                Szablon ważny do
            </InputLabel>
            <br />
            <FormControl fullWidth style={{ zIndex: 10 }}>
                <Datetime
                    utc
                    value={values.expired_at}
                    dateFormat={"YYYY-MM-DD"}
                    timeFormat={false}
                    onChange={(value) => setFieldValue("expired_at", value)}
                    inputProps={{ name: "expired_at", autocomplete: "off" }}
                />
            </FormControl>
        </GridItem>}

    </>
}