import Danger from "components/Typography/Danger";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import { I18n } from "PNH/components/I18n";
import Button from "components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";

export const  RowFunction = (row, formik) => {
    const { classes, values, setFieldValue } = formik;
    return [
        <Checkbox
        tabIndex={-1}
        checked={values.selected.includes(row.id)}
        onClick={() => {
          let ids = values.selected.includes(row.id) ? values.selected.filter((v) => (v != row.id)) : [...values.selected, row.id];
          setFieldValue('selected', ids)
        }}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />,
      row.id,
      row.name,
      row.prefix,
      row.code,
      row.value + row.value_type,
      row.active ? "tak" : "nie",
      row.active_from,
      row.active_to,
      <Button
        color="success"
        simple
        link
        href={`/promo-codes/${row.id}`}
        key={`edit_${row.id}`}
        className={classes.actionButton}
      >
        <Edit className={classes.icon} />
      </Button>,
    ];
} 