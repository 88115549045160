import React from "react";
import { Api } from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import { UsersFunctions } from "./index";
import {FitprofitForm} from "../FitprofitForms";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  state = {
    deleteModal: false,
  };

  constructor(props) {
    super(props);
    this.fetch();
  }
  deleteModal = (open) => {
    this.setState({ deleteModal: open });
  };

  fetch = () => {
    if (this.props.values.id) {
      Api.users.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setValues({
            ...UsersFunctions.defaultValues(this.props),
            ...response.data,
          });
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const {
      classes,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;
    return (

        <GridContainer>
          <GridItem xs={4} style={{borderRight: "1px solid #ddd"}}>
            <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <h3>Benefit system</h3>
                {values.benefit_id && (
                    <>
                      {values.benefit_active ? (
                          <Primary>
                            <h5>
                              <b> Karta Aktywna</b>
                            </h5>
                          </Primary>
                      ) : (
                          <Danger>
                            <h5>
                              <b>Karta nieaktywna</b>
                            </h5>
                          </Danger>
                      )}
                    </>
                )}
              </GridItem>
              <GridItem xs={12} md={12}>
                <CustomInput
                    labelText="Benefit Login"
                    error={errors.benefit_login}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: values.benefit_login,
                      name: "benefit_login",
                      onChange: handleChange,
                      type: "benefit_login",
                      autocomplete: "off",
                    }}
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <CustomInput
                    labelText="Benefit ID"
                    error={errors.benefit_id}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: values.benefit_id,
                      name: "benefit_id",
                      onChange: handleChange,
                      type: "benefit_id",
                      autocomplete: "off",
                    }}
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <CustomInput
                    labelText="Pierwsza Linia Na Karcie"
                    error={errors.first_line}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: values.first_line,
                      name: "first_line",
                      onChange: handleChange,
                      type: "first_line",
                      autocomplete: "off",
                    }}
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <CustomInput
                    labelText="Druga Linia Na Karcie"
                    error={errors.second_line}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: values.second_line,
                      name: "second_line",
                      onChange: handleChange,
                      type: "second_line",
                      autocomplete: "off",
                    }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem xs={12} style={{ textAlign: 'right' }}>
                <Button type="submit" color="primary">
                  Zapisz
                </Button>
              </GridItem>
            </GridContainer>
            </form>
          </GridItem>

          <GridItem xs={4} style={{borderRight: "1px solid #ddd"}}>
            <h3>Medicover Sport</h3>
            {values.confirmed_phone ?
              (<>
                <h6>Konto przypisane do nr {values.phone} jest&nbsp;
                  {!values.ok_system_active && <Danger><strong>nieaktywne</strong></Danger>}
                  {values.ok_system_active && <Primary><strong>aktywne</strong></Primary>}
                </h6>
              </>) :
              (<>
                <Danger>Nr telefonu nie potwierdzony.</Danger>
              </>)}
          </GridItem>
          <GridItem xs={4}>
            <FitprofitForm
                classes={classes} {...this.props}/>
          </GridItem>
        </GridContainer>
    );
  }
}

export default withStyles(styles)(Form);
