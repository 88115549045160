import React from "react";
import {FiltersForm} from "./Search";



export const BenefitEntriesPage = ({...props}) => {
    return <FiltersForm {...props} />
}


