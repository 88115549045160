import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";


export const FreespotsSection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12} md={4}>
            <CustomInput
              labelText={values.competition_type == 'activity' ? "Min. ilość graczy" : "Min. ilość drużyn"}
              error={errors.min_players}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.min_players,
                name: "min_players",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <CustomInput
              labelText={values.competition_type == 'activity' ? "Ilość miejsc" : "Ilość drużyn"}
              error={errors.max_players}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.max_players,
                name: "max_players",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <CustomInput
              labelText="Zarezerwowane miejsca"
              error={errors.reserved_spots}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.reserved_spots,
                name: "reserved_spots",
                onChange: handleChange,
              }}
            />
          </GridItem>

        { values.competition_type == 'sparing' &&  <GridItem xs={12} md={4}>
            <CustomInput
                labelText="Min. rozmiar drużyny"
                error={errors.min_team_size}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    type: "number",
                    value: values.min_team_size,
                    name: "min_team_size",
                    onChange: handleChange,
                }}
            />
        </GridItem>}
        { values.competition_type == 'sparing' &&  <GridItem xs={12} md={4}>
            <CustomInput
                labelText="Maks. rozmiar drużyny"
                error={errors.max_team_size}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    type: "number",
                    value: values.max_team_size,
                    name: "max_team_size",
                    onChange: handleChange,
                }}
            />
        </GridItem>}
    </>
}