import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import ShoppingCartPage from "./ShoppingCartPage";
import { Api } from "PNH/services/Api";

export const UsersFunctions = {
  update: (user_id, id, values, formik) => {
    return Api.basket.admin.update(user_id, id, { basket_item: values });
  },

  defaultValues: (props) => {
    return {
      basket_items: [],
      transaction: {},
      promoCodes: {},
      terms: false,
      afterSave: () => {},
    };
  },
};

export const ShoppingCart = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    Api.transactions.currentUser.basketCreate().then(({ ok, data }) => {
      if (ok) {
        window.location.href = parseFloat(values.transaction.value) > 0 ?  "/dotpay/" + data.id : '/transakcje';
      } else {
        window.location.href = "/koszyk";
      }
    });
  },
})(ShoppingCartPage);
