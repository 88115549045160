import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import { UsersFunctions } from "./index";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { I18n } from "PNH/components/I18n";
import CustomInput from "components/CustomInput/CustomInput";
const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
  }

  fetch = () => {
    if (this.props.userID) {
      Api.users.admin.show(this.props.userID).then((response) => {
        if (response.ok) {
          this.props.setFieldValue(
            "user_sport_levels_attributes",
            response.data.user_sport_levels_attributes
          );
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const { classes, values, handleChange, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer >
          <GridItem xs={12} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              simple
              size="sm"
              onClick={() => {
                UsersFunctions.addObject(this.props);
              }}
            >
              + Dodaj poziom
            </Button>
          </GridItem>


          {values.user_sport_levels_attributes &&
            values.user_sport_levels_attributes.map(
              (user_sport_level, index) => {
                if (user_sport_level._destroy) {
                  return null;
                }
                return (
                  <GridItem key={user_sport_level.id + "-" + index} xs={12}>
                    <GridContainer>
                      <GridItem xs={4}>
                        <AsyncSelector
                          apiMethod={Api.sports.admin.index}
                          apiShowMethod={Api.sports.admin.show}
                          labelKey="name"
                          label="Dyscyplina"
                          name="sport"
                          defaultValue={user_sport_level.sport_id}
                          value={
                            user_sport_level && user_sport_level.sport && {
                              id: user_sport_level.sport.id,
                              label: user_sport_level.sport.name,
                            }
                          }
                          onChange={(value, data) => {
                            UsersFunctions.updateObject(
                              this.props,
                              index,
                              "sport_id",
                              value
                            );
                            UsersFunctions.updateObject(
                              this.props,
                              index,
                              "sport",
                              data
                            );
                          }}
                        />


                      </GridItem>
                      <GridItem xs={4}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            Poziom
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={user_sport_level.level}
                            onChange={(e) => {
                              UsersFunctions.updateObject(
                                this.props,
                                index,
                                "level",
                                e.target.value
                              );
                            }}
                            inputProps={{
                              name: "level",
                            }}
                          >
                            {['blocked', 'beginners', "medium","intermediate","advanced",].map((g) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={g}
                              >
                                {I18n.t(g)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={4}>
                     { user_sport_level.avg_level && <CustomInput
                        labelText="Średni(Ilość opini)"
                        disabled
                        error= {user_sport_level.avg_level.avg_level != user_sport_level.level}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: `${I18n.t(user_sport_level.avg_level.avg_level || "")}(${user_sport_level.avg_level.opinions_count || 0})`,
                          name: "avg",
                          type: "text",
                          autocomplete: "off",
                        }}
                        autoComplete="off"
                      /> }
                               
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={user_sport_level.edit_only_by_admin}
                              onClick={(e) => {
                                UsersFunctions.updateObject(
                                  this.props,
                                  index,
                                  "edit_only_by_admin",
                                  !user_sport_level.edit_only_by_admin
                                );
                              }}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label={"Zablokowana edycja"}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={user_sport_level.check_on_pnh}
                              onClick={(e) => {
                                UsersFunctions.updateObject(
                                  this.props,
                                  index,
                                  "check_on_pnh",
                                  !user_sport_level.check_on_pnh
                                );
                              }}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label={"Sprawdzaj u PNH"}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={user_sport_level.check_on_organizer}
                              onClick={(e) => {
                                UsersFunctions.updateObject(
                                  this.props,
                                  index,
                                  "check_on_organizer",
                                  !user_sport_level.check_on_organizer
                                );
                              }}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label={"Sprawdzaj u organizatorów"}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={user_sport_level.verified}
                              onClick={(e) => {
                                UsersFunctions.updateObject(
                                  this.props,
                                  index,
                                  "verified",
                                  !user_sport_level.verified
                                );
                              }}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label={"Poziom zweryfikowany"}
                        />
                      </GridItem>
                      <GridItem xs={12} style={{textAlign: 'right'}}>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() =>
                            UsersFunctions.updateObject(
                              this.props,
                              index,
                              "_destroy",
                              1
                            )
                          }
                        >
                          Usuń
                        </Button>
                      </GridItem>
                    </GridContainer>

                    <hr />
                  </GridItem>
                );
              }
            )}
        </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem xs={12} style={{ textAlign: "right" }} >
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
