export const I18n = {t: (key) =>  PL[key]}


var PL = {
  "w-0": "niedziela",
  "w-1": "poniedziałek",
  "w-2": "wtorek",
  "w-3": "środa",
  "w-4": "czwartek",
  "w-5": "piątek",
  "w-6": "sobota",
  "sign_in": "Logowanie",
  "login_form": {
    "header": "Logowanie",
    "subheader": "Lub Klasycznie",
    "inputs": {
      "email": "Email...",
      "password": "Password...",
    },
    "submit": "Zaloguj",
  },
  "recharge": "Doładowanie środków",
  "withdrawal": "Pobranie środków",
  "prepaid": "Prepaid",
  "pay_by_fitprofit": "Fitprofit",
  "pay_by_prepaid": "Prepaid",
  "online_transfer": "Przelew online",
  "pay_by_online_transfer": "Przelew online",
  "benefit": "MultiSport",
  "fitprofit": "Fitprofit",
  "pay_by_benefit": "MultiSport",
  "ok_system": "Medicover Sport",
  "pay_by_ok_system": "Medicover Sport",
  "by_admin": "Administrator",
  "pay_by_cash": "Gotówka",
  "pay_by_bonus_account": "Płatność bonusem",
  "cash": "Gotówka",
  "unpaid": "Niepołacone",
  "paid": "Opłacone",
  "recharge_balance": 'Doładowanie konta głównego',
  "recharge_deposit": 'Doładowanie kaucji',
  "recharge_bonus": 'Doładowanie konta bonusowego',
  "buy_activity": 'Wykupione miejsce na zajęcia',
  "withdrawal_balance": 'Pobranie z konta głównego',
  "withdrawal_deposit": 'Pobranie z kaucji',
  "withdrawal_bonus": 'Pobranie z konta bonusowego',
  "withdrawal_supplement": 'Pobranie dopłaty',
  "hall": 'Hala sportowa',
  "baloon": 'Balon',
  "court": 'Otwarty obiekt',
  "stadium": 'Stadion',
  "gym": 'Sala gimnastyczna',
  "dance_room": 'Sala taneczna',
  "other": 'Inne',
  "terminal": 'Terminal',
  "report": 'Raport',
  "canceled": 'Odwołano',
  "deposit_disabled": 'Wyłącz kaucje',
  "coach": 'Trener',
  "hidden": 'Ukryte',
  "confirmed": 'Potwierdzone',
  "males": 'Mężczyźni',
  "females": 'Kobiety',
  "unisex": 'Mix',
  "free": 'Dowolny',
  "beginners": 'Rekreacyjny',
  "medium": 'Średni',
  "intermediate": 'Śr. zaawansowany',
  "advanced": 'Zaawansowany',
  "blocked": 'Zablokowany',
  "operation_costs": 'Koszty rezygnacji',
  "resign_activity": 'Rezygnacja z zajęć',
  "pnh": 'PNH',
  "organizer": 'Organizator',
  "promo": 'Promocja',
  "true": 'Tak',
  "false": 'Nie',
  "archive": 'Archiwum',
  "user_role_verify": 'Konto zweryfikowane',
  "user_role_payments_prepaid": "Dodawanie zajęć - Płatność prepaid",
  "user_role_activity_template": "Szablony zajęć",
  "user_role_payments_online_transfer": "Dodawanie zajęć -Płatność przelew online",
  "user_role_payments_ok_system": "Dodawanie zajęć -Płatność medicover",
  "user_role_payments_cash": "Dodawanie zajęć -Płatność gotówką",
  "user_role_payments_benefit": 'Dodawanie zajęć -Płatność benefit',
  "user_role_payments_fitprofit": 'Dodawanie zajęć -Płatność fitprofit',
  "user_role_coordinator": 'Koordynator',
  "user_role_activity_registration": 'Zapis na zajęcia',
  "user_role_activity": 'Dodawanie zajęć',
  "user_role_organizer_type_1": "Organizator 1",
  "user_role_organizer_type_2": 'Organizator 2',
  "user_role_organizer_type_3": 'Organizator 3',
  "user_role_edit_profile": 'Edycja profilu',
  "user_role_edit_benefit_card": 'Edycja karty MultiSport',
  "user_role_edit_fitprofit_card": 'Edycja karty FitProfit',
  "user_role_competition_groups": "Grupy",
  'admin_role_users': 'Moduł użytkownika',
  'admin_role_transactions': 'Moduł transakcji',
  'admin_role_sports': 'Moduł dyscyplin',
  'admin_role_sidekiq': 'Moduł sidekiq',
  'admin_role_promo_codes': 'Moduł kody promocyjne',
  'admin_role_prepaids': 'Moduł doładowanie kont',
  'admin_role_pages': 'Moduł stron',
  'admin_role_locations': 'Moduł lokalizacji',
  'admin_role_app_settings': 'Moduł ustawień',
  'admin_role_export_panel': 'Moduł exportu',
  'admin_role_audited': 'Moduł audytu',
  'admin_role_activities': 'Moduł zajęć',
  'admin_role_main_page_tile': 'Kafelki strona główna',
  'admin_role_system_events': 'Zdarzenia',
  "admin_role_activity_template": "Szablony zajęć",
  "admin_role_reports": "Raporty",
  "admin_role_benefit_entry": "Benefit odbicia",
  "admin_role_competition_groups": "Grupy",
  'verify_lv': 'Weryfikacja poziomu',
  "activities.created_at asc": "Dodano rosnąco",
  "activities.created_at desc": "Dodano malejąco",
  "activities.confirmed asc": "Potwierdzone rosnąco",
  "activities.confirmed desc": "Potwierdzono malejąco",
  "activities.date asc": "Data rosnąco",
  "activities.date desc": "Data malejąco",
  "activities.price asc": "Cena rosnąco",
  "activities.price desc": "Cena malejąco",
  "activities.start_at asc": "Godzina startu rosnąco",
  "activities.start_at desc": "Godzina startu malejąc",
  "activities.canceled asc": "Odwołane rosnąco",
  "activities.canceled desc": "Odwołane malejąco",
  "activities.activity_type asc": "Typ rosnąco",
  "activities.activity_type desc": "Typ malejąco",
  "activities.hidden asc": "Ukryte rosnąco",
  "activities.hidden desc": "Ukryte malejąco",
  "User": 'Użytkownicy',
  "AdminFlatActivity": "Zajęcia",
  "users.created_at asc": "Dodano rosnąco",
  "users.created_at desc": "Dodano malejąco",
  "users.first_name asc": "Imię rosnąco",
  "users.first_name desc": "Imię malejąco",
  "users.id asc": "ID rosnąco",
  "users.id desc": "ID malejąco",
  "users.last_name asc": "Nazwisko rosnąco",
  "users.last_name desc": "Nazwisko malejąco",
  "users.email asc": "Email rosnąco",
  "users.email desc": "Email malejąco",
  "opened": 'Otwarty',
  "inprogress": "W trakcie",
  "done": "Zrobione",
  "archived": 'Zarchiwizowane',
  "general": 'Ogólny',
  "benefit_change": "Zmiana karty ms",
  "deposit_drop": "Spadek kaucji",
  "negative_opinion": "Negatywna opinia",
  "Activity": 'Zajęcia',
  'system_events.created_at asc': "Dodano rosnąco",
  'system_events.created_at desc': "Dodano malejąco",
  "first_type": "Organizator 1.0",
  "second_type": "Organizator 2.0",
  "third_type": "Organizator 3.0",
  "approved_by_admin": 'Szablon zatwierdzony',
  "ActivityTemplate": 'Szablony zajęć',
  "activity_templates.created_at asc": "Dodano rosnąco",
  "activity_templates.created_at desc": "Dodano malejąco",
  "activity_templates.confirmed asc": "Potwierdzone rosnąco",
  "activity_templates.confirmed desc": "Potwierdzono malejąco",
  "activity_templates.date asc": "Data rosnąco",
  "activity_templates.date desc": "Data malejąco",
  "activity_templates.price asc": "Cena rosnąco",
  "activity_templates.price desc": "Cena malejąco",
  "activity_templates.start_at asc": "Godzina startu rosnąco",
  "activity_templates.start_at desc": "Godzina startu malejąc",
  "activity_templates.canceled asc": "Odwołane rosnąco",
  "activity_templates.canceled desc": "Odwołane malejąco",
  "activity_templates.activity_type asc": "Typ rosnąco",
  "activity_templates.activity_type desc": "Typ malejąco",
  "activity_templates.hidden asc": "Ukryte rosnąco",
  "activity_templates.hidden desc": "Ukryte malejąco",
  "activity_templates.approved_by_admin asc": "Zatwierdzone rosnąco",
  "activity_templates.approved_by_admin desc": "Zatwierdzone malejąco",
  "template_added": 'Dodano nowy szablon',
  'promo_codes.created_at asc': "Dodano rosnąco",
  'promo_codes.created_at desc': "Dodano malejąco",
  'deposit_disabled_on_publish': "Wyłącz kaucję po odkryciu",
  "benefit_entries.created_at asc": "Dodano rosnąco",
  "benefit_entries.created_at desc": "Dodano malejąco",
  "benefit_entries": "Benefit odbicia",
  "BenefitEntry": "Benefit odbicia",
  "be_paper": "Papierowy",
  "be_system": "Systemowy",
  "be_terminal": "Terminal",
  "activity": 'Zajęcia',
  "sparing": "Sparing",
  'local_group': 'Grupa lokalna',
  "club": "Klub",
  "tournament": "Turniej",
  "league": "Liga",
  "b2b": "B2B",
  "open_event": "Otwarte wydarzenie",
  "open": "Otwarta",
  "active": "Aktywna",
  "l_create_competition_group_local_group": "Liczba grup",
  "l_max_competition_group_local_group_players": "Liczba graczy",
  "l_max_competition_group_local_group_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_local_group_teams": "Liczba drużyn",
  "l_can_competition_group_local_group_hidden": "Mogą być ukryte",
  "l_create_competition_group_organizer": "Liczba grup",
  "l_max_competition_group_organizer_players": "Liczba graczy",
  "l_max_competition_group_organizer_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_organizer_teams": "Liczba drużyn",
  "l_can_competition_group_organizer_hidden": "Mogą być ukryte",
  "l_create_competition_group_club": "Liczba grup",
  "l_max_competition_group_club_players": "Liczba graczy",
  "l_max_competition_group_club_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_club_teams": "Liczba drużyn",
  "l_can_competition_group_club_hidden": "Mogą być ukryte",
  "l_create_competition_group_tournament": "Liczba grup",
  "l_max_competition_group_tournament_players": "Liczba graczy",
  "l_max_competition_group_tournament_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_tournament_teams": "Liczba drużyn",
  "l_can_competition_group_tournament_hidden": "Mogą być ukryte",
  "l_create_competition_group_league": "Liczba grup",
  "l_max_competition_group_league_players": "Liczba graczy",
  "l_max_competition_group_league_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_league_teams": "Liczba drużyn",
  "l_can_competition_group_league_hidden": "Mogą być ukryte",
  "l_create_competition_group_b2b": "Liczba grup",
  "l_max_competition_group_b2b_players": "Liczba graczy",
  "l_max_competition_group_b2b_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_b2b_teams": "Liczba drużyn",
  "l_can_competition_group_b2b_hidden": "Mogą być ukryte",
  "l_create_competition_group_open_event": "Liczba grup",
  "l_max_competition_group_open_event_players": "Liczba graczy",
  "l_max_competition_group_open_event_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_open_event_teams": "Liczba drużyn",
  "l_can_competition_group_open_event_hidden": "Mogą być ukryte",
  "l_create_competition_group_other": "Liczba grup",
  "l_max_competition_group_other_players": "Liczba graczy",
  "l_max_competition_group_other_max_players_per_team": "Rozmiar drużyn",
  "l_max_competition_group_other_teams": "Liczba drużyn",
  "l_can_competition_group_other_hidde": "Mogą być ukryte"
}