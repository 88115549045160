import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { I18n } from "PNH/components/I18n";
import Accordion from "components/Accordion/Accordion";
import { Api } from "PNH/services/Api";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";


export const TableFilters = (formik) => {
    const { classes, SearchFunctions } = formik;
    return (<GridContainer>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <GridContainer style={{ justifyContent: 'flex-end' }}>
                            <GridItem xs={1}>
                                {withID(formik)}
                            </GridItem>
                            <GridItem xs={3}>
                                {withDateGreater(formik)}
                            </GridItem>
                            <GridItem xs={3}>
                                {withDateLess(formik)}
                            </GridItem>
                            <GridItem xs={2}>
                                {withApprovedByAdmin(formik)}
                            </GridItem>
                            <GridItem xs={3}>
                                {withTags(formik)}
                            </GridItem>
                            <GridItem xs={12}>
                                <Accordion
                                    collapses={[{
                                        title: 'Zaawansowane filtry',
                                        content: <GridContainer>
                                            <GridItem xs={3}>
                                                {withConfirmed(formik)}
                                            </GridItem>
                                            <GridItem xs={3}>
                                                {withActivityType(formik)}
                                            </GridItem>

                                            <GridItem xs={3}>
                                                {withCreatorID(formik)}
                                            </GridItem>
                                           
                                            <GridItem xs={3}>
                                                {withHidden(formik)}
                                            </GridItem>
                                            <GridItem xs={3}>
                                                {withStartAtGreater(formik)}
                                            </GridItem>
                                            <GridItem xs={3}>
                                                {withStartAtLess(formik)}
                                            </GridItem>
                                            <GridItem xs={3}>
                                                {withArchived(formik)}
                                            </GridItem>
                                        </GridContainer>
                                    }
                                    ]}
                                />
                            </GridItem>
                            <GridItem xs={3}></GridItem>
                            <GridItem xs={3}>
                                {order(formik)}
                            </GridItem>
                            <GridItem xs={3} style={{ textAlign: "right" }}>
                                {SearchFunctions.showClearButton && <Button
                                    link
                                    color="success"
                                    onClick={SearchFunctions.clearFilters}
                                >
                                    Wyczyść
                                </Button>}
                                <Button
                                    color="success"
                                    onClick={formik.handleSubmit}
                                >
                                    Filtruj
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <input type="submit" style={{ display: 'none' }} />
        </form>
    </GridContainer>
    )
}

const withID = (formik) => (
    <CustomInput
        id={`with_id`}
        error={formik.errors.with_id}
        labelText="ID"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj...",
            type: "text",
            name: `with_id`,
            value: formik.values.with_id,
            onChange: (e) => { formik.setFieldValue(`with_id`, e.target.value) },
        }}
    />
)

const withDateGreater = (formik) => (
    <CustomInput
        id={`with_date_greater`}
        error={formik.errors.with_created_at_greater}
        labelText="Dodano po"
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_date_greater`,
            value: formik.values.with_created_at_greater,
            onChange: (e) => { formik.setFieldValue(`with_date_greater`, e.target.value) },
        }}
    />
)

const withDateLess = (formik) => (
    <CustomInput
        id={`with_date_less`}
        error={formik.errors.with_created_at_less}
        labelText="Dodano przed"
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_date_less`,
            value: formik.values.with_created_at_less,
            onChange: (e) => { formik.setFieldValue(`with_date_less`, e.target.value) },
        }}
    />
)

const withTags = (formik) => (
    <CustomInput
        id={`with_tags`}
        labelText="Szukaj..."
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            placeholder: "Szukaj ROK-M-D...",
            type: "text",
            name: `with_tags`,
            value: formik.values.with_tags,
            onChange: (e) => { formik.setFieldValue(`with_tags`, e.target.value) },
        }}
    />
)

const withConfirmed = (formik) => {
    const { classes } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Potwierdzone
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.with_confirmed}
            onChange={(e) => { formik.setFieldValue(`with_confirmed`, e.target.value) }
            }
            inputProps={{
                isClearable: true,
                name: "with_confirmed",
            }}
        >
            {["", "true", "false"].map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g) || "Wyczyść"}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

const withActivityType = (formik) => {
    const { classes } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Typ zajęć
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.with_activity_type}
            onChange={(e) => { formik.setFieldValue(`with_activity_type`, e.target.value) }}
            inputProps={{
                isClearable: true,
                name: "with_activity_type",
            }}
        >
            {["", "pnh", "organizer"].map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g) || "Wyczyść"}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

const withApprovedByAdmin = (formik) => {
    const { classes } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Zatwierdzony szablon
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.with_approved_by_admin}
            onChange={(e) => { formik.setFieldValue(`with_approved_by_admin`, e.target.value) }
            }
            inputProps={{
                isClearable: true,
                name: "with_approved_by_admin",
            }}
        >
            {["", "true", "false"].map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g) || "Wyczyść"}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

const withArchived = (formik) => {
    const { classes } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Archiwalny
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.with_archived}
            onChange={(e) => { formik.setFieldValue(`with_archived`, e.target.value) }
            }
            inputProps={{
                isClearable: true,
                name: "with_archived",
            }}
        >
            {["", "true", "false"].map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g) || "Wyczyść"}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}
const withCreatorID = (formik) => {
    const { classes, setFieldValue, values } = formik;
    return <AsyncSelector
        apiMethod={Api.users.admin.index}
        apiShowMethod={Api.users.admin.show}
        labelKey="label"
        label="Dodane przez"
        name="with_creator_id"
        defaultValue={values.with_creator_id}
        value={
            values.creator && {
                id: values.creator.id,
                label: values.creator.label,
            }
        }
        onChange={(value, data) => {
            setFieldValue("creator", data);
            setFieldValue("with_creator_id", value);
        }}
    />
}

const withHidden = (formik) => {
    const { classes } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Ukryte
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.with_hidden}
            onChange={(e) => { formik.setFieldValue(`with_hidden`, e.target.value) }
            }
            inputProps={{
                isClearable: true,
                name: "with_hidden",
            }}
        >
            {["", "true", "false"].map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g) || "Wyczyść"}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}

const withStartAtGreater = (formik) => (
    <CustomInput
        id={`with_start_at_greater`}
        labelText="Godzina startu od"
        error={formik.errors.with_start_at_greater}
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            placeholder: "Szukaj 14:00...",
            type: "text",
            name: `with_start_at_greater`,
            value: formik.values.with_start_at_greater,
            onChange: (e) => { formik.setFieldValue(`with_start_at_greater`, e.target.value) },
        }}
    />
)

const withStartAtLess = (formik) => (
    <CustomInput
        id={`with_start_at_less`}
        error={formik.errors.with_start_at_less}
        labelText="Godzina startu do"
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            placeholder: "Szukaj 14:00...",
            type: "text",
            name: `with_start_at_less`,
            value: formik.values.with_start_at_less,
            onChange: (e) => { formik.setFieldValue(`with_start_at_less`, e.target.value) },
        }}
    />
)


const order = (formik) => {
    const { classes, setFieldValue, SearchFunctions } = formik;
    return <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
        >
            Sortowanie
        </InputLabel>
        <Select
            MenuProps={{
                className: classes.selectMenu,
            }}
            classes={{
                select: classes.select,
            }}
            value={formik.values.order}
            onChange={(e) =>
                setFieldValue(`order`, e.target.value)
            }
            inputProps={{
                isClearable: true,
                name: "order",
            }}
        >
            {SearchFunctions.orderBy().map((g) => (
                <MenuItem
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                    }}
                    value={g}
                >
                    {I18n.t(g)}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}

