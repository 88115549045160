import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";

export const ActivitiesFunctions = {

  update: (values, formik) => {
    return Api.activities.admin
      .update(values.id, { activity: values })
      .then((response) => {
        if (response.ok) {
          window.setNotifications([{ type: "success", msg: "Zapisano" }]);
        } else {
          window.setNotifications([
            { type: "alert", msg: "Nie udało się zapisać zmian" },
          ]);
        }
      });
  },
  

  defaultValues: (props) => {
    const defaultValues = {
      localization_id: "",
      location_object: "",
      sport_id: "",
      name: "",
      description: "",
      hidden: "",
      price: "",
      notes: "",
      date: "",
      publish_date: "",
      lv: "",
      gender: "",
      localizations: "",
      reserved_spots: "",
      max_players: "",
      min_players: "",
      sports: [],
      start_at: "",
      stop_at: "",
      baner_image: "",
      thumb_image: "",
      custom_tags: "",
      supplement: "",
      ok_system_code: "",
      page_id: "",
      verify_lv: false,
      change_to_public_at: "",
      supplement_medicover: "",
      afterSave: () => {},
    };
    return { ...defaultValues, ...props.defaults };
  },
};

export const ActivitiesForm = withFormik({
  validationSchema: validationSchema,
  enableReinitialize: false,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (confirm('Na pewno zapisać zmiany?')) {
      formikProps.props.update(values)
    }
  },
})(Form);
