import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import ActivitiesTable from "PNH/components/Filters/Table"
import { Api, serializeParams } from "PNH/services/Api";
import { withRouter } from 'react-router-dom';
import { RowFunction } from "./RowFunction";
import { TableFilters } from "./Filters";
import moment from "moment";

export const SearchFunctions = {
  index: (values, formik) => {
    
    return Api.competition_groups.admin
      .index({ ...values, ...{ data: [], search: true } })
      .then((response) => {
        if (response.ok) {
         
          formik.setFieldValue("data", response.data);
          formik.setFieldValue("total", response.data.total);
          const params = { ...values, ...{ data: [] } };
          // eslint-disable-next-line 
          history.pushState({}, "competition-groups", "/competition-groups?" + serializeParams(params));
          window.scrollTo(0, 0);
        }
      });
  },

  getData: async (formik) => {
    SearchFunctions.index(formik.values, formik);
  },

  changePage: async (page, formik) => {
    let pageChange = await formik.setFieldValue('page', page)
    SearchFunctions.index({...formik.values, ...{page: page} }, formik);
  },

  mapRows: (row, formik) => {
    return RowFunction(row, formik)
  },

  clearFilters: () => {
    window.location.href = "/competition-groups";
  },

  onChangeRowsPerPage: async (e, formik) => {
    let limit = await formik.setFieldValue('limit', e.target.value);
    let page = await formik.setFieldValue('page', 0)
    SearchFunctions.getData(formik)
  },

  handleAddButton: (formik) => {
    let url = "/competition-groups/new";
    formik.history.push(url)
  },

  bulkUpdateComponent: (formik) => {
    return null
  },

  filtersComponent: (formik) => {
    return TableFilters(formik)
  },

  tableHeaders: () => [
    "ID",
    "Nazwa",
    'Opcje'
  ],

  showAddButton: () => true,
  showClearButton: () => true,

  orderBy: () => [
    'competition_groups.created_at asc',
    'competition_groups.created_at desc',
  ],

  defaultValues: (props) => {
    const urlParams = new URLSearchParams(props.location.search);
    return {
      page: parseInt(urlParams.get("page")) || 0,
      limit: 25,
      flat: true,
      data: { rows: [] },
      selected: [],
      total: 0,
      with_id: urlParams.get("with_id") || "",
      with_name: urlParams.get("with_name") || "",
      order: urlParams.get("order") || "competition_groups.created_at asc",
      afterSave: props.afterSave,
    };
  },
};

export const FiltersForm = withRouter(withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...SearchFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    const tmp = {...values, ...{page: 0}}
    formikProps.setSubmitting(true); 
    
    SearchFunctions.index(tmp, formikProps)
      .then((response) => { })
      .then(() => {
        formikProps.setFieldValue("page", 0);
        formikProps.setTouched({}); 
      });
  },
})(({ ...props }) => <ActivitiesTable SearchFunctions={SearchFunctions} {...props} />));
