import React, {useState} from "react";
import {Api} from "../../../../services/Api";
import Button from "../../../../../components/CustomButtons/Button";
import AsyncSelector from "../../../../components/AsyncSelector/AsyncSelector";
import EditIcon from "@material-ui/icons/Edit";
import {Link} from "react-router-dom";

export const OrganizerForm = ({row, classes}) => {
    const [userID, setUserID] = useState(row.organizer_id)
    const [user, setUser] = useState(row.organizer)
    const [edit, setEdit] = useState(false)
    return <>
        {!edit && (user && <Link to={`/users/${userID}`} target={'_blank'}>{user.label}</Link> )}
        {!edit && !row.confirmed && <Button color={"primary"} justIcon={true}  onClick={() => setEdit(true)} size={"sm"} style={{marginLeft: '10px'}}><EditIcon /></Button>}
        {edit && <AsyncSelector
            apiMethod={Api.users.admin.index}
            apiShowMethod={Api.users.admin.show}
            labelKey="label"
            label="Koordynator/Organizator"
            name="user_id"
            disabled={row.confirmed}
            defaultValue={userID}
            value={
                user && {
                    id: user.id,
                    label: user.label,
                }
            }
            onChange={(value, data) => {
                setUserID(value);
                setUser(data)
                if (userID !== row.user_id) {
                    Api.benefit_entries.admin.update(row.id, {organizer_id: userID}).then((r) => {
                        setEdit(false)
                        if (r.ok) {
                            window.setNotifications([
                                {
                                    type: "success",
                                    msg: "Zapisano",
                                },
                            ]);
                        } else {
                            window.setNotifications([
                                {
                                    type: "alert",
                                    msg: "Nie zapisano",
                                },
                            ]);
                        }
                    })
                }
            }}
        />}

    </>
}