import React from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { I18n } from "PNH/components/I18n";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.fetch();
    Api.users.admin.roles().then((response) => {
      if (response.ok) {
        this.props.setFieldValue("all_roles", response.data.rows);
      } else {
        window.setNotifications([
          { type: "alert", msg: "Coś poszło nie tak" },
        ]);
      }
    });
  }

  fetch = () => {
    if (this.props.values.id) {
      Api.users.admin.show(this.props.values.id).then((response) => {
        if (response.ok) {
          this.props.setFieldValue(
            "role_ids",
            response.data.roles.map((r) => r.id)
          );
        } else {
          window.setNotifications([
            { type: "alert", msg: "Coś poszło nie tak" },
          ]);
        }
      });
    }
  };
  render() {
    const { classes, values, setFieldValue, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={6}>
            <h3>Portal Gracz</h3>
            <GridContainer>
            {values.all_roles[0] &&
              values.all_roles.map((row) => {
                if (!row.name.startsWith('user_role')) {
                  return null
                }
                return  <GridItem xs={6}><FormControlLabel
                  key={`role-${row.id}`}
                  style={{ marginTop: "20px" }}
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={values.role_ids.includes(row.id)}
                      onClick={() => {
                          let ids =  values.role_ids.includes(row.id) ?  values.role_ids.filter((v)=>(v != row.id)) : [...values.role_ids, row.id];
                          setFieldValue('role_ids', ids)
                      }
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={I18n.t(row.name)}
                />
                </GridItem>
              })

            }
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            <h3>Portal Administratora</h3>
            <GridContainer>
            {values.all_roles[0] &&
              values.all_roles.map((row) => {
                if (!row.name.startsWith('admin_role')) {
                  return null
                }
                return  <GridItem xs={6}><FormControlLabel
                  key={`role-${row.id}`}
                  style={{ marginTop: "20px" }}
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={values.role_ids.includes(row.id)}
                      onClick={() => {
                          let ids =  values.role_ids.includes(row.id) ?  values.role_ids.filter((v)=>(v != row.id)) : [...values.role_ids, row.id];
                          setFieldValue('role_ids', ids)
                      }
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={I18n.t(row.name)}
                />
                </GridItem>
              })

            }
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer justify="flex-end">
          <GridItem xs={3} style={{textAlign: 'right'}}>
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default withStyles(styles)(Form);
