import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";


export const CostsSection = ({ handleChange, classes, values, errors, setFieldValue }) => {
    return <>
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12} md={3}>
            <CustomInput
              labelText="Cena"
              error={errors.price}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.price,
                name: "price",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={3}>
            <CustomInput
              labelText="Dopłata benefit"
              error={errors.supplement}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.supplement,
                name: "supplement",
                onChange: handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} md={3}>
            <CustomInput
              labelText="Dopłata Medicover"
              error={errors.supplement_medicover}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.supplement_medicover,
                name: "supplement_medicover",
                onChange: handleChange,
              }}
            />
          </GridItem>
        <GridItem xs={12} md={3}>
            <CustomInput
                labelText="Dopłata FitProfit"
                error={errors.supplement_fitprofit}
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    type: "number",
                    value: values.supplement_fitprofit,
                    name: "supplement_fitprofit",
                    onChange: handleChange,
                }}
            />
        </GridItem>
          <GridItem xs={12} md={3}>
            <CustomInput
              labelText="Kwota wynajmu"
              error={errors.rental_price}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "number",
                value: values.rental_price,
                name: "rental_price",
                onChange: handleChange,
              }}
            />
          </GridItem>
          {values.event_type === 'first_type' && (
            <GridItem xs={12} md={3}>
              <CustomInput
                labelText="Rabat - stała kwota"
                error={errors.discount}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  value: values.discount,
                  name: "discount",
                  onChange: handleChange,
                }}
              />
            </GridItem>
          )}
          {values.event_type === 'third_type' && (
            <>
              <GridItem xs={12} md={3}>
                <CustomInput
                  labelText="Maksymalna cena"
                  error={errors.max_price}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.max_price,
                    name: "max_price",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={3}>
                <CustomInput
                  labelText="Maksymalna cena z MS"
                  error={errors.max_benefit_price}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                    value: values.max_benefit_price,
                    name: "max_benefit_price",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
                <GridItem xs={12} md={3}>
                    <CustomInput
                        labelText="Maksymalna cena z FitProfit"
                        error={errors.max_fitprofit_price}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            type: "number",
                            value: values.max_fitprofit_price,
                            name: "max_fitprofit_price",
                            onChange: handleChange,
                        }}
                    />
                </GridItem>
            </>
          )}
    </>
}